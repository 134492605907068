import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../lib/store';

import styles from './ImpersonationWarning.module.scss';

export default function ImpersonationWarning() {
  const session = useSelector((state: AppState) => state.session);

  if (!session || !session.impersonating) {
    return null;
  }

  return <div className={styles.main}>You are impersonating {session.email}</div>;
}
