import React from 'react';
import RegisterState, { ChipDetails } from './RegisterStateV2';

export const stepsInOrderV2 = ['chip', 'email', 'pet', 'contact', 'upgrade', 'success'] as const;
export type RegisterChipStep = typeof stepsInOrderV2[number];

interface RegisterChipContextValue {
  currentUserIsReseller: boolean;
  onStepFinished: (step: RegisterChipStep) => void;
  registerState: RegisterState;
  setChipDetails: (chipDetails: ChipDetails) => void;
  setPetId: (petId: string) => void;
  setChipId: (chipId: string) => void;
  setPrimaryEmail: (primaryEmail: string) => void;
  registerChipAddEmail: (chipId: string) => Promise<void>;
}

const RegisterChipContextV2 = React.createContext<RegisterChipContextValue>({
  currentUserIsReseller: false,
  onStepFinished: () => {},
  registerState: {},
  setChipDetails: () => {},
  setPetId: () => {},
  setChipId: () => {},
  setPrimaryEmail: () => {},
  registerChipAddEmail: async () => {
    throw new Error('Not implemented');
  },
});
export default RegisterChipContextV2;
