import { gqlTypes } from '../../types/gqlTypes';
import React, { useState, ReactNode, useContext, useMemo } from 'react';

export interface AppViewState {
  // For the ListChips page.
  selectedStatus: gqlTypes.ChipStatusType;
  setSelectedStatus(newStatus: gqlTypes.ChipStatusType): void;
  isDesktop: boolean;
}

const initialStatus = gqlTypes.ChipStatusType.SHIPPED;

const emptyAppViewState: AppViewState = {
  selectedStatus: initialStatus,
  setSelectedStatus: () => {},
  isDesktop: false,
};

const AppViewContext = React.createContext(emptyAppViewState);

export function useAppViewContext() {
  return useContext(AppViewContext);
}

export function AppViewContextProvider({ children, isDesktop }: { children: ReactNode; isDesktop?: boolean }) {
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const contextValue: AppViewState = useMemo(
    () => ({
      selectedStatus,
      setSelectedStatus,
      isDesktop: !!isDesktop,
    }),
    [selectedStatus, setSelectedStatus, isDesktop],
  );

  return <AppViewContext.Provider value={contextValue}>{children}</AppViewContext.Provider>;
}
