import { useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import Select, { MenuListComponentProps, OptionTypeBase } from 'react-select';
import { gqlTypes } from '../../../../types/gqlTypes';
import { allBreedsQuery } from './BreedSelect.graphql';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import classNames from 'classnames';
import { FixedSizeList } from 'react-window';
import Checkbox from '../../../../components/Checkbox';

import styles from './BreedSelect.module.scss';

export const UNKNOWN_BREED_NAME = 'Other';

export interface BreedFields {
  breedId: string;
  isMixedBreed: boolean;
}

interface BreedSelectProps {
  name: string;
  isMixedBreed: boolean;
}

const HEIGHT = 49;

// Use react-window to speed up the selector, since our breed list is HUGE.
// https://github.com/JedWatson/react-select/issues/3128#issuecomment-431397942
function MenuList({ options, children, maxHeight, getValue }: MenuListComponentProps<OptionTypeBase, false>) {
  const value = getValue();
  const initialOffset = options.indexOf(value!) * HEIGHT;
  if (!Array.isArray(children)) {
    return null;
  }

  return (
    <FixedSizeList
      height={maxHeight}
      width="100%"
      itemCount={children.length}
      itemSize={HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </FixedSizeList>
  );
}

export default function BreedSelect({ name }: BreedSelectProps) {
  const { data } = useQuery<gqlTypes.allBreeds>(allBreedsQuery);

  const { setFieldValue, setFieldTouched, values } = useFormikContext<BreedFields>();

  const breeds = data?.allBreeds ?? [];
  const unknownBreed = breeds.find((breed) => breed.name === UNKNOWN_BREED_NAME);
  const options = breeds.map((breed) => ({
    label: breed.name,
    value: breed.id,
  }));
  const [value, setValue] = useState(() => values.breedId || '');
  const [isNotADog, setIsNotADog] = useState(false);

  useEffect(() => {
    setFieldValue(name, value);
  }, [value, name, setFieldValue]);

  return (
    <>
      <div className={classNames(styles.main, 'form-group')}>
        <div className="form-field">
          <Field type="hidden" name={name} />
          {isNotADog ? (
            <div className={classNames(styles.unknownBreed)}>Not a dog</div>
          ) : (
            <Select
              components={{ MenuList }}
              className={classNames('react-select-container')}
              placeholder="Dog Breed"
              classNamePrefix="react-select"
              options={options}
              onChange={(option: any) => {
                setValue(option.value);
                setFieldTouched(name, true);
              }}
              value={options.find((option) => option.value === value)}
            />
          )}
        </div>
        <div className="form-field">
          <div className={classNames(styles.breedModifiers, 'form-group')}>
            <div className="form-field">
              <Checkbox
                checked={values.isMixedBreed}
                onChange={(newChecked: boolean) => {
                  setFieldTouched(name, true);
                  setFieldTouched('isMixedBreed', true);
                  setFieldValue('isMixedBreed', newChecked);
                }}
                label={'Mixed Breed'}
                disabled={isNotADog}
              />
            </div>
            <div className="form-field">
              <Checkbox
                checked={isNotADog}
                onChange={(newChecked: boolean) => {
                  if (!newChecked) {
                    setValue('');
                    setIsNotADog(false);
                  } else if (unknownBreed) {
                    setValue(unknownBreed.id);
                    setIsNotADog(true);
                  }

                  setFieldTouched(name, true);
                  setFieldTouched('isMixedBreed', true);
                  setFieldValue('isMixedBreed', false);
                }}
                label={'Not a Dog'}
              />
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage name={name} component="div" className="form-field__error" />
    </>
  );
}
