import { useMutation } from '@apollo/client';
import { isValidSerialNumber, isValidThirdPartySerialNumber } from '@barkinglabs/nano-shared';
import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import qrCardImage from '../../../assets/images/qr-card.png';
import Button from '../../../components/Button/Button';
import SingleTextFieldForm from '../../../components/form/SingleTextFieldForm';
import { getChipDetails } from '../../../lib/chipsApi';
import { AppState } from '../../../lib/store';
import { gqlTypes } from '../../../types/gqlTypes';
import { chipRegistrationAddExistingPetMutation } from '../RegisterChip.graphql';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import styles from './SerialNumberStepV2.module.scss';

const InvalidSerialNumberErrorMessage = 'Invalid Serial Number';
const UnknownErrorMessage = 'An unknown error occurred';

const SHORT_CODE_REGEXP = /^[0-9a-zA-Z]{6}$/;
function isValidShortCode(input: string) {
  return SHORT_CODE_REGEXP.test(input);
}

function useSubmitSerialNumber() {
  const session = useSelector((state: AppState) => state.session);
  const { registerChipAddEmail, onStepFinished, registerState, setChipDetails, setChipId } =
    useContext(RegisterChipContextV2);

  const [registerChipAddExistingPet] = useMutation<
    gqlTypes.NANO_chipRegistrationAddExistingPet,
    gqlTypes.NANO_chipRegistrationAddExistingPetVariables
  >(chipRegistrationAddExistingPetMutation);

  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (input: string) => {
      const sanitizedInput = input.trim();
      if (!isValidSerialNumber(sanitizedInput) && !isValidShortCode(sanitizedInput)) {
        setError(InvalidSerialNumberErrorMessage);
        return;
      }

      // Manually set the loading to true
      setLoading(true);
      setChipId(sanitizedInput);

      getChipDetails(sanitizedInput)
        .then(async (chipDetails) => {
          setChipDetails(chipDetails);

          // If we're already logged in, we don't need to ask for them to login so we can go ahead and
          // submit the mutation to register the chip to the user's email
          if (session) {
            await registerChipAddEmail(chipDetails.shortId);
          }

          return chipDetails;
        })
        .catch(async (err: any) => {
          if (err.response?.status === 404) {
            // If the chip looks like a valid third-party chip serial number but we don't have it in our database,
            // we'll just set the chip details and we will try to create the chip with a GQL mutation after
            // they're registered.
            if (isValidThirdPartySerialNumber(sanitizedInput)) {
              const chipDetails = {
                serialNumber: sanitizedInput,
                // For 3rd party chips, the shortId is the same as the serial number
                shortId: sanitizedInput,
                // If the chip was not found, then it is not assigned
                isAssigned: false,
              };
              setChipDetails(chipDetails);

              // If we're already signed in, we'll go ahead and register the chip to the user's email
              if (session) {
                await registerChipAddEmail(sanitizedInput);
              }

              // Otherwise we need to move to next step
              return chipDetails;
            } else {
              throw new Error(InvalidSerialNumberErrorMessage);
            }
          } else {
            throw new Error(UnknownErrorMessage);
          }
        })
        .then((chipDetails) => {
          // If petId is in the register state, they started registration by selecting a specific pet they
          // wanted to register the chip to. When this is the case, we'll go ahead and register the chip to that pet
          // after they've submitted the serial number.
          if (registerState.petId) {
            return registerChipAddExistingPet({
              variables: {
                input: {
                  chipId: chipDetails.shortId,
                  petId: registerState.petId,
                },
              },
            });
          }
        })
        .then(() => {
          setLoading(false);
          onStepFinished('chip');
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    },
    [
      onStepFinished,
      registerChipAddEmail,
      registerChipAddExistingPet,
      registerState.petId,
      session,
      setChipDetails,
      setChipId,
    ],
  );

  return {
    handleSubmit,
    error,
    loading,
  };
}

function SerialNumberForm() {
  const {
    registerState: { chipId: registeringChipId },
  } = useContext(RegisterChipContextV2);

  const { handleSubmit, error, loading } = useSubmitSerialNumber();

  return (
    <>
      <SingleTextFieldForm
        fieldName="serialNumber"
        fieldPlaceholder="ex: ABC123"
        errorResponse={error}
        initialValues={{ serialNumber: registeringChipId ?? '' }}
        onSubmit={({ serialNumber }) => {
          handleSubmit(serialNumber);
        }}
        submitButton={<Button primary type="submit" label="Continue" disabled={loading} />}
      />
    </>
  );
}

export default function SerialNumberStep() {
  return (
    <div className={classNames('serial-number-step', styles.container)}>
      <div className={styles.mainContent}>
        <div className={styles.progress}>
          <div className={styles.progressText}>Step 1: Register</div>
          <div className={styles.progressFillBoxes}>
            <div className={styles.progressFillBox1} />
            <div className={styles.progressFillBox2} />
            <div className={styles.progressFillBox3} />
            <div className={styles.progressFillBox4} />
            <div className={styles.progressFillBox5} />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Register your Fi Nano microchip</div>
          <div className={styles.subtitle}>
            Enter the 6 digit number from your yellow QR code card. Other microchips’ numbers might be 9 to 15 digits
            long.
          </div>
        </div>
        <img alt="Fi QR Card" src={qrCardImage} />
        <SerialNumberForm />
      </div>
    </div>
  );
}
