import { gql } from '@apollo/client';

export const allBreedsQuery = gql`
  query allBreeds {
    allBreeds {
      ...breed
    }
  }

  fragment breed on Breed {
    id
    name
  }
`;
