import classnames from 'classnames';
import React from 'react';
import InternalOrExternalLink from '../../utils/InternalOrExternalLink';
import styles from './Navbar.module.scss';

type BaseProps = React.PropsWithChildren<{
  href: string;
  className?: string;
  /**
   * Automatically set the "selected" class if location.pathname === href.
   */
  autoSelect?: boolean;
}>;

type NavProps = BaseProps & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const NavLink = (props: NavProps) => {
  const classes = classnames(styles.navLink, props.className);

  return (
    <InternalOrExternalLink to={props.href} onClick={props.onClick} className={classes}>
      {props.children}
    </InternalOrExternalLink>
  );
};

export default NavLink;
