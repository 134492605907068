import * as React from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

export interface ICheckboxProps {
  checked: boolean;
  onChange(newChecked: boolean): void;
  label: string;
  className?: string;
  disabled?: boolean;
}

/**
 * A custom styled checkbox.
 */
const Checkbox = ({ checked, onChange, label, className, disabled }: ICheckboxProps) => (
  <label className={classNames(styles.checkboxCustom, className)}>
    <input type="checkbox" checked={checked} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
    <span
      className={classNames(styles.checkboxIcon, {
        [styles.checkboxIconDisabled]: disabled,
        [styles.checkboxIconChecked]: checked,
      })}
    />
    {label}
  </label>
);

export default Checkbox;
