import { gqlTypes } from '../types/gqlTypes';
import { ageInMonths } from './petUtils';

const BASE_SHOP_URL =
  'https://shop.tryfi.com/products/smart-collar-v3?utm_campaign=PP_Nano&utm_medium=Nano&utm_source=Web';

export const getShopURL = (
  source: string,
  petInfo: {
    name: string;
    gender: gqlTypes.Gender;
    yearOfBirth: number;
    monthOfBirth?: number | null;
    dayOfBirth?: number | null;
    weight: number;
    breed?: { __typename?: 'Breed'; id: string; name: string } | null;
  },
) => {
  const url = new URL(BASE_SHOP_URL);
  url.searchParams.append('utm_content', source);
  if (petInfo) {
    url.searchParams.append('pet_name', petInfo.name);
    url.searchParams.append('pet_weight', Math.round(petInfo.weight).toString());
    url.searchParams.append('pet_breed', petInfo.breed?.name || '');
    url.searchParams.append('pet_gender', petInfo.gender.toLowerCase());
    url.searchParams.append(
      'pet_age',
      ageInMonths(petInfo.yearOfBirth, petInfo.monthOfBirth || 0, petInfo.dayOfBirth || 0).toString(),
    );
  }
  return url.toString();
};
