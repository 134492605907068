import { gql } from '@apollo/client';
import { renderFragments, resellerChipFragment } from '../../lib/fragments';

export const resellerChipDetailsQuery = gql`
  query resellerChipDetails($id: ID!) {
    resellerChip(id: $id) {
      ...resellerChip
    }

    currentUser {
      id
      email
      userHouseholds {
        householdRole
        household {
          pets {
            id
          }
        }
      }
    }
  }

  ${renderFragments(resellerChipFragment)}
`;

export const registerThirdPartyChip = gql`
  mutation resellerRegisterThirdPartyChip($input: ThirdPartyChipInput!) {
    registerThirdPartyChip(input: $input) {
      id
      serialNumber
    }
  }
`;
