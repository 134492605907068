import { gql } from '@apollo/client';
import { baseChipFragment, renderFragments, resellerChipFragment } from '../../lib/fragments';

export const chipRegistrationAddEmailMutation = gql`
  mutation NANO_chipRegistrationAddEmail($input: ChipRegistrationAddEmailInput!) {
    chipRegistrationAddEmail(input: $input) {
      ...baseChip
    }
  }

  ${renderFragments(baseChipFragment)}
`;

export const chipRegistrationAddNewPetMutation = gql`
  mutation NANO_chipRegistrationAddNewPet($input: ChipRegistrationAddNewPetInput!) {
    chipRegistrationAddNewPet(input: $input) {
      ...baseChip
    }
  }

  ${renderFragments(baseChipFragment)}
`;

export const chipRegistrationAddExistingPetMutation = gql`
  mutation NANO_chipRegistrationAddExistingPet($input: ChipRegistrationAddExistingPetInput!) {
    chipRegistrationAddExistingPet(input: $input) {
      ...baseChip
    }
  }

  ${renderFragments(baseChipFragment)}
`;

export const chipRegistrationUpdateContactMutation = gql`
  mutation NANO_chipRegistrationUpdateContact($input: ChipRegistrationUpdateContactInput!) {
    chipRegistrationUpdateContact(input: $input) {
      ...baseChip
    }
  }

  ${renderFragments(baseChipFragment)}
`;

export const assignNewPetToChipMutation = gql`
  mutation NANO_assignNewPetToChip($input: AssignNewPetToChipInput!) {
    assignNewPetToChip(input: $input) {
      ...resellerChip
    }
  }

  ${renderFragments(resellerChipFragment)}
`;

export const assignNewOwnerToChipMutation = gql`
  mutation NANO_assignNewOwnerToChip($input: AssignNewOwnerToChipInput!) {
    assignNewOwnerToChip(input: $input) {
      ...resellerChip
    }
  }

  ${renderFragments(resellerChipFragment)}
`;
