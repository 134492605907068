import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dogPlaceholderImage from '../../../assets/images/dog-placeholder.png';
import Loading from '../../../components/Loading';
import { gqlTypes } from '../../../types/gqlTypes';
import { chipDetailsQuery } from '../../ChipDetails/ChipDetails.graphql';
import { useUpdatePet } from '../../lib/mutations';
import { assignNewPetToChipMutation, chipRegistrationAddNewPetMutation } from '../RegisterChip.graphql';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import PetInfoForm, { CreateOrUpdatePetInput, petFormValuesFromPet } from './PetInfoForm';
import styles from './PetInfoStepV2.module.scss';
import ImageUpload, { ImageAction } from '../../components/ImageUpload/ImageUpload';
import { useUpdatePetPhoto } from '../../lib/petMutations';
import getCustomerIO from '../../../lib/customerIO';

function petForChip(chip: gqlTypes.resellerChip | gqlTypes.baseChip) {
  if (chip.__typename === 'ResellerChip') {
    return chip.pet ?? chip.transfer?.pet;
  }

  if (chip.__typename === 'AssignedChip') {
    return chip.pet;
  }

  if (chip.__typename === 'StrangerChip') {
    return chip.transfer?.pet;
  }

  return undefined;
}

function usePetInfoStep() {
  const { currentUserIsReseller, registerState, onStepFinished } = useContext(RegisterChipContextV2);
  const [mutationError, setMutationError] = useState<string | undefined>(undefined);
  const [imageAction, setImageAction] = useState<ImageAction | undefined>(undefined);
  const [updatePetPhoto, { loading: photoMutationLoading }] = useUpdatePetPhoto();

  // See if the current user already owns the chip or has an existing transfer request with a page
  const { data: chipDetails, loading: chipDetailsLoading } = useQuery<gqlTypes.chipDetails>(chipDetailsQuery, {
    variables: {
      id: registerState.chipId,
    },
    skip: !registerState.chipId,
    fetchPolicy: 'network-only',
  });

  // If the chip isn't assigned to this user or has a pending transfer then we need to go back
  // to the chip step
  const hasRegistration = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip') {
      return true;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer) {
      return true;
    }

    return false;
  }, [chipDetails]);

  // If the user has a pet associated with this chip already, let's get the pet info
  const petInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.pet) {
      return chipDetails.chip.pet;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.pet) {
      return chipDetails.chip.transfer.pet;
    }

    return undefined;
  }, [chipDetails]);

  const contactInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.latestContactHistoryEntry?.contactInfo) {
      return chipDetails.chip.latestContactHistoryEntry.contactInfo;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.contactInfo) {
      return chipDetails.chip.transfer.contactInfo;
    }

    return undefined;
  }, [chipDetails]);

  // Mutation for owner
  const [registerChipAddNewPet, { loading: registerMutationLoading }] = useMutation<
    gqlTypes.NANO_chipRegistrationAddNewPet,
    gqlTypes.NANO_chipRegistrationAddNewPetVariables
  >(chipRegistrationAddNewPetMutation);

  // Separate mutation for reseller
  const [assignNewPetToChip, { loading: resellerMutationLoading }] = useMutation<
    gqlTypes.NANO_assignNewPetToChip,
    gqlTypes.NANO_assignNewPetToChipVariables
  >(assignNewPetToChipMutation);

  // Update pet mutation
  const [updatePet, { loading: updatePetLoading }] = useUpdatePet();

  const mutation = useCallback(
    async (values: CreateOrUpdatePetInput) => {
      if (chipDetailsLoading) {
        return Promise.reject(new Error('Chip details are still loading'));
      }

      // Pet already eixsts
      if (petInfo) {
        return updatePet({
          variables: {
            input: {
              id: petInfo.id,
              ...values,
            },
          },
        });
      }

      // Update registration with new pet
      const createPet = async () => {
        if (!registerState.chipId) {
          return Promise.reject(new Error('Chip ID is missing'));
        }

        if (currentUserIsReseller) {
          return assignNewPetToChip({
            variables: {
              input: {
                chipId: registerState.chipId,
                petInfo: values,
              },
            },
          }).then((response) => {
            if (!response.data) {
              return null;
            }

            return response.data.assignNewPetToChip;
          });
        } else {
          return registerChipAddNewPet({
            variables: {
              input: {
                chipId: registerState.chipId,
                petInfo: values,
              },
            },
          }).then((response) => {
            if (!response.data) {
              return null;
            }

            return response.data.chipRegistrationAddNewPet;
          });
        }
      };

      return createPet()
        .then(async (chip) => {
          if (!chip || !imageAction) {
            return;
          }

          const pet = petForChip(chip);
          if (!pet) {
            return;
          }

          // Now that we've created the pet, and they had added an image let's add the image after the fact.
          // At the moment it's just easier to use the existing update pet photo mutation.
          return updatePetPhoto(pet.id, imageAction);
        })
        .catch((err) => {
          setMutationError(err.message);
        });
    },
    [
      assignNewPetToChip,
      chipDetailsLoading,
      currentUserIsReseller,
      imageAction,
      petInfo,
      registerChipAddNewPet,
      registerState.chipId,
      updatePet,
      updatePetPhoto,
    ],
  );

  const onPhotoEdited = useCallback(
    async (photo: ImageAction) => {
      if (petInfo?.id) {
        // If the pet already exists, update the photo
        return updatePetPhoto(petInfo.id, photo).catch((err) => {
          setMutationError(err.message);
        });
      } else {
        // Otherwise we're gonna save the photo action for after the pet gets created.
        setImageAction(photo);
      }
    },
    [petInfo, updatePetPhoto],
  );

  const onSubmit = useCallback(
    (values: CreateOrUpdatePetInput) => {
      mutation(values)
        .then(() => {
          getCustomerIO()?.identify(registerState.primaryEmail, { nano: true });
          getCustomerIO()?.track('Nano Registration: Pet Info Step Completed', {
            email: registerState.primaryEmail,
            first_name: contactInfo?.firstName,
            last_name: contactInfo?.lastName,
            secondary_first_name: contactInfo?.secondaryFirstName,
            secondary_last_name: contactInfo?.secondaryLastName,
            address_line_1: contactInfo?.line1,
            address_line_2: contactInfo?.line2,
            address_city: contactInfo?.city,
            address_state: contactInfo?.state,
            address_zipcode: contactInfo?.zipcode,
            primary_phone: contactInfo?.primaryPhone,
            secondary_phone: contactInfo?.secondaryPhone,
            primary_email: contactInfo?.primaryEmail,
            chip_serial_number: registerState.chipDetails?.serialNumber,
            pet_name: petInfo?.name,
            pet_breed: petInfo?.breed,
            pet_birthday: petInfo?.dayOfBirth,
          });
          onStepFinished('pet');
        })
        .catch((err) => {
          setMutationError(err.message);
        });
    },
    [mutation, onStepFinished, contactInfo, petInfo, registerState],
  );

  return {
    error: mutationError,
    hasRegistration,
    mutationLoading: registerMutationLoading || resellerMutationLoading || updatePetLoading || photoMutationLoading,
    onPhotoEdited,
    onSubmit,
    petInfo,
    queryLoading: chipDetailsLoading,
  };
}

export default function PetInfoStep() {
  const history = useHistory();
  const { error, hasRegistration, mutationLoading, onSubmit, onPhotoEdited, petInfo, queryLoading } = usePetInfoStep();
  const { currentUserIsReseller, registerState } = useContext(RegisterChipContextV2);

  if (queryLoading) {
    return <Loading />;
  }

  if (!hasRegistration) {
    history.replace('/register/chip');
    return null;
  }

  const firstPhoto = petInfo?.photos.first;
  return (
    <div className={classNames(styles.container, { [styles.resellerContainer]: currentUserIsReseller })}>
      <div className={styles.mainContent}>
        <div className={styles.progress}>
          <div className={styles.progressText}>Step 3: Your pet</div>
          <div className={styles.progressFillBoxes}>
            <div className={styles.progressFillBox1} />
            <div className={styles.progressFillBox2} />
            <div className={styles.progressFillBox3} />
            <div className={styles.progressFillBox4} />
            <div className={styles.progressFillBox5} />
          </div>
        </div>
        <div className={styles.title}>
          {registerState.isShelterluvUser ? `Confirm your pet's information` : `Add your pet's information`}
        </div>
        <div className={styles.photoSection}>
          <div className={styles.photoContainer}>
            <div className={styles.imageContainer}>
              <ImageUpload
                initialValue={
                  firstPhoto
                    ? {
                        kind: 'preserve',
                        photo: firstPhoto,
                      }
                    : undefined
                }
                onChange={onPhotoEdited}
              >
                {({ src, openUploadDialog }) => (
                  <>
                    <img src={src ?? dogPlaceholderImage} alt={petInfo?.name} onClick={openUploadDialog} />
                    <div className={styles.addIcon} onClick={openUploadDialog}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path
                          d="M9.45833 11.0416V16.6666H11.5417V11.0416H17.1667V8.95831H11.5417V3.33331H9.45833V8.95831H3.83333V11.0416H9.45833Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </>
                )}
              </ImageUpload>
            </div>
          </div>
        </div>
        <PetInfoForm
          onSubmit={onSubmit}
          initialValues={petInfo ? petFormValuesFromPet(petInfo) : undefined}
          responseError={error}
          disabled={mutationLoading || queryLoading}
        />
      </div>
    </div>
  );
}
