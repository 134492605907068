import React, { useCallback, useContext, useMemo } from 'react';
import styles from './ContactInfoStepV2.module.scss';
import classNames from 'classnames';
import ContactInfoForm, { formValuesFromGql } from './ContactInfoForm';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import { useMutation, useQuery } from '@apollo/client';
import { gqlTypes } from '../../../types/gqlTypes';
import { chipDetailsQuery } from '../../ChipDetails/ChipDetails.graphql';
import { assignNewOwnerToChipMutation, chipRegistrationUpdateContactMutation } from '../RegisterChip.graphql';
import { useHistory } from 'react-router-dom';
import Loading from '../../../components/Loading';
import getCustomerIO from '../../../lib/customerIO';

function useContactInfoStep() {
  const { currentUserIsReseller, registerState, onStepFinished } = useContext(RegisterChipContextV2);

  // See if the current user already owns the chip or has an existing transfer request with a page
  const {
    data: chipDetails,
    loading: chipDetailsLoading,
    error: chipDetailsError,
  } = useQuery<gqlTypes.chipDetails>(chipDetailsQuery, {
    variables: {
      id: registerState.chipId,
    },
    skip: !registerState.chipId,
    fetchPolicy: 'network-only',
  });

  // If the chip isn't assigned to this user or has a pending transfer then we need to go back
  // to the chip step
  const hasRegistration = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip') {
      return true;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer) {
      return true;
    }

    return false;
  }, [chipDetails]);

  const petInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.pet) {
      return chipDetails.chip.pet;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.pet) {
      return chipDetails.chip.transfer.pet;
    }

    return undefined;
  }, [chipDetails]);

  // If the user has contact info associated with this chip already, let's get the contact info
  const contactInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.latestContactHistoryEntry?.contactInfo) {
      return chipDetails.chip.latestContactHistoryEntry.contactInfo;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.contactInfo) {
      return chipDetails.chip.transfer.contactInfo;
    }

    return undefined;
  }, [chipDetails]);

  // Mutation for owner
  const [registerChipUpdateContact, { loading: registerMutationLoading, error: registerMutationError }] = useMutation<
    gqlTypes.NANO_chipRegistrationUpdateContact,
    gqlTypes.NANO_chipRegistrationUpdateContactVariables
  >(chipRegistrationUpdateContactMutation);

  // Separate mutation for reseller
  const [assignNewOwnerToChip, { loading: resellerMutationLoading, error: resellerMutationError }] = useMutation<
    gqlTypes.NANO_assignNewOwnerToChip,
    gqlTypes.NANO_assignNewOwnerToChipVariables
  >(assignNewOwnerToChipMutation);

  const mutation = useCallback(
    (values: gqlTypes.contactInfo) => {
      if (!registerState.chipId) {
        return Promise.reject(new Error('Chip ID is missing'));
      }

      if (chipDetailsLoading) {
        return Promise.reject(new Error('Chip details are still loading'));
      }

      // Update registration
      if (currentUserIsReseller) {
        return assignNewOwnerToChip({
          variables: {
            input: {
              chipId: registerState.chipId,
              contactInfo: values,
            },
          },
        });
      } else {
        return registerChipUpdateContact({
          variables: {
            input: {
              chipId: registerState.chipId,
              contactInfo: values,
            },
          },
        });
      }
    },
    [assignNewOwnerToChip, chipDetailsLoading, currentUserIsReseller, registerChipUpdateContact, registerState.chipId],
  );

  const onSubmit = useCallback(
    (values: gqlTypes.contactInfo) => {
      mutation(values).then(() => {
        getCustomerIO()?.identify(registerState.primaryEmail, { nano: true });
        getCustomerIO()?.track('Nano Registration: Contact Info Step Completed', {
          email: registerState.primaryEmail,
          first_name: contactInfo?.firstName,
          last_name: contactInfo?.lastName,
          secondary_first_name: contactInfo?.secondaryFirstName,
          secondary_last_name: contactInfo?.secondaryLastName,
          address_line_1: contactInfo?.line1,
          address_line_2: contactInfo?.line2,
          address_city: contactInfo?.city,
          address_state: contactInfo?.state,
          address_zipcode: contactInfo?.zipcode,
          primary_phone: contactInfo?.primaryPhone,
          secondary_phone: contactInfo?.secondaryPhone,
          primary_email: contactInfo?.primaryEmail,
          chip_serial_number: registerState.chipDetails?.serialNumber,
          pet_name: petInfo?.name,
          pet_breed: petInfo?.breed,
          pet_birthday: petInfo?.dayOfBirth,
        });
        onStepFinished('contact');
      });
    },
    [mutation, onStepFinished, contactInfo, petInfo, registerState],
  );

  return {
    error: registerMutationError || resellerMutationError || chipDetailsError,
    mutationLoading: registerMutationLoading || resellerMutationLoading,
    hasRegistration,
    onSubmit,
    contactInfo,
    petName: petInfo?.name,
    queryLoading: chipDetailsLoading,
  };
}

export default function ContactInfoStep() {
  const history = useHistory();
  const { currentUserIsReseller, registerState } = useContext(RegisterChipContextV2);
  const { error, hasRegistration, mutationLoading, onSubmit, contactInfo, petName, queryLoading } =
    useContactInfoStep();

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (queryLoading) {
    return <Loading />;
  }

  if (!hasRegistration) {
    history.replace('/register/chip');
    return null;
  }

  return (
    <div className={classNames(styles.container, { [styles.resellerContainer]: currentUserIsReseller })}>
      <div className={styles.mainContent}>
        <div className={styles.progress}>
          <div className={styles.progressText}>Step 4: Contact</div>
          <div className={styles.progressFillBoxes}>
            <div className={styles.progressFillBox1} />
            <div className={styles.progressFillBox2} />
            <div className={styles.progressFillBox3} />
            <div className={styles.progressFillBox4} />
            <div className={styles.progressFillBox5} />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {currentUserIsReseller
              ? `New owner contact information`
              : registerState.isShelterluvUser
              ? `Confirm your contact information`
              : `Add your contact information`}
          </div>
          <div className={styles.subtitle}>
            {currentUserIsReseller
              ? `This step will transfer ${petName ? `${petName}'s` : 'the'} microchip to their new owner.`
              : `If ${
                  petName ?? 'your pet'
                } is ever lost, this will help us reunite you. Your info is secure and private.`}
          </div>
        </div>
        <ContactInfoForm
          initialValues={contactInfo ? formValuesFromGql(contactInfo) : undefined}
          onSubmit={onSubmit}
          responseError={error?.message}
          onCancel={onCancel}
          disabled={mutationLoading || queryLoading}
        />
      </div>
    </div>
  );
}
