import { useQuery } from '@apollo/client';
import React from 'react';
import { viewingChipsQuery } from './ViewingChips.graphql';
import ChipList from './ChipList';
import { gqlTypes } from '../../types/gqlTypes';
import StatusTypeMap from './StatusTypeMap';

export interface ViewingChipsProps {
  statusType: gqlTypes.ChipStatusType;
}

const PAGE_SIZE = 100;

export default function ViewingChips({ statusType }: ViewingChipsProps) {
  const queryVariables = {
    limit: PAGE_SIZE,
    statusType,
  };
  const { data } = useQuery<gqlTypes.viewingChips, gqlTypes.viewingChipsVariables>(viewingChipsQuery, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const chips = data?.currentUser.chipReseller?.chips.chips ?? [];
  const refetchQueries = [{ query: viewingChipsQuery, variables: queryVariables }];

  return <ChipList chips={chips} statusTypeMap={StatusTypeMap.uniform(statusType)} refetchQueries={refetchQueries} />;
}
