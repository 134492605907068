import React from 'react';
import { DateTime } from 'luxon';

import styles from './LastUpdated.module.scss';

export interface LastUpdatedProps {
  date: Date;
}

export default function LastUpdated({ date }: LastUpdatedProps) {
  const dateTime = DateTime.fromJSDate(date);
  return <div className={styles.main}>Last Updated: {dateTime.toFormat(`M.d.yyyy`)}</div>;
}
