import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import ModalActions from './ModalActions/ModalActions';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './ModalConfirm.module.scss';
import classNames from 'classnames';

export interface ModalConfirmProps {
  message: React.ReactNode;
  open: boolean;
  acceptText?: string;
  rejectText?: string;
  onAccept?(): void;
  onReject?(): void;
  onClose?(): void;
  className?: string;
  closeOnDocumentClick?: boolean;
  closeOnEscape?: boolean;
  hideActions?: boolean;
}

export default function ModalConfirm({
  open,
  message,
  acceptText = 'OK',
  rejectText = 'Cancel',
  onAccept,
  onReject,
  className,
  onClose,
  closeOnDocumentClick = false,
  closeOnEscape = false,
  hideActions = false,
}: ModalConfirmProps) {
  const ref = useRef<PopupActions>(null);
  return (
    <Popup
      open={open}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={closeOnEscape}
      modal
      contentStyle={{ padding: '0', fontSize: '26px' }}
      ref={ref}
      onClose={onClose}
    >
      <div className={classNames('modal-confirm', className)}>
        <div className={styles.message}>{message}</div>
        {!hideActions && (
          <ModalActions
            actions={[
              {
                text: acceptText,
                primary: true,
                onClick() {
                  if (onAccept) {
                    onAccept();
                  }
                },
              },
              {
                text: rejectText,
                onClick() {
                  ref.current?.close();
                  if (onReject) {
                    onReject();
                  }
                },
              },
            ]}
          />
        )}
      </div>
    </Popup>
  );
}
