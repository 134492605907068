import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/fi-logo.svg';
import { ReactComponent as HamburgerMenuIcon } from '../../../assets/images/hamburger-menu.svg';
import { logout } from '../../../lib/authentication';
import { Links, NavLinks as NavLinksArray } from '../../../lib/constants';
import { AppState } from '../../../lib/store';
import { userChipResellerQuery } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import styles from './Navbar.module.scss';
import NavLink from './Navlink';

function LoginLogoutButton() {
  const isLoggedIn = useSelector((state: AppState) => !!state.session);

  const doLogout = () => logout().catch((err) => console.error(err));

  return isLoggedIn ? (
    <button onClick={doLogout} className={styles.navbarBtn}>
      Log Out
    </button>
  ) : (
    <Link to="/login" className={styles.navbarBtn}>
      Log In
    </Link>
  );
}

function FiNanoNavLink({ autoSelect }: { autoSelect?: boolean }) {
  return (
    <NavLink href={'/'} className={!autoSelect ? 'selected' : undefined} autoSelect={autoSelect}>
      Fi Nano
    </NavLink>
  );
}

function ResellerNavLinks() {
  return (
    <>
      <NavLink href={Links.vetChipList()} autoSelect>
        Manage Chips
      </NavLink>
      <NavLink href={Links.shop}>Buy Nano</NavLink>
    </>
  );
}

function ConsumerNavLinks() {
  return (
    <NavLink href="/manage" autoSelect>
      Manage Chips
    </NavLink>
  );
}

function LoggedInNavLinks() {
  const { data, loading } = useQuery<gqlTypes.userChipReseller>(userChipResellerQuery);

  const isAcceptedReseller = data?.currentUser.chipReseller?.status === gqlTypes.ResellerStatus.ACCEPTED;

  return (
    <>
      <FiNanoNavLink autoSelect />
      {!loading && (isAcceptedReseller ? <ResellerNavLinks /> : <ConsumerNavLinks />)}
      <NavLink href="https://tryfi.com">Back to tryfi.com</NavLink>
    </>
  );
}

function DefaultNavLinks() {
  return (
    <>
      {NavLinksArray.map(({ path, text }, i) => {
        return (
          <NavLink key={`navlink-${i}`} href={path}>
            {text}
          </NavLink>
        );
      })}
      <FiNanoNavLink />
    </>
  );
}

function NavLinks() {
  const loggedIn = useSelector((state: AppState) => !!state.session);
  return loggedIn ? <LoggedInNavLinks /> : <DefaultNavLinks />;
}

export default function Navbar() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleNav = useCallback(
    (forceValue?: boolean) => {
      if (isExpanded !== forceValue) {
        setIsExpanded(forceValue ?? !isExpanded);
      }
    },
    [isExpanded],
  );

  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(() => location);

  // Mount a listener to the router's path change event to collapse the navbar
  useEffect(() => {
    if (location !== prevLocation) {
      toggleNav(false);
      setPrevLocation(location);
    }
  }, [toggleNav, location, prevLocation]);

  return (
    <header
      className={classNames(styles.navbarContainer, {
        [styles.expanded]: isExpanded,
      })}
    >
      <nav className={classNames(styles.navbar)}>
        <div className={styles.navbarLeft}>
          <div
            className={styles.navbarToggler}
            onClick={() => {
              toggleNav();
            }}
          >
            <HamburgerMenuIcon />
          </div>
          <div className={styles.navbarBrand}>
            <a href={Links.marketingSite} rel="noopener noreferrer">
              <Logo />
            </a>
          </div>
        </div>
        <div className={styles.navbarNav}>
          <NavLinks />
        </div>
        <div className={styles.navbarAction}>
          <LoginLogoutButton />
        </div>
      </nav>
    </header>
  );
}
