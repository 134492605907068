import React, { useState } from 'react';
import { useBackBehaviorContext } from '../lib/BackBehaviorContext';
import { useBackAction } from '../lib/withBackBehavior';
import ModalConfirm from './modals/ModalConfirm';

import backImage from '../../assets/images/back.svg';
import closeX from '../../assets/images/close-x.svg';

import styles from './AppBar.module.scss';

export interface AppBarProps {
  allowEdit?: boolean;
  onEditClicked?(): void;
  editing?: boolean;
  title: string;
  hideBackButton?: boolean;
  backLink?: string;
}

export default function AppBar({ title, allowEdit, onEditClicked, editing, hideBackButton, backLink }: AppBarProps) {
  const { behavior: backBehavior, needsConfirmation } = useBackBehaviorContext();

  const doClose = useBackAction({ backLink });

  const [confirmingClose, setConfirmingClose] = useState(false);

  const backImageProps: React.ImgHTMLAttributes<HTMLImageElement> = needsConfirmation
    ? {
        src: closeX,
        alt: 'Cancel',
        width: '20',
      }
    : {
        src: backImage,
        alt: 'Go back',
      };

  return (
    <div className={styles.main}>
      {needsConfirmation && (
        <ModalConfirm
          onAccept={() => doClose()}
          open={confirmingClose}
          message="Are you sure you want to leave without saving your changes?"
          onReject={() => setConfirmingClose(false)}
        />
      )}
      <div className={styles.back}>
        {backBehavior && !editing && !hideBackButton && (
          <img
            alt="Back"
            {...backImageProps}
            onClick={() => {
              if (needsConfirmation) {
                setConfirmingClose(true);
              } else {
                doClose();
              }
            }}
          />
        )}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.edit}>{allowEdit && <div onClick={onEditClicked}>{editing ? 'Cancel' : 'Edit'}</div>}</div>
    </div>
  );
}
