import classNames from 'classnames';
import { Formik, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import Button from '../../../components/Button/Button';
import Form from '../../../components/form/Form';
import FormField from '../../../components/form/FormField';
import { roundedWeightInLbs } from '../../../lib/petUtils';
import { lbsToKg } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import BreedSelect from '../../components/ChipDetails/PetForm/BreedSelect';
import GenderSelect from '../../components/ChipDetails/PetForm/GenderSelect';
import styles from './PetInfoStep.module.scss';

export interface PetInfo {
  name?: string | null;
  gender?: gqlTypes.Gender | null;
  breedId?: string | null;
  isMixedBreed?: boolean;
  birthDate?: string | null;
  weight?: string | null;
  householdId?: string | null;
}

const NUMBER = /^\d*$/;

const validationSchema = yup.object({
  name: yup.string().nullable().required(`Please enter the pet's name`),
  breedId: yup.string().required(`Please select a pet breed, or tap "Not a dog"`),
  gender: yup.mixed<gqlTypes.Gender>().required(`Please select a gender`),
  weight: yup
    .string()
    .nullable()
    .required(`Please enter a weight`)
    .matches(NUMBER, `Please enter a number for the weight`),
  birthDate: yup
    .string()
    .nullable()
    .required(`Please enter a birth date`)
    .test('valid_date', `Please provide a valid birth date`, function (value) {
      try {
        if (value) {
          const date = DateTime.fromISO(value);
          return date <= DateTime.utc();
        }
      } catch (_err) {}

      return false;
    }),
});

export const petFormValuesFromPet = (pet: gqlTypes.basePet): PetInfo => ({
  gender: pet.gender,
  name: pet.name,
  weight: roundedWeightInLbs(pet.weight).toString(),
  birthDate: DateTime.fromObject({
    year: pet.yearOfBirth,
    month: pet.monthOfBirth ?? 1,
    day: pet.dayOfBirth ?? 1,
  }).toISODate(),
  breedId: pet.breed?.id ?? '',
  isMixedBreed: !pet.isPurebred,
});

interface FormListenerProps {
  onValuesChanged?(values: PetInfo): void;
  onIsValidChanged?(isValid: boolean): void;
}

function FormListener({ onIsValidChanged, onValuesChanged }: FormListenerProps) {
  const { values: valuesWithActionTag, isValid } = useFormikContext<PetInfo & { actionTag?: string }>();

  useEffect(() => {
    if (onValuesChanged) {
      const { actionTag: _ignored, ...values } = valuesWithActionTag;
      onValuesChanged(values);
    }
  }, [valuesWithActionTag, onValuesChanged]);

  useEffect(() => {
    if (onIsValidChanged) {
      onIsValidChanged(isValid);
    }
  }, [isValid, onIsValidChanged]);

  return <React.Fragment />;
}

export interface CreateOrUpdatePetInput {
  name: string;
  gender: gqlTypes.Gender;
  species: gqlTypes.Species;
  breedId?: string | null;
  isPurebred: boolean;
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
  householdId?: string | null;
}

interface ContactInfoFormProps extends FormListenerProps {
  initialValues?: PetInfo;
  onSubmit?: (values: CreateOrUpdatePetInput, errorMessage?: string) => void;
  onSkip?: () => void;
  responseError?: string;
  disabled?: boolean;
  submitLabel?: string;
}

export default function PetInfoForm({
  initialValues,
  responseError,
  onSubmit,
  onSkip,
  onValuesChanged,
  onIsValidChanged,
  disabled,
  submitLabel = 'Complete Registration',
}: ContactInfoFormProps) {
  return (
    <Formik<PetInfo>
      initialValues={
        initialValues ?? {
          name: '',
          gender: null,
          breedId: null,
          birthDate: '',
          weight: '',
        }
      }
      onSubmit={(values: PetInfo) => {
        if (!onSubmit) {
          return;
        }

        let birthDate: DateTime | undefined;
        try {
          if (values.birthDate) {
            birthDate = DateTime.fromISO(values.birthDate);
          }
        } catch (err) {}

        if (!values.name || !values.gender || !values.weight || !birthDate) {
          return;
        }

        onSubmit({
          name: values.name.trim(),
          gender: values.gender,
          species: gqlTypes.Species.DOG,
          breedId: values.breedId,
          isPurebred: !values.isMixedBreed,
          yearOfBirth: birthDate.year,
          monthOfBirth: birthDate.month,
          dayOfBirth: birthDate.day,
          weight: lbsToKg(Number(values.weight)),
        });
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, submitForm, values }) => {
        const isFormEmpty = !values.name && !values.breedId && !values.birthDate && !values.gender && !values.weight;

        return (
          <Form compact onSubmit={handleSubmit}>
            <FormListener onIsValidChanged={onIsValidChanged} onValuesChanged={onValuesChanged} />
            <div className="form-group">
              <FormField type="text" name="name" placeholder="Name" />
              <GenderSelect name="gender" />
            </div>
            <BreedSelect name="breedId" isMixedBreed />
            <div className={classNames('form-group', styles.birthDateFormGroup)}>
              <FormField
                type="date"
                name="birthDate"
                placeholder="Birth date"
                classNames={!values.birthDate ? 'date-input--empty' : undefined}
                max={DateTime.utc().toISODate()}
              />
              <FormField type="text" name="weight" placeholder="Weight (lbs)" />
            </div>
            <div className="form-group form-group--action form-group--multistep-action">
              {responseError && <div className="form-field__error">{responseError}</div>}
              {onSubmit && (
                <Button
                  primary
                  label={submitLabel}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();

                    // We want to let the user just bypass this form completely, so if they haven't filled
                    // anything out yet we'll skip. Otherwise we'll validate & submit.
                    if (onSkip && isFormEmpty) {
                      onSkip();
                      return;
                    }

                    submitForm();
                  }}
                  disabled={disabled}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
