import React from 'react';

import AppBar from './AppBar';

export default function NotFound() {
  return (
    <div>
      <AppBar title="Not Found" />
    </div>
  );
}
