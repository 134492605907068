import classNames from 'classnames';
import React from 'react';
import { Route } from 'react-router-dom';
import { withEnsureLoggedIn } from '../../components/utils/EnsureLoggedIn';
import { AppViewContextProvider } from '../lib/AppViewContext';
import ListChips from '../ListChips/ListChips';
import ResellerChipDetails from '../ResellerChipDetails/ResellerChipDetails';

import styles from './AppViewStyles.module.scss';

const LoggedInListChips = withEnsureLoggedIn(ListChips, 'reseller');
const LoggedInResellerChipDetails = withEnsureLoggedIn(ResellerChipDetails, 'reseller');

export default function DesktopVetViews() {
  return (
    <AppViewContextProvider isDesktop>
      <div className={classNames(styles.main, styles.desktop)}>
        <Route exact path="/vet/chips" component={LoggedInListChips} />
        <Route exact path="/vet/chip" component={LoggedInResellerChipDetails} />
      </div>
    </AppViewContextProvider>
  );
}
