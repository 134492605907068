import React, { useCallback } from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import { useHistory } from 'react-router-dom';
import { Links } from '../../../lib/constants';
import styles from './ChipsWithoutPetList.module.scss';

interface ChipWithoutPetRowProps {
  chip: gqlTypes.baseChip;
}

function ChipWithoutPetRow({ chip }: ChipWithoutPetRowProps) {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(Links.manageChipDetails({ id: chip.shortId }));
  }, [chip.shortId, history]);

  return (
    <div className={styles.main} onClick={onClick}>
      <div className={styles.status}>Finish your registration</div>
      <div className={styles.primarySerialNumber}>{chip.shortId}</div>
      {chip.shortId !== chip.serialNumber && <div className={styles.secondarySerialNumber}>{chip.serialNumber}</div>}
    </div>
  );
}

interface ChipsWithoutPetListProps {
  chips: gqlTypes.baseChip[];
}

export default function ChipsWithoutPetList({ chips }: ChipsWithoutPetListProps) {
  return (
    <>
      {chips.map((chip) => {
        return <ChipWithoutPetRow key={chip.id} chip={chip} />;
      })}
    </>
  );
}
