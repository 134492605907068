import React from 'react';
import { ChipContactHistoryEntry, ChipRedactedContactInfoProps, ChipSearchResult } from '@barkinglabs/nano-shared';
import Card from './Card';
import DataElements from './DataElements';
import List from '../../../components/utils/List';
import LastUpdated from './LastUpdated';
import ContactModal, { searchResultToContactModalProps } from './ContactModal';

export interface ContactHistoryCardProps {
  historyEntry: ChipContactHistoryEntry;
  searchResult: ChipSearchResult;
}

function makeName(contactInfo: ChipRedactedContactInfoProps) {
  return contactInfo.firstName + (contactInfo.lastInitial ? ` ${contactInfo.lastInitial}.` : '');
}

export default function ContactHistoryCard({ searchResult, historyEntry }: ContactHistoryCardProps) {
  const { contactInfo } = historyEntry;
  return (
    <Card>
      <List relaxed>
        {historyEntry.createdAt && (
          <List.Item>
            <LastUpdated date={new Date(historyEntry.createdAt)} />
          </List.Item>
        )}
        <List.Item>
          <DataElements
            elements={[
              {
                title: `Previous Owner`,
                content: makeName(contactInfo),
              },
              ...(contactInfo.city
                ? [
                    {
                      title: `City`,
                      content: contactInfo.city,
                    },
                  ]
                : []),
            ]}
            large
            action={
              <ContactModal
                {...searchResultToContactModalProps(searchResult)}
                contactTarget={{
                  kind: 'contactHistory',
                  id: historyEntry.id,
                }}
                trigger={<div className="btn-black-outline">Send Message</div>}
              />
            }
          />
        </List.Item>
      </List>
    </Card>
  );
}
