import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import SignupForm from '../../components/SignupForm/SignupForm';
import { AppState } from '../../lib/store';

import styles from './Signup.module.scss';

export default function Signup() {
  const areLoggedIn = useSelector((state: AppState) => !!state.session);

  if (areLoggedIn) {
    return <Redirect to="/manage" />;
  }

  return (
    <div className="single-column single-column--narrow">
      <SignupForm />
      <div className={styles.actions}>
        <Link to="/login" className="link link__underlined">
          Already have an account?
        </Link>
      </div>
    </div>
  );
}
