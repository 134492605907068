import classNames from 'classnames';
import { gqlTypes } from '../../../types/gqlTypes';

import dogPlaceholderImage from '../../../assets/images/dog-placeholder.png';
import ImageUpload, { ImageAction } from '../ImageUpload/ImageUpload';
import { useCallback } from 'react';
import { useUpdatePetPhoto } from '../../lib/petMutations';
import ContactInfoSourceBadge from '../ContactInfoSourceBadge/ContactInfoSourceBadge';

import styles from './PetProfile.module.scss';

export type PetProfileStatus = 'pending' | 'active' | 'inactive';

export interface PetProfileProps {
  pet: gqlTypes.basePet;
  status?: PetProfileStatus;
  className?: string;
  onClick?(): void;
  enablePhotoEditing?: boolean;
  onPetEdited?(): void;
  contactInfoSource?: gqlTypes.ChipContactHistoryEntrySource | null;
}

export default function PetProfile({
  pet,
  status,
  className,
  onClick,
  enablePhotoEditing,
  onPetEdited,
  contactInfoSource,
}: PetProfileProps) {
  let statusText = `Active`;
  if (status === 'pending') {
    statusText = `Transfer Pending`;
  } else if (status === 'inactive') {
    statusText = `Inactive`;
  }

  const firstPhoto = pet.photos.first;
  const imageUrl = firstPhoto?.image.fullSize ?? dogPlaceholderImage;

  const [updatePetPhoto] = useUpdatePetPhoto();
  const onPhotoEdited = useCallback(
    async (photo: ImageAction) => {
      try {
        await updatePetPhoto(pet.id, photo);
      } catch (err) {
        window.alert((err as any).message);
      }

      if (onPetEdited) {
        onPetEdited();
      }
    },
    [pet.id, updatePetPhoto, onPetEdited],
  );

  const imageElement = enablePhotoEditing ? (
    <ImageUpload
      initialValue={
        firstPhoto
          ? {
              kind: 'preserve',
              photo: firstPhoto,
            }
          : undefined
      }
      onChange={onPhotoEdited}
      onError={(errorMessage) => {
        window.alert(errorMessage);
      }}
    >
      {({ src, openUploadDialog }) => (
        <img src={src ?? dogPlaceholderImage} alt={pet.name} onClick={openUploadDialog} />
      )}
    </ImageUpload>
  ) : (
    <img src={imageUrl} alt={pet.name} />
  );

  return (
    <div className={classNames(styles.main, className)} onClick={onClick}>
      <div
        className={classNames(styles.picture, {
          [styles.editablePicture]: enablePhotoEditing,
        })}
      >
        {imageElement}
      </div>
      <div className={styles.data}>
        <div className={classNames(styles.status, styles[status ?? 'active'])}>{statusText}</div>
        <div>
          <div className={styles.name}>{pet.name}</div>
          {pet.breed && <div className={styles.breed}>{pet.breed.name}</div>}
        </div>
      </div>
      <div className={styles.contactInfoSourceContainer}>
        {contactInfoSource && <ContactInfoSourceBadge contactInfoSource={contactInfoSource} />}
      </div>
    </div>
  );
}
