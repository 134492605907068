import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './BreedSelectModal.module.scss';
import classNames from 'classnames';
import { getFiGrowthBook } from '../../lib/growthbook';

interface Option {
  value: string;
  label: string;
}

interface SelectModalProps {
  options: Option[];
  setValue: (value: string) => void;
  value: string;
}

const BreedSelectModal: React.FC<SelectModalProps> = ({ options, setValue, value }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const breedPlaceholderText = getFiGrowthBook().getFeatureValue('nano-registration-size-suggestion-experiment', false)
    ? 'Breed'
    : 'Breed (optional)';

  return (
    <div className={styles.breedSelectModal}>
      <button
        onClick={handleOpen}
        className={classNames(styles.button, {
          [styles.buttonPlaceholder]: !options.find((option: Option) => option.value === value),
        })}
      >
        {options.find((option: Option) => option.value === value)?.label || breedPlaceholderText}
      </button>
      <Popup open={open} onClose={handleClose} modal>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>Select Breed</div>
          <button className={styles.modalCloseButton} onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M10.2326 12.0003L5 17.2329L6.76777 19.0007L12.0004 13.7681L17.233 19.0007L19.0008 17.233L13.7681 12.0003L19.0007 6.76777L17.2329 5L12.0004 10.2326L6.76782 5.00001L5.00006 6.76777L10.2326 12.0003Z"
                fill="black"
              />
            </svg>
          </button>
          <input
            className={styles.searchBar}
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className={styles.modalList}>
            {filteredOptions.map((option) => (
              <li
                className={styles.modalListItem}
                key={option.value}
                onClick={() => {
                  setValue(option.value);
                  setSearchTerm('');
                  setOpen(false);
                }}
              >
                {option.label}
              </li>
            ))}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default BreedSelectModal;
