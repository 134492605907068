import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { isValidThirdPartySerialNumber } from '@barkinglabs/nano-shared';

import { gqlTypes } from '../../types/gqlTypes';
import { searchResellerChipsQuery } from './ResellerSearchResults.graphql';
import ChipList from './ChipList';
import StatusTypeMap from './StatusTypeMap';
import { Links } from '../../lib/constants';
import { useAppViewContext } from '../lib/AppViewContext';

export interface ResellerSearchResultsProps {
  query: string;
}

export default function ResellerSearchResults({ query }: ResellerSearchResultsProps) {
  const { isDesktop } = useAppViewContext();

  const queryVariables = {
    input: {
      shortId: query,
      serialNumber: query,
    },
  };
  const { data } = useQuery<gqlTypes.searchResellerChips, gqlTypes.searchResellerChipsVariables>(
    searchResellerChipsQuery,
    {
      variables: queryVariables,
    },
  );
  const refetchQueries = [{ query: searchResellerChipsQuery, variables: queryVariables }];

  const chips = data?.currentUser.chipReseller?.searchChips.chips ?? [];
  const statusTypeMap = StatusTypeMap.fromResults(chips);

  // If there are no results, but the query looks like a valid serial number,
  // send the user to the Chip Details page and it'll ask them if they want to register
  // the chip or not. This is done primarily so we can get the register chip logic
  // on the current mobile clients which scan barcodes and sends them directly
  // to the Chip Details page.
  if (chips.length === 0 && isValidThirdPartySerialNumber(query)) {
    return <Redirect push to={Links.resellerChipDetails(query, { forceBackBehavior: 'back' }, isDesktop)} />;
  }

  // TODO: Error or loading state
  return (
    <div>
      <ChipList chips={chips} statusTypeMap={statusTypeMap} refetchQueries={refetchQueries} />
    </div>
  );
}
