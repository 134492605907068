import React from 'react';

import styles from './Comparison.module.scss';

function ComparisonHeader() {
  return (
    <div className={styles.header}>
      {/* <div className={styles.preHeader}>MICROCHIP vs. GPS COLLAR</div> */}
      <h3>MICROCHIP vs. GPS COLLAR</h3>
      <div className={styles.desc}>
        <p>
          The ideal combination is not either a Fi Nano or a Fi Collar but both. These technologies complement each
          other to increase the safety of your dog.
        </p>
        <p>
          The Fi Collar leverages cutting edge technology to prevent your dog from escaping, and recovering it promptly
          if she does.
        </p>
        <p>
          Fi Nano is a last line of defense in case your dog escapes and your Fi Collar is not charged. Fi Nano is also
          a good line of defense against dog theft - as once implanted under the skin a Fi Nano microchip cannot be
          removed.
        </p>
      </div>
    </div>
  );
}

interface ComparisonCellProps {
  main: string;
  bullets?: string[];
}

function ComparisonCell({ main, bullets }: ComparisonCellProps) {
  return (
    <td>
      <div>{main}</div>
      {bullets && (
        <ul>
          {bullets.map((bullet, i) => (
            <li key={i}>{bullet}</li>
          ))}
        </ul>
      )}
    </td>
  );
}

interface ComparisonRowProps {
  title: string;
  nano: ComparisonCellProps;
  other: ComparisonCellProps;
}

function ComparisonRow({ title, nano, other }: ComparisonRowProps) {
  return (
    <>
      <tr>
        <td colSpan={2} className={styles.mobileTitle}>
          {title}
        </td>
      </tr>
      <tr>
        <td className={styles.desktopTitle}>{title}</td>
        <ComparisonCell {...nano} />
        <ComparisonCell {...other} />
      </tr>
    </>
  );
}

const rows: ComparisonRowProps[] = [
  {
    title: `Technology`,
    nano: {
      main: `Sterile RFID microchip, no battery`,
    },
    other: {
      main: `GPS, LTE-M, Bluetooth low energy module with a lithium ion battery - FCC safe electromagnetic field certified and IP68 waterproof.`,
    },
  },
  {
    title: `Procedure`,
    nano: {
      main: `Implanted under the skin by a vet or health professional.`,
    },
    other: {
      main: `Smart collar worn by the dog instead or in addition to his standard collar.`,
    },
  },
  {
    title: `Method of recovery`,
    nano: {
      main: `Via animal control or vet scanning the microchip - and looking up the microchip scan number in the national database to contact the owner.`,
    },
    other: {
      main: `Remotely via satellites - Follow your dog's location on your phone`,
    },
  },
  {
    title: `Notifies owner when a dog escapes`,
    nano: {
      main: `No`,
    },
    other: {
      main: `Yes`,
    },
  },
  {
    title: `Connects with Fi dog owners in your neighborhood`,
    nano: {
      main: `No`,
    },
    other: {
      main: `Yes`,
    },
  },
  {
    title: `Monitors activity and health of your dog`,
    nano: {
      main: `No`,
    },
    other: {
      main: `Yes`,
    },
  },
];

export default function Comparison() {
  return (
    <div className={styles.mainB}>
      <ComparisonHeader />
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div>Product</div>
            </th>
            <th className="uppercase">
              <div>
                F<span className="no-uppercase">i</span> Nano - Microchip
              </div>
            </th>
            <th className="uppercase">
              <div>
                F<span className="no-uppercase">i</span> Collar - GPS Tracking
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <ComparisonRow {...row} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
