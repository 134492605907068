import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { login } from '../../lib/authentication';

interface FormValues {
  email: string;
  password: string;
}

const initialFormValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().required(`Please enter your email address`),
  password: yup.string().required(`Please enter your password`),
});

export default function LoginForm() {
  return (
    <div>
      <Formik<FormValues>
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            await login(values.email, values.password);
          } catch (err) {
            setStatus({ errorMessage: (err as any).message });
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialFormValues}
      >
        {({ handleSubmit, isSubmitting, status, submitForm }) => (
          <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <div className="form-field">
                <Field type="text" name="email" placeholder="Email" />
                <ErrorMessage name="email" component="div" className="form-field__error" />
              </div>
            </div>
            <div className="form-group">
              <div className="form-field">
                <Field type="password" name="password" placeholder="Password" />
                <ErrorMessage name="password" component="div" className="form-field__error" />
              </div>
            </div>
            <div className="form-group form-group--action form-group--action__wide">
              <div className="form-field">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-yellow"
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  Login
                </button>
                {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
