import React from 'react';

import {
  PetProps,
  ChipSearchResult,
  ChipContactHistoryEntry,
  ChipRedactedContactInfoProps,
} from '@barkinglabs/nano-shared';
import Card from './Card';
import DataElements from './DataElements';
import List from '../../../components/utils/List';
import PetName from './PetName';
import LastUpdated from './LastUpdated';
import ContactModal, { searchResultToContactModalProps } from './ContactModal';
import { dayOfBirthString, ageString, weightString } from '../../../lib/petUtils';
import RequestUpdateLink from './RequestUpdateLink';

import styles from './PetCard.module.scss';

export interface PetCardProps {
  pet: PetProps | null;
  searchResult: ChipSearchResult;
}

function makeName(contactInfo: ChipRedactedContactInfoProps) {
  let name = contactInfo.firstName;
  if (contactInfo.lastInitial) {
    name += ` ${contactInfo.lastInitial}.`;
  }
  return name;
}

function CurrentOwner({ historyEntry }: { historyEntry: ChipContactHistoryEntry }) {
  return (
    <DataElements
      elements={[
        {
          title: `Current Owner`,
          content: (
            <>
              {makeName(historyEntry.contactInfo)}
              {historyEntry.contactInfo.obfuscatedEmail ? (
                <>
                  {' '}
                  <span className={styles.obfuscatedEmail}>({historyEntry.contactInfo.obfuscatedEmail})</span>
                </>
              ) : null}
            </>
          ),
        },
        {
          title: `City`,
          content: historyEntry.contactInfo.city,
        },
      ]}
      large
    />
  );
}

export default function PetCard({ pet, searchResult }: PetCardProps) {
  const { chip, contactHistory } = searchResult;
  const contactHistoryEntry = contactHistory.length > 0 ? contactHistory[0] : undefined;

  return (
    <>
      <RequestUpdateLink serialNumber={searchResult.chip.serialNumber} contactToken={searchResult.contactToken} />
      <Card pictureUrl={pet?.photoUrl ?? undefined}>
        <List relaxed>
          {contactHistoryEntry && (
            <List.Item>
              <LastUpdated date={new Date(contactHistoryEntry.createdAt)} />
            </List.Item>
          )}
          {pet && (
            <>
              <List.Item>
                <PetName pet={pet} />
              </List.Item>
              <List.Item className={styles.divided}>
                <DataElements
                  elements={[
                    {
                      title: `Date of Birth`,
                      content: dayOfBirthString(pet),
                    },
                    {
                      title: `Age`,
                      content: ageString(pet),
                    },
                    {
                      title: `Weight`,
                      content: weightString(pet),
                    },
                  ]}
                />
              </List.Item>
            </>
          )}
          <List.Item>
            <DataElements
              elements={[
                {
                  title: `Chip ID`,
                  content: chip.serialNumber,
                },
              ]}
            />
          </List.Item>
          {contactHistoryEntry && (
            <>
              <List.Item className={styles.divided}>
                <CurrentOwner historyEntry={contactHistoryEntry} />
              </List.Item>
              <List.Item className={styles.action}>
                <ContactModal
                  trigger={<div className="btn-yellow-flat">Send Message</div>}
                  {...searchResultToContactModalProps(searchResult)}
                  contactTarget={{
                    kind: 'contactHistory',
                    id: contactHistoryEntry.id,
                  }}
                />
              </List.Item>
            </>
          )}
        </List>
      </Card>
    </>
  );
}
