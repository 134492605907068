import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './SignupOption.module.scss';

interface SignupOptionProps {
  title: string;
  subtitle: string;
  linkText: string;
  linkHref: string;
  secondaryLink?: boolean;
}

export default function SignupOption({ title, subtitle, linkText, linkHref, secondaryLink }: SignupOptionProps) {
  return (
    <div className={styles.main}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div>
        <Link className={classNames('btn', secondaryLink ? 'btn-black-outline' : 'btn-black')} to={linkHref}>
          {linkText}
        </Link>
      </div>
    </div>
  );
}
