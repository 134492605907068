import React, { ReactNode } from 'react';

import styles from './AppDataElements.module.scss';

export interface AppDataElementsProps {
  elements: Array<{
    title: string;
    content: ReactNode;
  }>;
}

export default function AppDataElements({ elements }: AppDataElementsProps) {
  return (
    <div className={styles.main}>
      {elements.map(({ title, content }, i) => (
        <div key={i} className={styles.element}>
          <div className={styles.title}>{title}</div>
          <div className={styles.content}>{content}</div>
        </div>
      ))}
    </div>
  );
}
