import { FieldProps, Field, FieldAttributes } from 'formik';
import React from 'react';
import Select, { Props as SelectProps, OptionsType } from 'react-select';

// Component that lets you use react-select with Formik
// https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8

export const SelectFieldComponent: React.SFC<SelectProps & FieldProps> = ({
  options,
  field,
  form,
  menuPlacement,
  placeholder,
  autoComplete,
}) => (
  <Select
    className="react-select-container"
    placeholder={placeholder}
    menuPlacement={menuPlacement}
    classNamePrefix="react-select"
    options={options}
    name={field.name}
    autoComplete={autoComplete}
    value={options ? (options as OptionsType<any>).find((option) => option.value === field.value) : ''}
    onChange={(option) => form.setFieldValue(field.name, option?.value)}
    onBlur={field.onBlur}
  />
);

export type SelectFieldProps = FieldAttributes<any> & SelectProps;

export default function SelectField(props: SelectFieldProps) {
  return <Field component={SelectFieldComponent} {...props} />;
}
