import { MutationFunctionOptions } from '@apollo/client';
import { webApiClient } from '../../lib/authentication';
import { gqlTypes } from '../../types/gqlTypes';
import { ImageAction } from '../components/ImageUpload/ImageUpload';
import { useCreatePet, useRemovePhoto, useSetPetPrimaryPhoto, useUpdatePet } from './mutations';
import { useState } from 'react';

export interface PetWithPhotoInput {
  petInput: gqlTypes.CreatePetInput;
  photo: ImageAction | undefined;
}

interface CreatePetWithPhotoResponse {
  petId: string;
}

interface UploadMediaResponse {
  fullSize: string;
  id: string;
  photoId: string;
}

async function uploadMedia(file: File) {
  const formData = new FormData();
  formData.set('file', file);
  const response = await webApiClient.post<UploadMediaResponse>('/media/add', formData);
  return response.data.photoId;
}

export function useUpdatePetPhoto(): [(petId: string, photo: ImageAction) => Promise<void>, { loading: boolean }] {
  const [removePhoto] = useRemovePhoto();
  const [setPetPrimaryPhoto] = useSetPetPrimaryPhoto();
  const [loading, setLoading] = useState(false);

  return [
    async (petId, photo) => {
      if (photo) {
        setLoading(true);

        try {
          if (photo.kind === 'replace') {
            const photoId = await uploadMedia(photo.file);
            // TODO: Do we need to delete an existing photo too?
            await setPetPrimaryPhoto({
              variables: {
                input: { petId, photoId },
              },
            });
          } else if (photo.kind === 'remove') {
            await removePhoto({
              variables: {
                photoId: photo.photoId,
              },
            });
          }
        } finally {
          setLoading(false);
        }
      }
    },
    {
      loading,
    },
  ];
}

export function useUpdatePetWithPhoto(): [(petId: string, input: PetWithPhotoInput) => Promise<void>] {
  const [updatePet] = useUpdatePet();
  const [updatePetPhoto] = useUpdatePetPhoto();

  return [
    async (petId, input) => {
      await updatePet({
        variables: {
          input: {
            id: petId,
            ...input.petInput,
          },
        },
      });
      if (input.photo) {
        await updatePetPhoto(petId, input.photo);
      }
    },
  ];
}

export function useCreatePetWithPhoto(): [
  (
    input: PetWithPhotoInput,
    mutationOptions?: Pick<MutationFunctionOptions, 'refetchQueries'>,
  ) => Promise<CreatePetWithPhotoResponse>,
] {
  const [createPet] = useCreatePet();
  const [setPetPrimaryPhoto] = useSetPetPrimaryPhoto();

  return [
    async (input, options = {}) => {
      const { refetchQueries } = options;
      let photoId: string | undefined;
      if (input.photo && input.photo.kind === 'replace') {
        photoId = await uploadMedia(input.photo.file);
      }
      const petResponse = await createPet({
        variables: { input: input.petInput },
        refetchQueries: !photoId ? refetchQueries : undefined,
        awaitRefetchQueries: true,
      });
      const petId = petResponse.data!.createPet.id;
      if (photoId) {
        await setPetPrimaryPhoto({
          variables: {
            input: {
              petId,
              photoId,
            },
          },
          refetchQueries,
        });
      }
      return { petId };
    },
  ];
}
