import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

export interface CardProps {
  children: ReactNode;
  pictureUrl?: string;
}

export default function Card({ children, pictureUrl }: CardProps) {
  return (
    <div className={classNames(styles.main, 'card')}>
      {pictureUrl && (
        <div className={styles.pictureContainer}>
          <img src={pictureUrl} alt="A dog" />
        </div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
