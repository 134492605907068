import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import loadingImage from '../../assets/images/classic_spinner.gif';
import styles from './Loading.module.scss';

interface LoadingProps {
  className?: string;
}
export default function Loading({ className }: LoadingProps) {
  const [display, setDisplay] = useState(false);

  const displayMessage = useCallback(() => {
    setDisplay(true);
  }, []);

  useEffect(() => {
    // A bit of a timeout to try and prevent quick flashes of the loading spinner
    const timer = setTimeout(displayMessage, 50);
    return () => clearTimeout(timer);
  }, [displayMessage]);

  if (!display) {
    return null;
  }

  return (
    <div className={classNames([styles.main, className])}>
      <img src={loadingImage} alt="Loading Spinner" />
    </div>
  );
}
