import { gql, DocumentNode } from '@apollo/client';
import { print } from 'graphql/language/printer';

interface Fragment {
  symbol: symbol;
  body: DocumentNode;
  deps?: () => Fragment[];
}

export function renderFragments(...fragments: Fragment[]) {
  const uniqueFragments: Fragment[] = [];
  const seen = new Set<symbol>();
  const fragmentStack = [...fragments];
  while (fragmentStack.length > 0) {
    const fragment = fragmentStack.shift();
    if (!fragment) {
      break;
    }
    if (!seen.has(fragment.symbol)) {
      uniqueFragments.push(fragment);
      seen.add(fragment.symbol);
      if (fragment.deps) {
        fragmentStack.push(...fragment.deps());
      }
    }
  }

  const fragmentString = uniqueFragments.map((f) => print(f.body)).join('\n');
  return gql(fragmentString);
}

export const miniChipFragment: Fragment = {
  symbol: Symbol.for(`miniChip`),
  body: gql`
    fragment miniChip on ResellerChip {
      id
      shortId
      serialNumber
      pet {
        id
        name
      }
      latestContactHistoryEntry {
        source
        contactInfo {
          firstName
          lastName
        }
      }
    }
  `,
};

export const resellerChipFragment: Fragment = {
  symbol: Symbol.for('resellerChip'),
  body: gql`
    fragment resellerChip on ResellerChip {
      id
      shortId
      serialNumber
      cartonId
      status
      pet {
        ...basePet
      }
      transfer {
        ...transfer
        pet {
          ...basePet
        }
      }
      latestContactHistoryEntry {
        source
        contactInfo {
          ...contactInfo
        }
      }
    }
  `,
  deps: () => [basePetFragment, transferFragment, contactInfoFragment],
};

export const baseChipFragment: Fragment = {
  symbol: Symbol.for('baseChip'),
  body: gql`
    fragment baseChip on BaseChip {
      __typename
      id
      shortId
      serialNumber
      ... on AssignedChip {
        pet {
          ...basePet
        }
        latestContactHistoryEntry {
          source
          contactInfo {
            ...contactInfo
          }
        }
        transfer {
          ...transfer
        }
      }
      ... on StrangerChip {
        transfer {
          ...transfer
        }
      }
    }
  `,
  deps: () => [basePetFragment, contactInfoFragment, transferFragment],
};

export const transferFragment: Fragment = {
  symbol: Symbol.for('transfer'),
  body: gql`
    fragment transfer on ChipTransfer {
      id
      contactInfo {
        ...contactInfo
      }
      origin
      status
      canResolve
      pet {
        ...basePet
      }
      chip {
        shortId
        serialNumber
      }
    }
  `,
  deps: () => [contactInfoFragment],
};

export const contactInfoFragment: Fragment = {
  symbol: Symbol.for('contactInfo'),
  body: gql`
    fragment contactInfo on ChipContactInfo {
      firstName
      lastName
      secondaryFirstName
      secondaryLastName
      primaryPhone
      secondaryPhone
      primaryEmail
      secondaryEmail
      line1
      line2
      city
      state
      zipcode
      country
    }
  `,
};

export const photoFragment: Fragment = {
  symbol: Symbol.for('photo'),
  body: gql`
    fragment photo on Photo {
      id
      image {
        fullSize
      }
    }
  `,
};

export const basePetFragment: Fragment = {
  symbol: Symbol.for('basePet'),
  body: gql`
    fragment basePet on BasePet {
      id
      name
      gender
      species
      breed {
        id
        name
      }
      isPurebred
      yearOfBirth
      monthOfBirth
      dayOfBirth
      weight
      additionalInfo
      photos {
        first {
          ...photo
        }
      }
    }
  `,
  deps: () => [photoFragment],
};

export const userPetFragment: Fragment = {
  symbol: Symbol.for('userPet'),
  body: gql`
    fragment userPet on Pet {
      ...basePet

      chip {
        id
        shortId
        serialNumber
      }

      pendingChip {
        id
        shortId
      }
    }
  `,
  deps: () => [basePetFragment],
};
