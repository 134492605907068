import { gql } from '@apollo/client';
import { renderFragments, miniChipFragment } from '../../lib/fragments';

export const searchResellerChipsQuery = gql`
  query searchResellerChips($input: ChipQueryInput!) {
    currentUser {
      id
      chipReseller {
        id
        searchChips(query: $input) {
          chips {
            ...miniChip
            status
          }
        }
      }
    }
  }

  ${renderFragments(miniChipFragment)}
`;
