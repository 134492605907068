import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ApplyHero.module.scss';

export default function ApplyHero() {
  return (
    <div className={styles.main}>
      <div>
        <h3>Are you a Vet, Rescue, or Breeder?</h3>
        <div className="font-md">Request a Fi Nano sample today!</div>
      </div>

      <div>
        <Link to="/apply" className="btn-yellow-flat">
          Request a sample
        </Link>
      </div>
    </div>
  );
}
