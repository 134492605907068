import React from 'react';
import { ErrorMessage, Formik, Field, FormikHelpers, FormikValues } from 'formik';
import Button from '../Button/Button';
import styles from './SingleTextFieldForm.module.scss';
import classNames from 'classnames';

import Form from './Form';

interface SingleTextFieldFormProps<T> {
  fieldName: string;
  fieldPlaceholder: string;
  fieldDisabled?: boolean;
  errorResponse?: React.ReactNode;
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  submitButton?: React.ReactNode;
  validationSchema?: any | (() => any);
}

export default function SingleTextFieldForm<T extends FormikValues>({
  fieldName,
  fieldPlaceholder,
  fieldDisabled,
  errorResponse,
  initialValues,
  onSubmit,
  validationSchema,
  submitButton = <Button primary label="Submit" type="submit" />,
}: SingleTextFieldFormProps<T>) {
  return (
    <Formik<T> onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Form compact onSubmit={handleSubmit}>
          <div className="form-section">
            <div className="form-group">
              <div className="form-field">
                <Field
                  className={classNames('form-input', styles.input)}
                  name={fieldName}
                  placeholder={fieldPlaceholder}
                  disabled={fieldDisabled}
                />
              </div>
              <div className="form-field form-field--inline-action">{submitButton}</div>
            </div>
            <ErrorMessage name={fieldName} component="div" className="form-field__error" />
            {errorResponse && <div className="form-field__error">{errorResponse}</div>}
          </div>
        </Form>
      )}
    </Formik>
  );
}
