import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './OutlineButton.module.scss';

export interface OutlineButtonProps {
  children: ReactNode;
  disabled?: boolean;
  secondary?: boolean;
  /**
   * Removes the uppercase text transform and adds border-radius, for a softer feel.
   */
  soft?: boolean;
  onClick?(): void;
}

export default function OutlineButton({ children, onClick, disabled, secondary, soft }: OutlineButtonProps) {
  const className = classNames(styles.main, {
    [styles.disabled]: disabled,
    [styles.primary]: !secondary,
    [styles.secondary]: secondary,
    [styles.soft]: soft,
  });

  return (
    <div
      className={className}
      onClick={(e) => {
        e.preventDefault();
        if (!disabled && onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
}
