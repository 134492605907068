import React from 'react';
import FormField from './FormField';

export interface SingleTextFieldFormGroupProps {
  name: string;
  type?: string;
  placeholder: string;
  autoComplete?: string;
  min?: string;
}

export default function SingleTextFieldFormGroup({
  name,
  type,
  placeholder,
  autoComplete,
  min,
}: SingleTextFieldFormGroupProps) {
  return (
    <div className="form-group">
      <FormField type={type ?? 'text'} name={name} placeholder={placeholder} autoComplete={autoComplete} min={min} />
    </div>
  );
}
