import { PureQueryOptions } from '@apollo/client';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Links } from '../../lib/constants';
import { gqlTypes } from '../../types/gqlTypes';
import ContactInfoSourceBadge from '../components/ContactInfoSourceBadge/ContactInfoSourceBadge';
import { useAppViewContext } from '../lib/AppViewContext';
import { firstLastInitial } from '../lib/utils';
import RemoveChipModal from './RemoveChipModal';

import styles from './ChipItem.module.scss';

export interface ChipItemProps {
  chip: gqlTypes.miniChip;
  statusType: gqlTypes.ChipStatusType;
  refetchQueries?: PureQueryOptions[];
}

const NO_OWNER = Array(4).fill('-').join(' ');

export default function ChipItem({ chip, statusType, refetchQueries }: ChipItemProps) {
  const history = useHistory();

  const isDesktop = useAppViewContext().isDesktop;
  const showRemoveChipOption = statusType !== gqlTypes.ChipStatusType.REVIEW;

  const onClick = () => {
    if (statusType === gqlTypes.ChipStatusType.SHIPPED) {
      history.push(Links.register({ id: chip.id }));
    } else {
      history.push(Links.resellerChipDetails(chip.id, { forceBackBehavior: 'back' }, isDesktop));
    }
  };

  return (
    <div className={styles.main} onClick={onClick}>
      <div className={styles.chipDetails}>
        <div className={classNames(styles.icon, styles[statusType.toLowerCase()])} />
        <div className={styles.chipId}>
          <div className={styles.shortId}>{chip.serialNumber === chip.shortId ? 'Non Fi Nano Chip' : chip.shortId}</div>
          <div className={styles.serialNumber}>{chip.serialNumber}</div>
          {!!chip.latestContactHistoryEntry?.source && (
            <ContactInfoSourceBadge contactInfoSource={chip.latestContactHistoryEntry.source} />
          )}
          {showRemoveChipOption && <RemoveChipModal chipShortId={chip.shortId} refetchQueries={refetchQueries} />}
        </div>
      </div>
      <div>
        <div className={styles.secondary}>
          {chip.latestContactHistoryEntry ? firstLastInitial(chip.latestContactHistoryEntry.contactInfo) : NO_OWNER}
        </div>
        {chip.pet && <div className={styles.secondary}>(and {chip.pet.name})</div>}
      </div>
    </div>
  );
}
