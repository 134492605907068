import * as qs from 'querystring';

import { AppViewLinkState } from '../appview/lib/types';

export const Colors = {
  lightGrayBackground: 'rgb(249, 249, 249)',
};

export const Links = {
  instagram: 'https://www.instagram.com/fi.dogs/',
  facebook: 'https://www.facebook.com/dogcollarsbyfi/',
  twitter: 'https://twitter.com/fidogtweets',
  pinterest: 'https://www.pinterest.com/fidogs/',
  blog: 'https://blog.tryfi.com',
  faq: 'https://support.tryfi.com',
  contact: 'https://support.tryfi.com/hc/en-us/requests/new',
  accessories: 'https://shop.tryfi.com/accessories',
  shop: 'https://shop.tryfi.com',
  marketingSite: 'https://tryfi.com',

  stories: 'https://tryfi.com/stories',
  tech: 'https://tryfi.com/learn',
  collar: 'https://tryfi.com/collar',
  app: 'https://tryfi.com/theapp',
  reviews: 'https://tryfi.com/reviews',

  iosAppStore: `https://itunes.apple.com/us/app/fi-gps-dog-tracker/id1438036784?ls=1&mt=8`,
  androidAppStore: `https://play.google.com/store/apps/details?id=com.barkinglabs.fi`,

  login: (redirectUri?: string) => `/login?${qs.stringify({ redirectUri })}`,
  scanChip: `fi://scanchip`,
  closeAppView: () => `fi://closeappview`,

  resellerChipDetails(id: string, state: AppViewLinkState, isDesktop: boolean) {
    return {
      pathname: isDesktop ? '/vet/chip' : '/appview/resellerchipdetails',
      search: `?${qs.stringify({ id })}`,
      state,
    };
  },
  listChips: () => `/appview/listchips`,
  vetChipList: () => `/vet/chips`,
  vetChip: (id: string) => `/vet/chip?id=${encodeURIComponent(id)}`,

  sendFiNanoToYourVet: `mailto:?${qs.stringify({
    subject: `Fi Nano`,
    body: `Hi,\n\nI would love for you to look at the Fi Nano microchip. You can find more information at https://nano.tryfi.com.`,
  })}`,

  manageChipDetails: (params: { id: string }) => `/manage/chipdetails?${qs.stringify(params)}`,
  manageHome: `/manage`,

  register: (params: { id?: string; petId?: string } = {}) => `/register?${qs.stringify(params)}`,
};

export const NavLinks = [
  { path: Links.tech, text: 'How it Works' },
  { path: Links.collar, text: 'The Collar' },
  { path: Links.app, text: 'The App' },
  { path: Links.reviews, text: 'Reviews' },
  { path: Links.stories, text: 'Stories' },
  { path: Links.blog, text: 'Off Leash' },
];

export const apiHost = process.env.REACT_APP_API_HOST;
