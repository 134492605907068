import { useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import { manageHomeQuery } from '../../ManageHome/ManageHome.graphql';
import ContactInfoForm from '../../RegisterChip/ContactInfoStep/ContactInfoForm';
import PetInfoStep from './PetInfoStep';
import styles from './FinalizeTransferView.module.scss';

export interface FinalizeTransferViewProps {
  chipShortId: string;
  contactInfo: gqlTypes.contactInfo;
  onContactInfoUpdated: () => void;
  onPetInfoUpdated: () => void;
}

export default function FinalizeTransferView({
  chipShortId,
  contactInfo,
  onContactInfoUpdated,
  onPetInfoUpdated,
}: FinalizeTransferViewProps) {
  const [updatedContactInfo, setUpdatedContactInfo] = useState<gqlTypes.ChipContactInfoInput | undefined>();

  const { data, loading } = useQuery<gqlTypes.manageHome>(manageHomeQuery);
  const unassignedPets = useMemo(
    () =>
      data?.currentUser.userHouseholds
        .flatMap((userHousehold) => userHousehold.household.pets)
        .filter((p) => !p.chip && !p.pendingChip) ?? [],
    [data?.currentUser.userHouseholds],
  );

  if (loading || !data) {
    return null;
  }

  if (updatedContactInfo) {
    return (
      <div className={styles.container}>
        <PetInfoStep
          chipShortId={chipShortId}
          unassignedPets={unassignedPets}
          onSuccess={onPetInfoUpdated}
          contactInfo={updatedContactInfo}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h4>Contact Information</h4>
      <ContactInfoForm
        chipShortId={chipShortId}
        initialValues={contactInfo}
        onSuccess={(response) => {
          const { __typename, ...responseContactInfo } = response.updateChipContactInfo.contactInfoResponse.contactInfo;

          onContactInfoUpdated();
          setUpdatedContactInfo(responseContactInfo);
        }}
        mutationOptions={{
          refetchQueries: [{ query: manageHomeQuery }],
        }}
      />
    </div>
  );
}
