import React from 'react';
import { useHistory } from 'react-router-dom';
import { gqlTypes } from '../../../types/gqlTypes';
import PetProfile, { PetProfileStatus } from '../PetProfile/PetProfile';
import { Links } from '../../../lib/constants';

import styles from './PetListRow.module.scss';

export interface PetListRowProps {
  pet: gqlTypes.userPet;
  onClick?: () => void;
}

export default function PetListRow({ pet, onClick }: PetListRowProps) {
  const history = useHistory();
  let status: PetProfileStatus;
  if (pet.chip) {
    status = 'active';
  } else if (pet.pendingChip) {
    status = 'pending';
  } else {
    status = 'inactive';
  }

  const defaultOnClick = () => {
    const anyChip = pet.chip ?? pet.pendingChip;
    if (anyChip) {
      history.push(Links.manageChipDetails({ id: anyChip.shortId }));
    } else {
      history.push(Links.register({ petId: pet.id }));
    }
  };

  return (
    <>
      <PetProfile pet={pet} status={status} className={styles.main} onClick={onClick ?? defaultOnClick} />
    </>
  );
}
