import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppViewContextProvider } from '../lib/AppViewContext';
import { withEnsureLoggedIn } from '../../components/utils/EnsureLoggedIn';
import classNames from 'classnames';
import Register from '../../pages/Register/Register';
import ChipDetails from '../ChipDetails/ChipDetails';
import ManageHome from '../ManageHome/ManageHome';

import styles from './AppViewStyles.module.scss';

function ChipDetailsWrapper() {
  return <ChipDetails forManage />;
}

const LoggedInChipDetails = withEnsureLoggedIn(ChipDetailsWrapper, 'owner');
const LoggedInManageHome = withEnsureLoggedIn(ManageHome, 'owner');

export default function DesktopManageViews() {
  let routes = (
    <Switch>
      <Route exact path="/manage/chipdetails" component={LoggedInChipDetails} />
      <Route exact path="/manage/start" component={Register} />
      <Route exact path="/manage" component={LoggedInManageHome} />
    </Switch>
  );

  return (
    <AppViewContextProvider isDesktop>
      <div className={classNames(styles.main, styles.desktop)}>{routes}</div>
    </AppViewContextProvider>
  );
}
