import React from 'react';
import { Route } from 'react-router-dom';
import ListChips from '../ListChips/ListChips';
import ChipDetails from '../ChipDetails/ChipDetails';
import ResellerChipDetails from '../ResellerChipDetails/ResellerChipDetails';
import { Helmet } from 'react-helmet';
import { AppViewContextProvider } from '../lib/AppViewContext';

import styles from './AppViewStyles.module.scss';

const AppViews = () => (
  <AppViewContextProvider>
    <div className={styles.main}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      <Route exact path="/appview/listchips" component={ListChips} />
      <Route exact path="/appview/chipdetails" component={ChipDetails} />
      <Route exact path="/appview/resellerchipdetails" component={ResellerChipDetails} />
    </div>
  </AppViewContextProvider>
);

export default AppViews;
