import React, { useState } from 'react';
import { impersonate } from '../../lib/authentication';

export default function Impersonate() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email: string = (event.target as any).email.value.trim();

    try {
      await impersonate(email);
      // HACK: Do a hard refresh to reset all of the apollo and redux state, now that
      // we're logged in as another user. I've found it hard to correctly clear this all
      // in-memory, so doing this hard-refresh as a workaround for any cache bugs.
      window.location.href = '/';
    } catch (error) {
      debugger;
      setErrorMessage((error as any).message);
    }
  };

  return (
    <div className="single-column">
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <div className="form-field">
            <input type="text" name="email" placeholder="Email" />
          </div>
        </div>
        <div className="form-group form-group--action">
          <div className="form-field">
            <button type="submit" className="btn-yellow">
              Impersonate
            </button>
            {errorMessage && <div className="form-field__error">{errorMessage}</div>}
          </div>
        </div>
      </form>
    </div>
  );
}
