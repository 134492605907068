import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';

import styles from './TransferHero.module.scss';

function Accepted() {
  return (
    <div className={styles.main}>
      <div className={styles.contents}>
        <h1>Transfer Accepted!</h1>
      </div>
    </div>
  );
}

function Rejected() {
  return (
    <div className={styles.main}>
      <div className={styles.contents}>
        <h1>Transfer Rejected</h1>
      </div>
    </div>
  );
}

function AlreadyTransferred() {
  return (
    <div className={styles.main}>
      <div className={styles.contents}>
        <h1>Chip has already been transferred</h1>
      </div>
    </div>
  );
}

export default function Transfer() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/accepted`} component={Accepted} />
      <Route path={`${path}/rejected`} component={Rejected} />
      <Route path={`${path}/already_transferred`} component={AlreadyTransferred} />
    </Switch>
  );
}
