import axios from 'axios';
import { identifyUser, resetAnalytics } from './analytics/events';
import { Session, sessionActions } from './reducers/session';
import { store } from './store';
import { apiHost } from './constants';

export const webApiClient = axios.create({
  baseURL: apiHost,
  headers: { 'x-api-key': process.env.REACT_APP_WEB_API_AUTH_KEY },
  withCredentials: true,
});

export async function createSessionFromExistingCredentials() {
  const response = await webApiClient.get('/auth/currentuser');
  const session: Session = {
    userId: response.data.userId,
    email: response.data.email,
  };
  store.dispatch(sessionActions.create({ session }));
}

export async function login(email: string, password: string): Promise<Session> {
  if (!email || !password) {
    throw new Error('Please provide both an email and password');
  }

  let session: Session;
  try {
    const response = await webApiClient.post('/auth/login', {
      email,
      password,
    });

    session = {
      userId: response.data.userId,
      email: response.data.email,
    };

    identifyUser(session.userId, { email: session.email });
  } catch (error) {
    if ((error as any).response?.status === 401) {
      throw new Error('Invalid email or password');
    }
    throw new Error('An unknown error occurred');
  }

  store.dispatch(sessionActions.create({ session }));
  return session;
}

export async function logout() {
  try {
    await webApiClient.post('/auth/logout');
    store.dispatch(sessionActions.destroy({}));
    resetAnalytics();
  } catch (error) {
    throw new Error('We were unable to log you out');
  }
}

export class UserAlreadyExistsError extends Error {}

export async function signup(
  email: string,
  password: string | null,
  firstName: string,
  lastName: string | null,
  chipRegistrationStep?: boolean,
): Promise<Session> {
  if (!email || !firstName) {
    throw new Error('Please provide an email and first name');
  }

  let session: Session;
  try {
    const response = await webApiClient.post('/auth/signup', {
      email,
      password: password ?? undefined,
      firstName,
      lastName,
      // Don't identify Nano-only users in Customer.IO yet since they haven't bought a collar.
      noIdentify: true,
      nanoChipRegistration: chipRegistrationStep ?? false,
    });

    session = {
      userId: response.data.userId,
      email,
    };

    identifyUser(session.userId, { email: session.email });
  } catch (err) {
    const error = err as any;
    if (error.response.status === 409) {
      if (
        error.response.data.error &&
        error.response.data.error.code &&
        error.response.data.error.code === 'already-exists'
      ) {
        throw new UserAlreadyExistsError('That email is already in use, try logging in first');
      }
    }

    // A bad request error can be returned if the form does not pass validation (e.g. password too short, bad email)
    // and will have a corresponding message.
    if (error.response.status === 400 && error.response.data.error.message) {
      throw new Error(error.response.data.error.message);
    }

    throw new Error(`An unknown error occurred`);
  }

  store.dispatch(sessionActions.create({ session }));
  return session;
}

export async function impersonate(email: string) {
  const response = await webApiClient.post('/auth/impersonate', { email });
  const session: Session = {
    userId: response.data.userId,
    email,
    impersonating: true,
  };
  store.dispatch(sessionActions.create({ session }));
}

export async function sendPasswordResetEmail(email: string) {
  await webApiClient.post('/auth/sendpasswordreset', { email });
}
