import React from 'react';
import classNames from 'classnames';
import { gqlTypes } from '../../types/gqlTypes';

import styles from './Tabs.module.scss';

export interface TabsProps {
  selectedStatus: gqlTypes.ChipStatusType;
  onSelectionChanged(newStatus: gqlTypes.ChipStatusType): void;
}

interface TabProps extends TabsProps {
  statusType: gqlTypes.ChipStatusType;
  text: string;
}

function Tab({ selectedStatus, onSelectionChanged, text, statusType }: TabProps) {
  const className = classNames(styles.tab, styles[statusType.toLowerCase()], {
    [styles.selected]: selectedStatus === statusType,
  });

  return (
    <div className={className} onClick={() => onSelectionChanged(statusType)}>
      {text}
    </div>
  );
}

export default function Tabs(props: TabsProps) {
  return (
    <div className={styles.main}>
      <Tab {...props} statusType={gqlTypes.ChipStatusType.SHIPPED} text="Stock" />
      <Tab {...props} statusType={gqlTypes.ChipStatusType.ACTIVE} text="Active" />
      <Tab {...props} statusType={gqlTypes.ChipStatusType.REVIEW} text="Review" />
    </div>
  );
}
