import { ErrorMessage } from 'formik';
import React from 'react';
import SelectField, { SelectFieldProps } from './SelectField';

export default function SelectFormField(props: SelectFieldProps) {
  return (
    <div className="form-field">
      <SelectField {...props} />
      <ErrorMessage name={props.name} component="div" className="form-field__error" />
    </div>
  );
}
