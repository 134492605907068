import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import styles from './ModalAlert.module.scss';

export interface ModalAlertProps {
  message: string;
  open: boolean;
  onClose?(): void;
}

export default function ModalAlert({ open, message, onClose }: ModalAlertProps) {
  const ref = useRef<PopupActions>(null);
  return (
    <Popup open={open} onClose={onClose} modal contentStyle={{ padding: '0', fontSize: '26px' }} ref={ref}>
      <div>
        <div className={styles.message}>{message}</div>
        <div className={styles.action} onClick={() => ref.current?.close()}>
          Done
        </div>
      </div>
    </Popup>
  );
}
