import { gql } from '@apollo/client';
import { transferFragment, renderFragments, userPetFragment, baseChipFragment } from '../../lib/fragments';

export const manageHomeQuery = gql`
  query manageHome {
    currentUser {
      id
      userHouseholds {
        household {
          pets {
            ...userPet
          }
        }
      }
      chips {
        ...baseChip
      }
    }
  }

  ${renderFragments(userPetFragment, transferFragment, baseChipFragment)}
`;
