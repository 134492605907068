import React from 'react';

import { PetProps } from '@barkinglabs/nano-shared';

import styles from './PetName.module.scss';

export interface PetNameProps {
  pet: PetProps;
}

export default function PetName({ pet }: PetNameProps) {
  return (
    <div>
      <h4 className={styles.name}>{pet.name}</h4>
      <div>{pet.breedName}</div>
    </div>
  );
}
