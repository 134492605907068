import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import escapeAlertsSectionImage from '../../../assets/images/success-escape.png';
import gpsSectionImage from '../../../assets/images/success-gps.png';
import videoPosterImage from '../../../assets/images/success-video-poster.png';
import fiCollarImage from '../../../assets/images/fi-collar.png';
import Loading from '../../../components/Loading';
import { trackClickShopCollarCTA } from '../../../lib/analytics/events';
import { Links } from '../../../lib/constants';
import { gqlTypes } from '../../../types/gqlTypes';
import { chipDetailsQuery } from '../../ChipDetails/ChipDetails.graphql';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import styles from './SuccessStepV2.module.scss';
import { getFiGrowthBook } from '../../lib/growthbook';
import { getShopURL } from '../../../lib/shopURL';

export default function SuccessStep() {
  const { registerState } = useContext(RegisterChipContextV2);

  // See if the current user already owns the chip or has an existing transfer request with a page
  const { data: chipDetails, loading: chipDetailsLoading } = useQuery<gqlTypes.chipDetails>(chipDetailsQuery, {
    variables: {
      id: registerState.chipId,
    },
    skip: !registerState.chipId,
    fetchPolicy: 'network-only',
  });

  const petInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.pet) {
      return chipDetails.chip.pet;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.pet) {
      return chipDetails.chip.transfer.pet;
    }

    return undefined;
  }, [chipDetails]);

  const isTransfer = useMemo(() => {
    return chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer;
  }, [chipDetails]);

  if (chipDetailsLoading) {
    return <Loading />;
  }

  if (!petInfo || !registerState.chipId) {
    return <Redirect to="/manage" />;
  }

  const firstProfilePhoto = petInfo?.photos.first;
  const profilePhotoUrl = firstProfilePhoto?.image.fullSize;
  const renderProgressBarExperiment = getFiGrowthBook().getFeatureValue('nano-success-page-progess-bar', true);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{isTransfer ? 'Request sent!' : 'Success!'}</div>
            <div className={styles.subtitle}>
              {isTransfer
                ? `Your transfer request for ${petInfo.name} has been sent.`
                : `${petInfo.name} has been registered with Fi Nano.`}
              <br />
              This is a huge step toward total pet safety.
            </div>
          </div>
        </div>
        <div className={styles.petCard}>
          {profilePhotoUrl && (
            <div className={styles.petCardImage}>
              <img src={profilePhotoUrl} alt={petInfo?.name} />
            </div>
          )}
          <div className={styles.petCardInfo}>
            <div
              className={classNames(styles.petCardStatus, {
                [styles.isTransfer]: isTransfer,
              })}
            >
              Status: {isTransfer ? `Transfer pending` : `Active`}
            </div>
            <div className={styles.petCardName}>{petInfo.name}</div>
            <div className={styles.petCardChipId}>Chip ID: {registerState.chipId}</div>
          </div>
        </div>
        <div className={styles.ctaContainer}>
          <a className={styles.ctaButton} href={Links.register()}>
            Register more
          </a>
          <a className={styles.ctaButton} href={Links.manageHome}>
            See all chips
          </a>
        </div>
      </div>
      {!renderProgressBarExperiment && (
        <>
          <div className={styles.hero}>
            <div className={styles.heroTitle}>Microchips don't track your dog</div>
            <div className={styles.heroSubtitle}>The Fi smart Collar does</div>
            <div className={styles.heroCta}>
              <a
                href={getShopURL('NanoSuccess', petInfo)}
                className={styles.heroCtaShop}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  trackClickShopCollarCTA('nano-header-microchips-dont-track-dog');
                }}
              >
                Get the collar
              </a>
              <div className={styles.heroMobileNotification}>
                <div className={styles.heroMobileNotificationIcon}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                    <g clip-path="url(#clip0_993_3608)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.7526 0.578217L13.4648 4.43786L17.6754 4.43786L18.9631 0.578217L14.7526 0.578217Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.13818 17.4203L13.3487 17.4203L17.2084 5.84137L12.9978 5.84137L9.13818 17.4203Z"
                        fill="black"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.0086 6.54313H7.85071L8.0858 5.84137C8.43668 4.78874 8.78755 4.43787 10.3069 4.43787H12.0612L13.349 0.578217H9.48931C7.38405 0.578217 5.27878 1.63085 4.22615 4.78874L3.64019 6.54313H1.18405L0.015625 10.0519H2.47177L0.015625 17.4203H4.22615L6.68229 10.0519H9.84019L11.0086 6.54313Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_993_3608">
                        <rect
                          width="19.089"
                          height="16.8421"
                          fill="white"
                          transform="translate(19.1045 17.4203) rotate(-180)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className={styles.heroMobileNotificationContent}>
                  <div className={styles.heroMobileNotificationTitle}>Bella is out without an owner</div>
                  <div className={styles.heroMobileNotificationSubtitle}>
                    ⚠️ Last near 7925 NW Expressway. Enable Lost Dog Mode to track.
                  </div>
                </div>
                <div className={styles.heroMobileNotificationTime}>now</div>
              </div>
            </div>
          </div>
          <div className={styles.collar}>
            <div className={styles.review}>
              <div className={styles.reviewStars}>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                  <path
                    d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                    fill="black"
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                  <path
                    d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                    fill="black"
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                  <path
                    d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                    fill="black"
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                  <path
                    d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                    fill="black"
                  />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                  <path
                    d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className={styles.reviewCopy}>
                <div className={styles.reviewTitle}>“Constantly monitored and&nbsp;safe!”</div>
                <div className={styles.reviewSubtitle}>@shirley2792 | Fi Member</div>
              </div>
            </div>

            <a
              href={getShopURL('NanoSuccess', petInfo)}
              className={styles.collarCta}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackClickShopCollarCTA('nano-customer-testimonial');
              }}
            >
              Get the collar
            </a>
          </div>
        </>
      )}
      {renderProgressBarExperiment && (
        <>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar}>
              <div className={styles.progressBarElement}>
                <div className={styles.progressBarText}>Fi Nano</div>
                <div className={styles.progressBarIconYellow}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="check">
                      <path
                        id="Vector"
                        d="M18.3334 5.40002L16.9335 4L8.23328 13.2L3.73328 9L2.33337 10.4L8.33337 16L18.3334 5.40002Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className={styles.progressBarElement}>
                <div className={styles.progressBarText}>Register</div>
                <div className={styles.progressBarIconYellow}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="check">
                      <path
                        id="Vector"
                        d="M18.3334 5.40002L16.9335 4L8.23328 13.2L3.73328 9L2.33337 10.4L8.33337 16L18.3334 5.40002Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className={styles.progressBarElement}>
                <div className={styles.progressBarText}>GPS Tracking</div>
                <div className={styles.progressBarIconWhite}>
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="kit">
                      <path
                        id="Union"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.7709 5.83334H5.56258C5.36919 5.83334 5.18378 5.91312 5.04704 6.0552C4.91029 6.19727 4.83341 6.39 4.83341 6.59092V13.4091C4.83341 13.61 4.91029 13.8028 5.04704 13.9448C5.18378 14.0869 5.36919 14.1667 5.56258 14.1667H15.7709C15.9643 14.1667 16.1497 14.0869 16.2865 13.9448C16.4232 13.8028 16.5001 13.61 16.5001 13.4091V6.59092C16.5001 6.39 16.4232 6.19727 16.2865 6.0552C16.1497 5.91312 15.9643 5.83334 15.7709 5.83334ZM11.3959 12.6515H9.93758V7.3485H11.3959V12.6515ZM18.0001 6.66668H20.6667V13.3333H18.0001C17.8233 13.3333 17.6537 13.2553 17.5287 13.1164C17.4037 12.9775 17.3334 12.7891 17.3334 12.5926V7.40742C17.3334 7.21096 17.4037 7.02255 17.5287 6.88363C17.6537 6.74472 17.8233 6.66668 18.0001 6.66668ZM3.33341 6.66668H0.666748V13.3333H3.33341C3.51023 13.3333 3.67979 13.2553 3.80482 13.1164C3.92984 12.9775 4.00008 12.7891 4.00008 12.5926V7.40742C4.00008 7.21096 3.92984 7.02255 3.80482 6.88363C3.67979 6.74472 3.51023 6.66668 3.33341 6.66668Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className={styles.progressBarHeaderText}>The ultimate peace of mind: 24/7 Location Tracking</div>
            <img className={styles.progressBarFiCollar} alt="Fi collar" src={fiCollarImage} />
            <a
              href={getShopURL('NanoSuccess', petInfo)}
              className={styles.progressBarCta}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackClickShopCollarCTA('nano-progress-bar-cta');
              }}
            >
              Start tracking {petInfo.name.length <= 10 ? petInfo.name : 'with Fi'}
            </a>
            <div className={styles.progressBarTestimonialContainer}>
              <div className={styles.review}>
                <div className={styles.reviewStars}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path
                      d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                      fill="black"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path
                      d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                      fill="black"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path
                      d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                      fill="black"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path
                      d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                      fill="black"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                    <path
                      d="M32.8963 13.1556L32.303 11.3756H21.0092L17.4696 0H15.5873L12.0682 11.3756H0.753978L0.160645 13.1556L9.28571 20.2552L5.93031 31.6718L7.48525 32.7357L16.5285 25.677L25.5717 32.7357L27.1267 31.6718L23.7712 20.2552L32.8963 13.1556Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className={styles.reviewCopy}>
                  <div className={styles.reviewTitle}>“Constantly monitored and&nbsp;safe!”</div>
                  <div className={styles.reviewSubtitle}>@shirley2792 | Fi Member</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={styles.gps}>
        <div className={styles.gpsLeft}>
          <div className={styles.gpsContent}>
            <div className={styles.gpsTitle}>
              {renderProgressBarExperiment ? 'Always-On GPS' : '24/7 Live GPS Tracking'}
            </div>
            <div className={styles.gpsSubtitle}>
              When you can see where they are, <em>they're never lost.</em>
            </div>
          </div>
          <a
            href={getShopURL('NanoSuccess', petInfo)}
            className={styles.gpsCta}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackClickShopCollarCTA('nano-live-gps-tracking');
            }}
          >
            Get the collar
          </a>
        </div>
        <div className={styles.gpsRight}>
          <img alt="dog on a rock wearing a blue Fi collar" src={gpsSectionImage} />
        </div>
      </div>
      <div className={styles.testimonalSection}>
        <div className={styles.testimonialContent}>
          <div className={styles.testimonialLeft}>
            <div className={styles.testimonialTitle}>Fi collars track while others can't</div>
            <div className={styles.testimonialSubtitle}>
              <p>
                Your microchip works when a friendly stranger finds your lost dog and brings them to a vet or shelter.
              </p>
              <p>
                With Fi, you <em>always have eyes on their location</em>, saving you the worry and waiting.
              </p>
            </div>
          </div>
          <div className={styles.testimonialVideo}>
            <video controls poster={videoPosterImage}>
              <source src="/testimonial.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className={styles.escapeSection}>
        <div className={styles.escapeSectionLeft}>
          <img alt="dog on a rock wearing a blue Fi collar" src={escapeAlertsSectionImage} />
        </div>
        <div className={styles.escapeSectionRight}>
          <div className={styles.escapeSectionContent}>
            <div className={styles.escapeTitle}>Escape Alerts</div>
            <div className={styles.escapeSubtitle}>
              <em>Know instantly</em> when they get out and where they are.
            </div>
          </div>
          <a
            href={getShopURL('NanoSuccess', petInfo)}
            className={styles.escapeCta}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackClickShopCollarCTA('nano-escape-alerts');
            }}
          >
            Get the collar
          </a>
        </div>
      </div>
    </div>
  );
}
