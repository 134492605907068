import { DocumentNode, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';
import React, { ReactNode, useState, useMemo, useEffect } from 'react';
import NotFound from './NotFound';
import AppBar from './AppBar';

export interface QueryBasedPageChildProps<TFinalData> {
  data: TFinalData;
  editing: boolean;
  reload(): Promise<void>;
  setEditing(value: boolean): void;
  setCanEdit(value: boolean): void;
}

export interface QueryBasedPageProps<TData, TVariables extends OperationVariables, TFinalData> {
  query: DocumentNode;
  options?: QueryHookOptions<TData, TVariables>;
  title: string;
  notFound?: JSX.Element;
  /**
   * If this returns null, it's interpreted as a 404.
   */
  transformData(input: TData): TFinalData | undefined | null;
  children(childProps: QueryBasedPageChildProps<TFinalData>): ReactNode;
  backLink?: string;
}

export default function QueryBasedPage<TData, TVariables extends OperationVariables, TFinalData>({
  query,
  title,
  notFound,
  transformData,
  children,
  options,
  backLink,
}: QueryBasedPageProps<TData, TVariables, TFinalData>) {
  const { loading, data, refetch } = useQuery<TData, TVariables>(query, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const [editing, setEditing] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const finalData = useMemo(() => (data ? transformData(data) : null), [data, transformData]);
  const reload = useMemo(
    () => async () => {
      await refetch();
    },
    [refetch],
  );

  // Scroll to top of page when user toggles between editing.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editing]);

  const [hideBackButton, setHideBackButton] = useState(true);

  // Need to hide back button when loading because depending on the result of the load,
  // the back button may be presented as an "X" or a normal back arrow.
  useEffect(() => {
    setHideBackButton(loading);
  }, [loading]);

  if (!data) {
    if (loading) {
      return null;
    } else {
      // Probably error
      return notFound ?? <NotFound />;
    }
  }

  if (!finalData) {
    return notFound ?? <NotFound />;
  }

  return (
    <div>
      <AppBar
        title={title}
        allowEdit={canEdit}
        editing={editing}
        onEditClicked={() => setEditing(!editing)}
        hideBackButton={hideBackButton}
        backLink={backLink}
      />
      {children({
        data: finalData!,
        editing,
        setCanEdit,
        setEditing,
        reload,
      })}
    </div>
  );
}
