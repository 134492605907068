import React from 'react';

import SelectField from '../../components/form/SelectField';
import usStates from '../../lib/usStates';

export interface StateSelectFieldProps {
  name: string;
}

export default function StateSelectField({ name }: StateSelectFieldProps) {
  return (
    <SelectField
      menuPlacement="top"
      autoComplete="address-level1"
      name={name}
      placeholder="State"
      options={usStates.map(({ name, abbreviation }) => ({
        label: name,
        value: abbreviation,
      }))}
    />
  );
}
