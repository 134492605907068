import React from 'react';
import { ChipResellerProps, ChipFullContactInfoProps, ChipSearchResult } from '@barkinglabs/nano-shared';
import Card from './Card';
import DataElements from './DataElements';
import List from '../../../components/utils/List';
import LastUpdated from './LastUpdated';
import ContactModal, { searchResultToContactModalProps } from './ContactModal';

function AddressElements({ contactInfo }: { contactInfo: ChipFullContactInfoProps }) {
  return (
    <DataElements
      elements={[
        {
          title: `Street Address`,
          content: contactInfo.line1,
        },
        {
          title: `City`,
          content: contactInfo.city,
        },
        {
          title: `State`,
          content: contactInfo.state,
        },
        {
          title: `Zip`,
          content: contactInfo.zipcode,
        },
      ]}
    />
  );
}

export interface VetCardProps {
  reseller: ChipResellerProps;
  searchResult: ChipSearchResult;
}

export default function VetCard({ searchResult, reseller }: VetCardProps) {
  const { contactInfo, updatedAt } = reseller;
  return (
    <Card>
      <List relaxed>
        {updatedAt && (
          <List.Item>
            <LastUpdated date={new Date(updatedAt)} />
          </List.Item>
        )}
        <List.Item>
          <DataElements
            elements={[
              {
                title: `Vet/Rescue/Breeder`,
                content: reseller.name,
              },
            ]}
            large
            action={
              <ContactModal
                {...searchResultToContactModalProps(searchResult)}
                contactTarget={{ kind: 'reseller' }}
                trigger={<div className="btn-black-outline">Contact Vet/Rescue/Breeder</div>}
              />
            }
          />
        </List.Item>
        <List.Item>
          <DataElements
            elements={[
              {
                title: `Email`,
                content: <a href={`mailto:${contactInfo.primaryEmail}`}>{contactInfo.primaryEmail}</a>,
              },
              {
                title: `Phone`,
                content: <a href={`tel:${contactInfo.primaryPhone}`}>{contactInfo.primaryPhone}</a>,
              },
            ]}
          />
        </List.Item>
        <List.Item>
          <AddressElements contactInfo={contactInfo} />
        </List.Item>
      </List>
    </Card>
  );
}
