import React from 'react';
import { Link } from 'react-router-dom';
import { Links } from '../../../lib/constants';

import styles from './FinalHero.module.scss';

export default function FinalHero() {
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <h5>BREEDERS, RESCUES, VET</h5>
        <h3>Request a Fi Nano sample today</h3>

        <Link to="/apply" className="btn-yellow-flat">
          REQUEST A SAMPLE
        </Link>
      </div>
      <div className={styles.right}>
        <h5>Dog Owners</h5>
        <h3>Tell your vet about Fi Nano</h3>

        <a href={Links.sendFiNanoToYourVet} className="btn-black-flat">
          SEND Fi NANO TO YOUR VET
        </a>
      </div>
    </div>
  );
}
