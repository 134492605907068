import * as qs from 'querystring';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect, useLocation } from 'react-router-dom';
import { chipDetailsQuery } from '../../appview/ChipDetails/ChipDetails.graphql';
import { isStrangerChipWithTransfer } from '../../appview/lib/types';
import { resellerChipDetailsQuery } from '../../appview/ResellerChipDetails/ResellerChipDetails.graphql';
import { Links } from '../../lib/constants';
import { useQueryParams } from '../../lib/useQueryParams';
import { userChipResellerQuery } from '../../lib/utils';
import { gqlTypes } from '../../types/gqlTypes';

interface RegisterQueryParams {
  id?: string;
  petId?: string;
  email?: string;
}

/**
 * This is a hook that determines whether the current state should take a user to the register/transfer chip steps
 * or if we should take them to the chip details page.  If we know the user is logged in as either a reseller or
 * pet owner, we can make that determination by checking to see if they own the chip or have a transfer in progress.
 * If the user is a stranger, we are unable to make that determination so we would want to initiate a registration.
 */
function useShouldInitiateTransfer(chipId?: string) {
  const { data: currentUserResellerData, loading: currentUserResellerLoading } = useQuery<gqlTypes.userChipReseller>(
    userChipResellerQuery,
    {
      skip: !chipId,
    },
  );

  const isReseller = !!currentUserResellerData?.currentUser.chipReseller;
  const { data: chipDetailsData } = useQuery<gqlTypes.chipDetails>(chipDetailsQuery, {
    variables: {
      id: chipId,
    },
    skip: !currentUserResellerData || isReseller,
  });

  const { data: resellerChipDetailsData } = useQuery<gqlTypes.resellerChipDetails>(resellerChipDetailsQuery, {
    variables: {
      id: chipId,
    },
    skip: !currentUserResellerData || !isReseller,
  });

  const notLoggedIn = !currentUserResellerLoading && !currentUserResellerData;
  const haveChipDetails = chipDetailsData || resellerChipDetailsData;
  const waitingForChipDetails = currentUserResellerData && !haveChipDetails;

  const shouldInitiateTransfer = useMemo(() => {
    if (notLoggedIn) {
      return true;
    }

    if (chipDetailsData) {
      const chipIsAssigned =
        chipDetailsData?.chip?.__typename === 'AssignedChip' && !!chipDetailsData?.chip?.latestContactHistoryEntry;

      const currentUserHasPendingTransfer =
        chipDetailsData?.chip?.__typename === 'StrangerChip' && isStrangerChipWithTransfer(chipDetailsData.chip);

      return !chipIsAssigned && !currentUserHasPendingTransfer;
    }

    if (resellerChipDetailsData) {
      return resellerChipDetailsData.resellerChip?.status === gqlTypes.ChipStatusType.SHIPPED;
    }

    return true;
  }, [notLoggedIn, chipDetailsData, resellerChipDetailsData]);

  return {
    shouldInitiateTransfer,
    isReseller,
    loading: currentUserResellerLoading || waitingForChipDetails,
    notLoggedIn,
  };
}

export default function Register() {
  const location = useLocation();
  const { id, petId, email } = useQueryParams<RegisterQueryParams>();
  const { shouldInitiateTransfer, isReseller, loading, notLoggedIn } = useShouldInitiateTransfer(id);

  if (id && loading) {
    return null;
  }

  if (!id || (shouldInitiateTransfer && !email)) {
    return <Redirect to={`/register/chip?${qs.stringify({ id, petId })}`} />;
  }

  if (notLoggedIn) {
    return <Redirect to={Links.login(`${location.pathname}${location.search}`)} />;
  } else {
    return <Redirect to={isReseller ? Links.vetChip(id) : Links.manageChipDetails({ id })} />;
  }
}
