import React from 'react';
import { fullName, singleLineAddress } from '../../../lib/utils';
import { gqlTypes } from '../../../../types/gqlTypes';
import AppDataElements from '../AppDataElements/AppDataElements';
import PhoneNumberLink from '../../../../components/utils/PhoneNumberLink';
import EmailLink from '../../../../components/utils/EmailLink';

import styles from './ViewContactInfo.module.scss';

export interface ViewContactInfoProps {
  contactInfo: gqlTypes.contactInfo;
}

export default function ViewContactInfo({ contactInfo }: ViewContactInfoProps) {
  const address = singleLineAddress(contactInfo);
  return (
    <div>
      <div className={styles.top}>
        <h3>Owner Info</h3>
        <div>
          <div>{fullName(contactInfo)}</div>
          {address && <div>{address}</div>}
        </div>
      </div>
      {contactInfo.primaryPhone && (
        <AppDataElements
          elements={[
            {
              title: `Phone Number 1`,
              content: <PhoneNumberLink number={contactInfo.primaryPhone} />,
            },
            ...(contactInfo.secondaryPhone
              ? [
                  {
                    title: `Phone Number 2`,
                    content: <PhoneNumberLink number={contactInfo.secondaryPhone} />,
                  },
                ]
              : []),
          ]}
        />
      )}
      <AppDataElements
        elements={[
          {
            title: `Email 1`,
            content: <EmailLink email={contactInfo.primaryEmail} />,
          },
        ]}
      />
      {contactInfo.secondaryEmail && (
        <AppDataElements
          elements={[
            {
              title: `Email 2`,
              content: <EmailLink email={contactInfo.secondaryEmail} />,
            },
          ]}
        />
      )}
    </div>
  );
}
