import { ChipSearchResultResponse } from '@barkinglabs/nano-shared';

export interface SearchState {
  isSearchState: true;
  response: ChipSearchResultResponse;
  query: string;
}

export function isSearchState(obj: any): obj is SearchState {
  return !!obj?.isSearchState;
}
