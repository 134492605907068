import { PureQueryOptions } from '@apollo/client';
import { ErrorMessage, Formik } from 'formik';
import React, { useRef } from 'react';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';
import * as yup from 'yup';
import SelectField from '../../components/form/SelectField';
import { gqlTypes } from '../../types/gqlTypes';
import ModalActions from '../components/modals/ModalActions/ModalActions';
import { useUpdateChipCondition } from '../lib/mutations';
import styles from './RemoveChipModal.module.scss';

interface RemoveChipModalProps {
  chipShortId: string;
  refetchQueries?: PureQueryOptions[];
}

interface RemoveChipFormValues {
  chipCondition: gqlTypes.ChipCondition | null;
}

const validationSchema = yup.object({
  chipCondition: yup.mixed<gqlTypes.ChipCondition>().required(`Please select a reason for removing this chip`),
});

export default function RemoveChipModal({ chipShortId, refetchQueries }: RemoveChipModalProps) {
  const [updateChipCondition] = useUpdateChipCondition();
  const popupRef = useRef<PopupActions>(null);
  const popupContent = (
    <div>
      <Formik<RemoveChipFormValues>
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          if (values.chipCondition) {
            try {
              popupRef.current?.close();
              await updateChipCondition({
                variables: {
                  chipId: chipShortId,
                  chipCondition: values.chipCondition,
                },
                refetchQueries,
              });
            } catch (err) {
              setStatus({ errorMessage: (err as any).message });
            } finally {
              setSubmitting(false);
            }
          }
        }}
        validationSchema={validationSchema}
        initialValues={{ chipCondition: null }}
      >
        {({ handleSubmit, status, isSubmitting, values }) => (
          <form className="form" autoComplete="on">
            <div className={styles.main}>
              <p>Please select a reason for removing this chip.</p>
              <div className="form-group">
                <div className="form-field">
                  <SelectField
                    name="chipCondition"
                    options={[
                      {
                        label: 'Microchip is damaged',
                        value: gqlTypes.ChipCondition.DAMAGED,
                      },
                      {
                        label: 'Pet has passed',
                        value: gqlTypes.ChipCondition.DECEASED_PET,
                      },
                      {
                        label: 'I never received this chip',
                        value: gqlTypes.ChipCondition.MISSING,
                      },
                    ]}
                  />
                </div>
              </div>
              <ErrorMessage name="chipCondition" component="div" className="form-field__error" />
              {values.chipCondition === gqlTypes.ChipCondition.MISSING && (
                <small>Email support@tryfi.com if you think there is an issue.</small>
              )}
            </div>

            <div className="form-group form-group--action">
              <div className="form-field">
                <ModalActions
                  actions={[
                    {
                      text: 'Remove chip',
                      primary: true,
                      disabled: isSubmitting,
                      onClick: () => {
                        handleSubmit();
                      },
                    },
                    {
                      text: 'Cancel',
                      disabled: isSubmitting,
                      onClick: () => {
                        popupRef.current?.close();
                      },
                    },
                  ]}
                />
              </div>
              {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );

  return (
    <Popup trigger={<button className={styles.removeChipButton}>Remove chip</button>} ref={popupRef} modal>
      {popupContent}
    </Popup>
  );
}
