import {
  ChipSendMessagePayload,
  ChipSearchResultResponse,
  ChipRequestInfoUpdatePayload,
} from '@barkinglabs/nano-shared';
import { apiHost } from './constants';
import axios from 'axios';
import { gqlTypes } from '../types/gqlTypes';

export async function search(query: string, token: string) {
  const url = `${apiHost}/api/chips/search`;
  const response = await axios.get<ChipSearchResultResponse>(url, {
    params: {
      query,
      token,
    },
  });
  return response.data;
}

export async function sendMessage(payload: ChipSendMessagePayload) {
  const url = `${apiHost}/api/chips/sendmessage`;
  await axios.post(url, payload);
}

export async function requestInfoUpdate(payload: ChipRequestInfoUpdatePayload) {
  const url = `${apiHost}/api/chips/requestinfoupdate`;
  await axios.post(url, payload);
}

export async function validateAddress(addressPayload: gqlTypes.AddressInput) {
  const url = `${apiHost}/api/chips/validate-address`;
  const response = await axios.post(url, addressPayload);
  return response.data;
}

export class EstablishmentAlreadyExistsError extends Error {}

export async function checkEstablishmentRegistration(establishmentName: string, establishmentCity: string) {
  const url = `${apiHost}/api/chips/check-establishment-registration`;
  try {
    return await axios.get(url, {
      params: {
        establishmentName,
        establishmentCity,
      },
    });
  } catch (err) {
    if (err.response?.data?.includes('already registered')) {
      throw new EstablishmentAlreadyExistsError();
    }
  }
}

interface ChipDetails {
  serialNumber: string;
  shortId: string;
  isAssigned: boolean;
}

export async function getChipDetails(chipId: string): Promise<ChipDetails> {
  const url = `${apiHost}/api/chips/fichipstatus`;
  const response = await axios.get<ChipDetails>(url, { params: { chipId } });
  return response.data;
}
