import React from 'react';
import SearchHero from './sections/SearchHero';
import ApplyHero from './sections/ApplyHero';
import UpsellHero from './sections/UpsellHero';
import Comparison from './sections/Comparison';
import ApplyHeroB from './sections/ApplyHeroB';
import ComparisonB from './sections/ComparisonB';
import Footer from '../../components/pagelayout/Footer/Footer';
import FinalHero from './sections/FinalHero';

export default function Home() {
  return (
    <>
      <div>
        <SearchHero />
        <ApplyHero />
        <UpsellHero />
        <Comparison />
        <ApplyHeroB />
        <ComparisonB />
        <FinalHero />
      </div>
      <Footer />
    </>
  );
}
