import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { phoneRegex, saneEmail } from '../../../../lib/utils';
import { Formik, ErrorMessage, useFormikContext } from 'formik';
import SingleTextFieldFormGroup from '../../../../components/form/SingleTextFieldFormGroup';
import StateSelectField from '../../../../components/form/StateSelectField';
import OutlineButton from '../../OutlineButton';
import { gqlTypes } from '../../../../types/gqlTypes';
import FormActions, { FormAction } from '../../FormActions';

import styles from './ContactInfoForm.module.scss';

export interface ContactInfoFormValues {
  firstName: string;
  lastName: string | undefined;
  primaryEmail: string;
  secondaryEmail: string | undefined;
  primaryPhone: string | undefined;
  secondaryPhone: string | undefined;
  line1: string | undefined;
  line2: string | undefined;
  city: string | undefined;
  zipcode: string | undefined;
  state: string | undefined;
}

export function formValuesFromGql(gql: gqlTypes.contactInfo) {
  const values: Partial<ContactInfoFormValues> = {
    // The only required fields.
    firstName: gql.firstName.trim(),
    primaryEmail: gql.primaryEmail,
  };
  if (gql.primaryPhone) {
    values.primaryPhone = gql.primaryPhone;
  }
  if (gql.lastName) {
    values.lastName = gql.lastName.trim();
  }
  if (gql.secondaryEmail) {
    values.secondaryEmail = gql.secondaryEmail;
  }
  if (gql.secondaryPhone) {
    values.secondaryPhone = gql.secondaryPhone;
  }
  if (gql.line1) {
    values.line1 = gql.line1;
  }
  if (gql.line2) {
    values.line2 = gql.line2;
  }
  if (gql.city) {
    values.city = gql.city;
  }
  if (gql.zipcode) {
    values.zipcode = gql.zipcode;
  }
  if (gql.state) {
    values.state = gql.state;
  }
  return values;
}

const formValuesToGql = (values: ContactInfoFormValues): gqlTypes.ChipContactInfoInput => ({
  ...values,
  country: `US`,
});

const initialFormValues: ContactInfoFormValues = {
  firstName: '',
  lastName: '',
  primaryEmail: '',
  secondaryEmail: '',
  primaryPhone: '',
  secondaryPhone: '',
  line1: '',
  line2: '',
  city: '',
  zipcode: '',
  state: '',
};

const validationSchema = yup.object({
  firstName: yup.string().required(`Please enter a first name`),
  lastName: yup.string(),
  primaryEmail: yup
    .string()
    .email(`Please enter a valid primary email address`)
    .test('sane_email', `Please enter a valid primary email address`, saneEmail)
    .required(`Please enter a primary email address`),
  secondaryEmail: yup
    .string()
    .email(`Please enter a valid secondary email address`)
    .test('sane_email', `Please enter a valid primary email address`, saneEmail),
  primaryPhone: yup.string().matches(phoneRegex, `Please enter a valid primary phone number`),
  secondaryPhone: yup.string().matches(phoneRegex, `Please enter a valid secondary phone number`),
  line1: yup.string(),
  line2: yup.string(),
  city: yup.string(),
  zipcode: yup.string(),
  state: yup.string(),
  actionTag: yup.string().required(),
});

export interface ContactInfoFormProps<TActionTag> {
  initialValues?: Partial<ContactInfoFormValues>;
  actions: FormAction<TActionTag>[];
  doSubmit(contactInfoInput: gqlTypes.ChipContactInfoInput, actionTag: TActionTag): Promise<void>;
  showCancel?: boolean;
  onCancel?(): void;
  autoComplete?: boolean;
  forceDisabled?: boolean;
}

function TouchInitialValuedFields({ initialValues }: { initialValues: Partial<ContactInfoFormValues> }) {
  const [didTouch, setDidTouch] = useState(false);
  const { setFieldTouched } = useFormikContext();
  useEffect(() => {
    if (!didTouch) {
      const toTouch = Object.entries(initialValues)
        .filter(([_k, v]) => !!v)
        .map(([k]) => k);
      toTouch.forEach((name) => setFieldTouched(name, true));
      setDidTouch(true);
    }
  }, [initialValues, didTouch, setFieldTouched]);
  return <React.Fragment />;
}

export default function ContactInfoForm<TActionTag>({
  initialValues: initialValuesFromProps,
  actions,
  doSubmit,
  showCancel,
  onCancel,
  autoComplete,
  forceDisabled,
}: ContactInfoFormProps<TActionTag>) {
  const initialValues: ContactInfoFormValues = {
    ...initialFormValues,
    ...initialValuesFromProps,
  };
  const haveInitialValuesFromProps = Object.values(initialValuesFromProps ?? {}).filter((v) => v).length > 0;
  const initialValuesWithActionTag = {
    ...initialValues,
    actionTag: actions[0].tag,
  };

  const maybeAutoComplete = (name: string) => (autoComplete ? { autoComplete: name } : {});

  return (
    <div>
      <Formik
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            const { actionTag, ...formValues } = values;
            await doSubmit(formValuesToGql(formValues), actionTag);
          } catch (err) {
            setStatus({ errorMessage: (err as any).message });
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValuesWithActionTag}
      >
        {({ handleSubmit, status, isSubmitting, isValid, dirty, submitForm, setFieldValue }) => {
          const actionsDisabled = isSubmitting || (!dirty && !haveInitialValuesFromProps) || !isValid || forceDisabled;
          return (
            <form onSubmit={handleSubmit} className="form form__app" autoComplete={autoComplete ? 'on' : undefined}>
              {initialValuesFromProps && <TouchInitialValuedFields initialValues={initialValuesFromProps} />}
              <SingleTextFieldFormGroup
                name="firstName"
                placeholder="First name"
                {...maybeAutoComplete('given-name')}
              />
              <SingleTextFieldFormGroup name="lastName" placeholder="Last name" {...maybeAutoComplete('family-name')} />
              <SingleTextFieldFormGroup
                name="primaryEmail"
                type="email"
                placeholder="Primary email address"
                {...maybeAutoComplete('email')}
              />
              <SingleTextFieldFormGroup
                name="secondaryEmail"
                type="email"
                placeholder="Secondary email address"
                autoComplete="off"
              />
              <SingleTextFieldFormGroup
                name="primaryPhone"
                type="tel"
                placeholder="Primary phone number"
                {...maybeAutoComplete('tel')}
              />
              <SingleTextFieldFormGroup
                name="secondaryPhone"
                type="tel"
                placeholder="Secondary phone number"
                autoComplete="off"
              />
              <SingleTextFieldFormGroup name="line1" placeholder="Address" {...maybeAutoComplete('street-address')} />
              <SingleTextFieldFormGroup
                name="line2"
                placeholder="Apt/suite/other (optional)"
                {...maybeAutoComplete('address-line-2')}
              />
              <SingleTextFieldFormGroup name="city" placeholder="City" {...maybeAutoComplete('address-level2')} />
              <div className="form-group">
                <div className="form-field">
                  <StateSelectField name="state" />
                  <ErrorMessage name={'state'} component="div" className="form-field__error" />
                </div>
              </div>
              <SingleTextFieldFormGroup name="zipcode" placeholder="Zip" {...maybeAutoComplete('postal-code')} />
              <div className={styles.actions}>
                {showCancel && (
                  <OutlineButton
                    secondary={true}
                    onClick={() => {
                      if (onCancel) {
                        onCancel();
                      }
                    }}
                  >
                    Cancel
                  </OutlineButton>
                )}
                <FormActions
                  actions={actions}
                  disabled={actionsDisabled}
                  onClick={(actionTag) => {
                    setFieldValue('actionTag', actionTag);
                    submitForm();
                  }}
                />
                {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
