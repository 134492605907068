import { ReactNode, useMemo, useRef, useState } from 'react';
import { gqlTypes } from '../../../types/gqlTypes';

export type ImageAction =
  | {
      kind: 'replace';
      file: File;
    }
  | {
      kind: 'remove';
      photoId: string;
    }
  | {
      kind: 'preserve';
      photo: gqlTypes.photo;
    };

export interface ImageUploadProps {
  initialValue: ImageAction | undefined;
  onChange(newAction: ImageAction | undefined): void;
  onError?: (message: string) => void;
  children(args: {
    src: string | null;
    openUploadDialog(): void;
    error: string | null;
    removePhoto(): void;
  }): ReactNode;
}

const MAX_SIZE = 20000000; // See MAX_IMAGE_SIZE in web

export default function ImageUpload({ initialValue, onChange, children, onError }: ImageUploadProps) {
  const initialPhoto = useMemo(
    () => initialValue && initialValue.kind === 'preserve' && initialValue.photo,
    [initialValue],
  );
  const [src, setSrc] = useState<string | null>((initialPhoto && initialPhoto.image.fullSize) || null);
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          const file = e.currentTarget.files?.[0];
          if (file) {
            if (file.size > MAX_SIZE) {
              onChange(undefined);
              setSrc(null);
              const errorMessage = `That image is too large. Please resize it and try again.`;
              setError(errorMessage);
              if (onError) {
                onError(errorMessage);
              }
            } else {
              setError(null);
              setSrc(URL.createObjectURL(file));
              onChange({
                kind: 'replace',
                file,
              });
            }
          }
        }}
      />
      {children({
        src,
        error,
        openUploadDialog() {
          if (inputRef.current) {
            inputRef.current.click();
          }
        },
        removePhoto() {
          setSrc(null);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
          if (initialPhoto) {
            onChange({ kind: 'remove', photoId: initialPhoto.id });
          } else {
            onChange(undefined);
          }
        },
      })}
    </>
  );
}
