import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cache from '../apollo/cache';
import Cookies from 'js-cookie';

export interface Session {
  userId: string;
  email: string;
  impersonating?: boolean;
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: null as Session | null,
  reducers: {
    create(_state: Session | null, { payload }: PayloadAction<{ session: Session }>) {
      return payload.session;
    },

    destroy(_state: Session | null, _action: PayloadAction<{}>) {
      Cookies.remove('fi_session_id');
      cache.reset();
      return null;
    },
  },
});

export const { actions: sessionActions, reducer: sessionReducer } = sessionSlice;
