import React from 'react';

import upsellImage from '../../../assets/images/upsell-image.jpg';
import upsellImageMobile from '../../../assets/images/upsell-image-mobile.jpg';

import styles from './UpsellHero.module.scss';

export default function UpsellHero() {
  return (
    <div className={styles.main}>
      <div className={styles.left}>
        <div className={styles.theProblem}>
          <h5>The Problem</h5>
          <h3>Existing microchips are expensive and unreliable.</h3>
          <ul>
            <li>
              <p>
                <b>Only 5% of dogs</b> in the US are microchipped.
              </p>
            </li>
            <li>
              <p>
                <b>52% of implanted microchips are never registered</b> to their owners due to hidden registration fees
                and obsolete technology platforms.
              </p>
            </li>
          </ul>
        </div>

        <div>
          <h5>The Solution</h5>
          <h3>Fi Nano.</h3>
          <ul>
            <li>
              <p>
                <b>ISO certified, internationally compatible with all scanners</b>, 1.4mm microchip for a fraction of
                the cost.
              </p>
            </li>
            <li>
              <p>
                <b>Free to register and update for life</b> with owners information from any smartphone or computer.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.upsellImage}>
        <picture>
          <source srcSet={upsellImageMobile} />
          <img alt="A Fi dog" src={upsellImage} />
        </picture>
      </div>
    </div>
  );
}
