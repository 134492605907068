import { gql, useMutation } from '@apollo/client';
import { gqlTypes } from '../../types/gqlTypes';
import { renderFragments, contactInfoFragment, photoFragment } from '../../lib/fragments';

export const updateChipContactInfoMutation = gql`
  mutation updateChipContactInfo($input: UpdateChipContactInfoInput!) {
    updateChipContactInfo(input: $input) {
      petOwnershipWasTransferred
      contactInfoResponse {
        __typename
        id
        contactInfo {
          ...contactInfo
        }
      }
    }
  }

  ${renderFragments(contactInfoFragment)}
`;

export const useUpdateChipContactInfo = () =>
  useMutation<gqlTypes.updateChipContactInfo, gqlTypes.updateChipContactInfoVariables>(updateChipContactInfoMutation);

export const createPetMutation = gql`
  mutation createPet($input: CreatePetInput!) {
    createPet(input: $input) {
      id
    }
  }
`;

export const useCreatePet = () => useMutation<gqlTypes.createPet, gqlTypes.createPetVariables>(createPetMutation);

const setPetPrimaryPhotoMutation = gql`
  mutation setPetPrimaryPhoto($input: SetPetPrimaryPhotoInput!) {
    setPetPrimaryPhoto(input: $input) {
      id
      photos {
        first {
          ...photo
        }
      }
    }
  }

  ${renderFragments(photoFragment)}
`;

export const useSetPetPrimaryPhoto = () =>
  useMutation<gqlTypes.setPetPrimaryPhoto, gqlTypes.setPetPrimaryPhotoVariables>(setPetPrimaryPhotoMutation);

const removePhotoMutation = gql`
  mutation removePhoto($photoId: ID!) {
    removePhoto(photoId: $photoId)
  }
`;

export const useRemovePhoto = () =>
  useMutation<gqlTypes.removePhoto, gqlTypes.removePhotoVariables>(removePhotoMutation);

export const resolveChipTransferMutation = gql`
  mutation resolveChipTransfer($input: ResolveChipTransferInput!) {
    resolveChipTransfer(input: $input) {
      id
    }
  }
`;

const updatePetMutation = gql`
  mutation updatePet($input: UpdatePetInput!) {
    updatePet(input: $input) {
      id
    }
  }
`;

export const useUpdatePet = () => useMutation<gqlTypes.updatePet, gqlTypes.updatePetVariables>(updatePetMutation);

export const useResolveChipTransfer = () =>
  useMutation<gqlTypes.resolveChipTransfer, gqlTypes.resolveChipTransferVariables>(resolveChipTransferMutation);

export const associateChipWithPetMutation = gql`
  mutation associateChipWithPet($input: AssociateChipWithPetInput!) {
    associateChipWithPet(input: $input)
  }
`;

export const useAssociateChipWithPet = () =>
  useMutation<gqlTypes.associateChipWithPet, gqlTypes.associateChipWithPetVariables>(associateChipWithPetMutation);

const updateChipCondition = gql`
  mutation updateChipCondition($chipId: ID!, $chipCondition: ChipCondition!) {
    updateChipCondition(input: { chipId: $chipId, chipCondition: $chipCondition }) {
      id
      shortId
      serialNumber
    }
  }
`;

export const useUpdateChipCondition = () =>
  useMutation<gqlTypes.updateChipCondition, gqlTypes.updateChipConditionVariables>(updateChipCondition);
