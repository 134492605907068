import React from 'react';
import classNames from 'classnames';
import styles from './Form.module.scss';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  children?: React.ReactNode;
  /**
   * Compact
   */
  compact?: boolean;
  /**
   * Next
   */
  next?: boolean;
}

export default function Form({ compact = false, children, next = false, ...passthroughProps }: FormProps) {
  return (
    <form
      className={classNames('form', {
        [styles.compact]: compact,
        [styles.next]: next,
      })}
      {...passthroughProps}
    >
      {children}
    </form>
  );
}
