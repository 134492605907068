import React from 'react';
import { StrangerChipWithTransfer } from '../lib/types';
import { usePet } from './usePet';
import PetDetails from '../components/ChipDetails/PetDetails/PetDetails';
import ActionsContainer from '../components/ActionsContainer/ActionsContainer';
import OutlineButton from '../components/OutlineButton';

export interface PendingTransferViewProps {
  chip: StrangerChipWithTransfer;
  petId: string;
  reload(): void;
}

export default function PendingTransferView({ chip, petId, reload }: PendingTransferViewProps) {
  const { pet } = usePet(petId);
  return (
    <div>
      {pet && (
        <PetDetails
          chip={chip}
          pet={pet}
          status="pending"
          onPetEdited={() => {
            reload();
          }}
        />
      )}
      <ActionsContainer>
        <OutlineButton disabled>Transfer Requested</OutlineButton>
      </ActionsContainer>
    </div>
  );
}
