import { DateTime, Duration } from 'luxon';
import { kgToLbs } from './utils';

interface CommonPet {
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
}

export const dateOfBirth = (pet: CommonPet) =>
  DateTime.fromObject({
    year: pet.yearOfBirth,
    month: pet.monthOfBirth ?? undefined,
    day: pet.dayOfBirth ?? undefined,
  });

export function dayOfBirthString(pet: CommonPet) {
  let format: string;
  if (pet.dayOfBirth && pet.monthOfBirth) {
    format = `LLLL d, yyyy`;
  } else if (pet.monthOfBirth) {
    format = `LLLL, yyyy`;
  } else {
    // Only have year.
    format = `yyyy`;
  }

  return dateOfBirth(pet).toFormat(format);
}

export function ageString(pet: CommonPet) {
  const date = dateOfBirth(pet);
  const duration = DateTime.utc().diff(date);
  if (duration < Duration.fromObject({ years: 1 })) {
    const numMonths = Math.round(duration.as('months'));
    return numMonths < 1 ? `<1 month` : `${numMonths} month${numMonths > 1 ? 's' : ''}`;
  } else {
    const numYears = Math.floor(duration.as('years'));
    return `${numYears} yr${numYears > 1 ? 's' : ''}`;
  }
}

export function ageInMonths(year: number, month: number, day: number): number {
  return (
    (new Date().getFullYear() - year) * 12 + new Date().getMonth() - month + (new Date().getDate() >= day ? 0 : -1)
  );
}

export function roundedWeightInLbs(kg: number) {
  const lbs = kgToLbs(kg);
  const roundLbs = Math.max(1, Math.round(lbs));
  return roundLbs;
}

export function weightString(pet: CommonPet) {
  const roundLbs = roundedWeightInLbs(pet.weight);
  return `${roundLbs} lbs`;
}
