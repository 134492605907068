import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './ChipSearchBox.module.scss';

export default function ChipSearchBox() {
  const [text, setText] = useState('');
  const history = useHistory();

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (text) {
        history.push(`/verifysearch?q=${encodeURIComponent(text)}`);
      }
    },
    [text, history],
  );

  return (
    <div className={styles.main}>
      <form className={styles.form} onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Look up microchip scan codes"
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
        />
        <button type="submit" className={styles.searchButton}>
          Search
        </button>
      </form>
      <div className="disclaimer font-md">
        *If you find a dog with a Fi Nano chip, look up the microchip scan code to find its owner’s contact info.
      </div>
    </div>
  );
}
