import { Formik } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import Button from '../../../components/Button/Button';
import Form from '../../../components/form/Form';
import FormField from '../../../components/form/FormField';
import { roundedWeightInLbs } from '../../../lib/petUtils';
import { lbsToKg } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import BreedSelect from './BreedSelect';
import GenderSelect from './GenderSelect';
import { getFiGrowthBook } from '../../lib/growthbook';

export interface PetInfo {
  name?: string | null;
  gender?: gqlTypes.Gender | null;
  species: gqlTypes.Species;
  breedId?: string | null;
  isMixedBreed?: boolean;
  birthDate?: string | null;
  weight?: string | null;
  additionalInfo?: string | null;
  householdId?: string | null;
}

const NUMBER = /^\d*$/;

export const petFormValuesFromPet = (pet: gqlTypes.basePet): PetInfo => ({
  gender: pet.gender,
  name: pet.name,
  weight: roundedWeightInLbs(pet.weight).toString(),
  birthDate: DateTime.fromObject({
    year: pet.yearOfBirth,
    month: pet.monthOfBirth ?? 1,
    day: pet.dayOfBirth ?? 1,
  }).toISODate(),
  breedId: pet.breed?.id ?? '',
  species: pet.species || gqlTypes.Species.DOG,
  isMixedBreed: !pet.isPurebred,
});

export interface CreateOrUpdatePetInput {
  name: string;
  gender: gqlTypes.Gender;
  species: gqlTypes.Species;
  breedId?: string | null;
  isPurebred: boolean;
  yearOfBirth: number;
  monthOfBirth?: number | null;
  dayOfBirth?: number | null;
  weight: number;
  additionalInfo?: string | null;
  householdId?: string | null;
}

interface PetInfoFormProps {
  initialValues?: PetInfo;
  onSubmit?: (values: CreateOrUpdatePetInput) => void;
  responseError?: string;
  disabled?: boolean;
  submitLabel?: string;
}

export default function PetInfoForm({
  initialValues,
  responseError,
  onSubmit,
  disabled,
  submitLabel = 'Continue',
}: PetInfoFormProps) {
  const requireBreedField = getFiGrowthBook().getFeatureValue('nano-registration-size-suggestion-experiment', false);
  const validationSchema = yup.object({
    name: yup.string().nullable().required(`Please enter the pet's name`),
    gender: yup.mixed<gqlTypes.Gender>().required(`Please select a gender`),
    weight: yup
      .string()
      .nullable()
      .required(`Please enter a weight`)
      .matches(NUMBER, `Please enter a number for the weight`),
    birthDate: yup
      .string()
      .nullable()
      .required(`Please enter a birth date`)
      .test('valid_date', `Please provide a valid birth date`, function (value) {
        try {
          if (value) {
            const date = DateTime.fromISO(value);
            return date <= DateTime.utc();
          }
        } catch (_err) {}

        return false;
      }),
    species: yup.mixed<gqlTypes.Species>().nullable(),
    breedId: yup.string().when('species', {
      is: gqlTypes.Species.DOG,
      then: yup.string().when('requireBreedField', {
        is: () => requireBreedField,
        then: yup.string().required('Please select a breed'),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    }),
  });

  return (
    <Formik<PetInfo>
      initialValues={
        initialValues ?? {
          name: '',
          gender: null,
          species: gqlTypes.Species.DOG,
          breedId: null,
          birthDate: '',
          weight: '',
        }
      }
      onSubmit={(values: PetInfo) => {
        if (!onSubmit) {
          return;
        }

        let birthDate: DateTime | undefined;
        try {
          if (values.birthDate) {
            birthDate = DateTime.fromISO(values.birthDate);
          }
        } catch (err) {}

        if (!values.name || !values.gender || !values.weight || !birthDate) {
          return;
        }

        onSubmit({
          name: values.name.trim(),
          gender: values.gender,
          species: values.species || gqlTypes.Species.DOG,
          breedId: values.breedId,
          isPurebred: !values.isMixedBreed,
          yearOfBirth: birthDate.year,
          monthOfBirth: birthDate.month,
          dayOfBirth: birthDate.day,
          additionalInfo: values.additionalInfo,
          weight: lbsToKg(Number(values.weight)),
        });
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, submitForm, values }) => {
        return (
          <Form next onSubmit={handleSubmit}>
            <div className="form-section">
              <div className="form-group">
                <FormField type="text" name="name" placeholder="Pet's name" />
              </div>
              <BreedSelect name="breedId" isMixedBreed={!!values.isMixedBreed} />
              <GenderSelect name="gender" />
              <div className="form-group">
                <FormField
                  type="date"
                  name="birthDate"
                  placeholder="Birthday"
                  classNames={!values.birthDate ? 'date-input--empty' : undefined}
                  max={DateTime.utc().toISODate()}
                />
              </div>
              <div className="form-group">
                <FormField type="number" inputMode="numeric" name="weight" placeholder="Weight in lbs" />
              </div>
              <div className="form-group additional-info-container">
                <FormField
                  type="text"
                  component="textarea"
                  name="additionalInfo"
                  maxLength="500"
                  placeholder="Leave a message for someone that finds your pet (optional)"
                />
              </div>
              <div className="form-group form-group--action form-group--multistep-action">
                {onSubmit && (
                  <Button
                    primary
                    label={submitLabel}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                    disabled={disabled}
                  />
                )}
              </div>
              {responseError && <div className="form-field__error">{responseError}</div>}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
