import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * Is this button disabled?
   */
  disabled?: boolean;
  /**
   * Button type: button, submit, or reset
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button contents
   */
  label: string;
  /**
   * Icon src URL
   */
  iconSrc?: string;
  /**
   * Optional click handler
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function Button({
  primary = false,
  disabled = false,
  type = 'button',
  label,
  iconSrc,
  onClick,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={classNames('button-main', styles.main, {
        [styles.primary]: primary,
        'button-main--primary': primary,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
      {iconSrc && <img src={iconSrc} className={styles.icon} alt="" />}
    </button>
  );
}
