import { useState } from 'react';
import { useQueryParams } from '../../lib/useQueryParams';

export interface ChipDetails {
  shortId: string;
  serialNumber: string;
  isAssigned: boolean;
}

export default interface RegisterStateV2 {
  petId?: string;
  chipId?: string;
  chipDetails?: ChipDetails;
  primaryEmail?: string;
  userIsApprovedReseller?: boolean;
  visitedPaths?: Set<string>;
  logVisit?: (path: string) => void;
  isShelterluvUser?: boolean;
}

interface RegisterQueryParams {
  id?: string;
  petId?: string;
  email?: string;
}

export function useRegisterStateV2() {
  const {
    id: queryParamChipId,
    petId: queryParamPetId,
    email: queryParamPrimaryEmail,
  } = useQueryParams<RegisterQueryParams>();

  const [chipId, setChipId] = useState<string | undefined>(queryParamChipId);
  const [petId, setPetId] = useState<string | undefined>(queryParamPetId);
  const [primaryEmail, setPrimaryEmail] = useState<string | undefined>(queryParamPrimaryEmail);
  const [isShelterluvUser] = useState<boolean>(queryParamPrimaryEmail !== undefined); // Only Shelterluv users pass in a primary email via query param
  const [chipDetails, setChipDetails] = useState<ChipDetails | undefined>();
  const [visitedPaths, setVisitedPaths] = useState<Set<string>>(new Set());
  const logVisit = (path: string) => {
    setVisitedPaths((prevPaths) => new Set(prevPaths).add(path));
  };

  return {
    registerState: {
      petId,
      chipId,
      chipDetails,
      primaryEmail,

      isShelterluvUser,
    },
    setChipDetails,
    setChipId,
    setPetId,
    visitedPaths,
    logVisit,
    setPrimaryEmail,
  };
}
