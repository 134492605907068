import React from 'react';
import { Link } from 'react-router-dom';
import List from '../../components/utils/List';

interface NoSearchResultsProps {
  query: string;
}

export default function NoSearchResults({ query }: NoSearchResultsProps) {
  return (
    <div>
      <h4>Sorry, no results found for '{query}'</h4>
      <p>Double check the spelling of the chip ID number and try again.</p>
      <div>
        <List horizontal relaxed>
          <List.Item>
            <Link to="/" className="btn-yellow">
              Back to Nano homepage
            </Link>
          </List.Item>
          <List.Item>
            <a className="btn-outline" href="https://support.tryfi.com" target="_blank" rel="noopener noreferrer">
              Contact support
            </a>
          </List.Item>
        </List>
      </div>
    </div>
  );
}
