import ApplicationFormV2 from './ApplicationFormV2';
import styles from './ApplyV2.module.scss';

export default function ApplyV2() {
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <ApplicationFormV2 />
      </div>
    </div>
  );
}
