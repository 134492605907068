import { gqlTypes } from '../../types/gqlTypes';

interface FirstLastName {
  firstName: string;
  lastName?: string | null;
}

export function fullName({ firstName, lastName }: FirstLastName) {
  return `${firstName}${lastName ? ' ' + lastName : ''}`;
}

export function firstLastInitial({ firstName, lastName }: FirstLastName) {
  let result = firstName;
  if (lastName) {
    result += ` ${lastName.charAt(0)}.`;
  }
  return result;
}

export function singleLineAddress(contactInfo: gqlTypes.contactInfo) {
  return (
    [
      [[contactInfo.line1, contactInfo.line2].filter((x) => x).join(' '), contactInfo.city, contactInfo.state]
        .filter((x) => x)
        .join(', '),
      contactInfo.zipcode,
    ]
      .filter((x) => x)
      .join(' ') || null
  );
}
