import React, { useState, useCallback, BaseSyntheticEvent, useRef } from 'react';

import styles from './JoinEmailForm.module.scss';
import { trackNewsletterSignup } from '../../../lib/analytics/events';

function identifyEmailSignup(email: string, source: string) {
  if (typeof window !== 'undefined') {
    const payload = {
      id: email.toLowerCase(),
      email: email,
      source: source,
      created_at: Math.round(new Date().getTime() / 1000),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    (window as any)._cio.identify(payload);
    trackNewsletterSignup(email, source);
  }
}

export default function JoinEmailForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(
    (e: BaseSyntheticEvent) => {
      e.preventDefault();

      if (!formRef.current || !inputRef.current) {
        return false;
      }

      const validForm = formRef.current.reportValidity();
      const email = inputRef.current.value;

      if (validForm && email.length > 0) {
        setSubmitted(true);
        identifyEmailSignup(email, 'fi-nano');
      }

      return false;
    },
    [formRef, inputRef],
  );

  return (
    <div className={styles.main}>
      <form onSubmit={handleSubmit} ref={formRef}>
        <input type="text" placeholder="Enter email to stay up to date" required={true} ref={inputRef} />
        <input type="submit" value="&rarr;" onClick={handleSubmit} />
      </form>
      {submitted && <div className={styles.thankYou}>Thank you for joining our list!</div>}
    </div>
  );
}
