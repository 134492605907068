import React from 'react';
import { AssignedChip } from '../lib/types';
import PetDetails from '../components/ChipDetails/PetDetails/PetDetails';
import ViewContactInfo from '../components/ChipDetails/ViewContactInfo/ViewContactInfo';

export interface AssignedChipViewProps {
  chip: AssignedChip;
  reload(): void;
}

export default function AssignedChipView({ chip, reload }: AssignedChipViewProps) {
  const { pet, latestContactHistoryEntry } = chip;

  return (
    <div>
      {pet && (
        <PetDetails
          chip={chip}
          pet={pet}
          onPetEdited={() => {
            reload();
          }}
        />
      )}
      {latestContactHistoryEntry && <ViewContactInfo contactInfo={latestContactHistoryEntry.contactInfo} />}
    </div>
  );
}
