import { dayOfBirthString, ageString, weightString } from '../../../../lib/petUtils';
import AppDataElements from '../AppDataElements/AppDataElements';
import { CommonChip } from '../../../lib/types';
import { gqlTypes } from '../../../../types/gqlTypes';
import PetProfile from '../../PetProfile/PetProfile';

import styles from './PetDetails.module.scss';

export interface PetDetailsProps {
  chip: CommonChip;
  pet: gqlTypes.basePet;
  status?: 'pending' | 'active' | 'inactive';
  onPetEdited?(): void;
  contactInfoSource?: gqlTypes.ChipContactHistoryEntrySource | null;
}

export default function PetDetails({ chip, pet, status, onPetEdited, contactInfoSource }: PetDetailsProps) {
  return (
    <div className={styles.main}>
      <PetProfile
        pet={pet}
        status={status}
        className={styles.petProfile}
        enablePhotoEditing={!!onPetEdited}
        onPetEdited={onPetEdited}
        contactInfoSource={contactInfoSource}
      />
      <AppDataElements
        elements={[
          {
            title: `Date of Birth`,
            content: dayOfBirthString(pet),
          },
          {
            title: `Age`,
            content: ageString(pet),
          },
          {
            title: `Weight`,
            content: weightString(pet),
          },
        ]}
      />
      <AppDataElements
        elements={[
          {
            title: `Fi Nano ID`,
            content: chip.shortId,
          },
          {
            title: `Chip ID`,
            content: chip.serialNumber,
          },
        ]}
      />
    </div>
  );
}
