import React, { useEffect } from 'react';
import { useCreatePet } from '../../lib/mutations';
import { manageHomeQuery } from '../../ManageHome/ManageHome.graphql';
import PetInfoForm, { CreateOrUpdatePetInput } from '../../RegisterChip/PetInfoStep/PetInfoForm';

interface CreatePetProps {
  responseError?: string;
  onSuccess: (petId: string) => void;
}

export default function CreatePet({ responseError, onSuccess }: CreatePetProps) {
  const [createPet, { data: createPetData, loading: createPetLoading, error: createPetError }] = useCreatePet();

  // After createPet mutation completes
  useEffect(() => {
    if (!createPetData) {
      return;
    }

    const petId = createPetData.createPet.id;
    onSuccess(petId);
  }, [createPetData, onSuccess]);

  return (
    <>
      <h4>Pet Information</h4>
      <PetInfoForm
        responseError={responseError || createPetError?.message}
        disabled={createPetLoading || !!createPetData}
        onSubmit={(petInfo: CreateOrUpdatePetInput) => {
          createPet({
            variables: {
              input: petInfo,
            },
            refetchQueries: [{ query: manageHomeQuery }],
          }).catch(() => {
            // Need to handle rejection here, but error message from hook is used
          });
        }}
      />
    </>
  );
}
