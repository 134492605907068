import React, { ReactNode } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { AppViewLinkState } from './types';
import { useBackBehaviorContext, BackBehaviorContextProvider, BackBehavior } from './BackBehaviorContext';
import { Links } from '../../lib/constants';
import { useAppViewContext } from './AppViewContext';

export function useBackAction({ backLink }: { backLink?: string } = {}) {
  const history = useHistory();
  const location = useLocation();
  const { behavior: backBehaviorFromContext } = useBackBehaviorContext();
  const { isDesktop } = useAppViewContext();
  const backBehavior: BackBehavior = isDesktop ? 'back' : backBehaviorFromContext;
  const isInitialLocation = !location.state;

  return () => {
    if (backLink) {
      history.push(backLink);
    } else if (backBehavior === 'close' && isInitialLocation) {
      window.open(Links.closeAppView());
    } else {
      history.goBack();
    }
  };
}

function BackBehaviorOverrideWrapper({
  backBehavior,
  children,
}: {
  backBehavior: 'close' | 'back';
  children: ReactNode;
}) {
  const location = useLocation<AppViewLinkState>();
  const backBehaviorOverride = location.state?.forceBackBehavior;
  return (
    <BackBehaviorContextProvider behavior={backBehaviorOverride ?? backBehavior}>
      {children}
    </BackBehaviorContextProvider>
  );
}

export const withBackBehavior =
  <P extends {}>(backBehavior: 'close' | 'back', Component: React.FC<P>): React.FC<P> =>
  (props: P) => {
    return (
      <BackBehaviorOverrideWrapper backBehavior={backBehavior}>
        <Component {...props} />
      </BackBehaviorOverrideWrapper>
    );
  };
