import React from 'react';
import SelectFormField from '../../../../components/form/SelectFormField';
import { gqlTypes } from '../../../../types/gqlTypes';

export interface GenderSelectProps {
  name: string;
}

export default function GenderSelect({ name }: GenderSelectProps) {
  return (
    <SelectFormField
      name={name}
      placeholder="Gender"
      options={[
        {
          label: `Male`,
          value: gqlTypes.Gender.MALE,
        },
        {
          label: `Female`,
          value: gqlTypes.Gender.FEMALE,
        },
      ]}
    />
  );
}
