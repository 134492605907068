import { PureQueryOptions } from '@apollo/client';
import React from 'react';
import ChipItem from './ChipItem';
import ChipListHeader from './ChipListHeader';
import { gqlTypes } from '../../types/gqlTypes';
import StatusTypeMap from './StatusTypeMap';

export interface ChipListProps {
  chips: gqlTypes.miniChip[];
  statusTypeMap: StatusTypeMap;
  refetchQueries?: PureQueryOptions[];
}

export default function ChipList({ chips, statusTypeMap, refetchQueries }: ChipListProps) {
  return (
    <div>
      <ChipListHeader />
      <div>
        {chips.map((chip, i) => (
          <ChipItem chip={chip} statusType={statusTypeMap.get(chip.id)} key={i} refetchQueries={refetchQueries} />
        ))}
      </div>
    </div>
  );
}
