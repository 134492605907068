import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import { CustomBackgroundColor } from '../../lib/backgroundColor';
import { Colors } from '../../lib/constants';
import { isSearchState } from '../../lib/SearchState';
import SearchResult from './SearchResult';
import NoSearchResults from './NoSearchResults';

export default function Search() {
  const location = useLocation();

  const state = location.state;

  if (!isSearchState(state)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="single-column">
      <CustomBackgroundColor color={Colors.lightGrayBackground} />
      {state.response.result ? (
        <SearchResult result={state.response.result} />
      ) : (
        <NoSearchResults query={state.query} />
      )}
    </div>
  );
}
