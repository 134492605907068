import React from 'react';
import { ChipSearchResult } from '@barkinglabs/nano-shared';

import PetCard from './components/PetCard';
import VetCard from './components/VetCard';
import ContactHistoryCard from './components/ContactHistoryCard';

import styles from './SearchResult.module.scss';

interface SearchResultsProps {
  result: ChipSearchResult;
}

export default function SearchResult({ result }: SearchResultsProps) {
  const historyEntries = result.contactHistory.slice(1);

  return (
    <div className={styles.main}>
      <PetCard pet={result.pet} searchResult={result} />
      {historyEntries.map((historyEntry, i) => (
        <ContactHistoryCard searchResult={result} historyEntry={historyEntry} key={i} />
      ))}
      {result.reseller && <VetCard searchResult={result} reseller={result.reseller} />}
    </div>
  );
}
