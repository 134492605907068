import React, { ReactNode } from 'react';

import styles from './ActionsContainer.module.scss';

export interface ActionsContainerProps {
  children: ReactNode;
}

export default function ActionsContainer({ children }: ActionsContainerProps) {
  return <div className={styles.main}>{children}</div>;
}
