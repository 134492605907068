import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './List.module.scss';

export interface ListProps {
  relaxed?: boolean;
  children: ReactNode;
  horizontal?: boolean;
}

export interface ListItemProps {
  children: ReactNode;
  className?: string;
}

interface ListType {
  (props: ListProps): JSX.Element;
  Item(props: ListItemProps): JSX.Element;
}

/**
 * An unstyled list.
 */
const List: ListType = ({ relaxed, horizontal, children }: ListProps) => (
  <ul
    className={classNames(styles.main, {
      [styles.relaxed]: relaxed,
      [styles.horizontal]: horizontal,
    })}
  >
    {children}
  </ul>
);

List.Item = ({ children, className }: ListItemProps) => <li className={className}>{children}</li>;

export default List;
