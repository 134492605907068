import { ApolloClient, HttpLink, throwServerError } from '@apollo/client';

import cache from './cache';
import { store } from '../store';
import { sessionActions } from '../reducers/session';
import { apiHost } from '../constants';

async function customFetch(input: RequestInfo | URL, init?: RequestInit) {
  const response = await fetch(input, init);
  if (response.status === 401) {
    store.dispatch(sessionActions.destroy({}));
  }

  if (response.status >= 300) {
    const body = await response.json();
    const errorMessage =
      body?.errors && Array.isArray(body.errors) && !!body.errors.length
        ? body.errors[0].message
        : `An unexpected error occurred (${response.status})`;

    throwServerError(response, body, errorMessage);
  }

  return response;
}

const link = new HttpLink({
  uri: `${apiHost}/graphql`,
  credentials: 'include',
  fetch: customFetch,
});

const client = new ApolloClient({
  cache,
  link,
});

export default client;
