import { useCallback, useEffect, useState } from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import { manageHomeQuery } from '../../ManageHome/ManageHome.graphql';
import { useUpdateChipContactInfo } from '../../lib/mutations';
import OutlineButton from '../OutlineButton';
import PetList from '../PetList/PetList';
import ModalConfirm from '../modals/ModalConfirm';
import CreatePet from './CreatePet';

/**
 * The mutation we use to attach a pet to a chip depends on if the user is able to resolve the transfer request.
 * If they can, we can go ahead and resolve the transfer with the desired pet. If they're waiting for the current
 * chip owner to accept the transfer, they will have to just update their existing transfer request with the pet id.
 */
function useUpdateChipWithPet(chipShortId: string, contactInfo: gqlTypes.ChipContactInfoInput) {
  const [
    updateChipContactInfo,
    { data: updateChipContactInfoData, loading: updateChipContactInfoLoading, error: updateChipContactInfoError },
  ] = useUpdateChipContactInfo();

  const updateChipWithPet = useCallback(
    (petId: string) => {
      const refetchQueries = [{ query: manageHomeQuery }];

      return updateChipContactInfo({
        variables: {
          input: {
            chipId: chipShortId,
            petId,
            contactInfo,
          },
        },
        refetchQueries,
      }).catch(() => {
        // Avoid unhandled rejection, but error is used from the hook
      });
    },
    [chipShortId, contactInfo, updateChipContactInfo],
  );

  return {
    updateChipWithPet,
    success: !!updateChipContactInfoData,
    loading: updateChipContactInfoLoading,
    error: updateChipContactInfoError,
  };
}

interface PetInfoStepProps {
  chipShortId: string;
  contactInfo: gqlTypes.ChipContactInfoInput;
  unassignedPets: gqlTypes.userPet[];
  onSuccess: () => void;
}

export default function PetInfoStep({ chipShortId, contactInfo, unassignedPets, onSuccess }: PetInfoStepProps) {
  const [petNeedsConfirmation, setPetNeedsConfirmation] = useState<{ id: string; name: string } | undefined>();
  const [addNewPet, setAddNewPet] = useState<boolean>(unassignedPets.length === 0);

  const {
    updateChipWithPet,
    success: updateChipWithPetSuccess,
    loading: updateChipWithPetLoading,
    error: updateChipWithPetError,
  } = useUpdateChipWithPet(chipShortId, contactInfo);

  // After resolveChipTransfer mutation completes
  useEffect(() => {
    if (updateChipWithPetLoading) {
      return;
    }

    if (updateChipWithPetSuccess) {
      onSuccess();
    }
  }, [updateChipWithPetLoading, updateChipWithPetSuccess, onSuccess]);

  const createPetOnSuccess = useCallback(
    (petId) => {
      updateChipWithPet(petId);
    },
    [updateChipWithPet],
  );

  if (addNewPet) {
    return <CreatePet responseError={updateChipWithPetError?.message} onSuccess={createPetOnSuccess} />;
  }

  return (
    <>
      <h4>Select a pet for this chip</h4>
      <ModalConfirm
        open={!!petNeedsConfirmation}
        onAccept={() => {
          if (petNeedsConfirmation) {
            updateChipWithPet(petNeedsConfirmation.id);
          }
        }}
        onReject={() => setPetNeedsConfirmation(undefined)}
        message={`Are you sure you want to assign ${petNeedsConfirmation?.name} to chip ${chipShortId}?`}
      />
      <OutlineButton secondary onClick={() => setAddNewPet(true)}>
        Add a new pet
      </OutlineButton>
      <PetList
        pets={unassignedPets}
        onClick={(pet) => {
          setPetNeedsConfirmation(pet);
        }}
      />
    </>
  );
}
