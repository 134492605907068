import { gql } from '@apollo/client';
import { renderFragments, baseChipFragment } from '../../lib/fragments';

export const chipDetailsQuery = gql`
  query chipDetails($id: ID!) {
    chip(id: $id) {
      ...baseChip
    }
  }

  ${renderFragments(baseChipFragment)}
`;
