import React, { useContext, ReactNode, useState } from 'react';

export type BackBehavior = 'back' | 'close';

export interface BackBehaviorState {
  behavior: BackBehavior;
  needsConfirmation: boolean;
  setNeedsConfirmation(value: boolean): void;
}

const emptyBackBehaviorState: BackBehaviorState = {
  behavior: 'back',
  needsConfirmation: false,
  setNeedsConfirmation: () => {},
};

const BackBehaviorContext = React.createContext<BackBehaviorState>(emptyBackBehaviorState);

export function useBackBehaviorContext() {
  return useContext(BackBehaviorContext);
}

export function BackBehaviorContextProvider({ behavior, children }: { behavior: BackBehavior; children: ReactNode }) {
  const [needsConfirmation, setNeedsConfirmation] = useState<boolean>(false);

  return (
    <BackBehaviorContext.Provider value={{ behavior, needsConfirmation, setNeedsConfirmation }}>
      {children}
    </BackBehaviorContext.Provider>
  );
}
