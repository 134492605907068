import React from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import PetListRow from './PetListRow';

export interface PetListProps {
  pets: gqlTypes.userPet[];
  onClick?: (pet: gqlTypes.userPet) => void;
}

export default function PetList({ pets, onClick }: PetListProps) {
  return (
    <div>
      {pets.map((pet, i) => (
        <PetListRow pet={pet} key={i} onClick={onClick ? () => onClick(pet) : undefined} />
      ))}
    </div>
  );
}
