import React, { useEffect, useRef, useState } from 'react';

export interface RecaptchaControlProps {
  onToken(token: string): void;
}

let idCounter = 0;
function makeId() {
  return `googleRecaptchaControl-${++idCounter}`;
}

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function RecaptchaControl({ onToken }: RecaptchaControlProps) {
  const tokenCallbackRef = useRef(onToken);
  const [id] = useState(makeId);

  useEffect(() => {
    tokenCallbackRef.current = onToken;
  }, [onToken]);

  useEffect(() => {
    async function doRender() {
      await window.recaptchaReadyPromise; // Typically, already resolved.
      if (recaptchaSiteKey) {
        window.grecaptcha.render(id, {
          sitekey: recaptchaSiteKey,
          callback: (token: string) => tokenCallbackRef.current(token),
        });
      } else {
        console.warn(`Cannot render recaptcha, missing site key`);
      }
    }
    doRender();
  }, [tokenCallbackRef, id]);

  return <div id={id} />;
}
