import React, { useState } from 'react';
import Tabs from './Tabs';
import SearchBar from './SearchBar';
import ViewingChips from './ViewingChips';
import ResellerSearchResults from './ResellerSearchResults';
import { useQueryParams } from '../../lib/useQueryParams';
import { useAppViewContext } from '../lib/AppViewContext';

interface ListChipsQueryParams {
  showBackButton?: string;
}

export default function ListChips() {
  const { selectedStatus, setSelectedStatus } = useAppViewContext();

  const [searchQuery, setSearchQuery] = useState<null | string>(null);

  const queryParams = useQueryParams<ListChipsQueryParams>();

  return (
    <div>
      <SearchBar
        onQuerySubmitted={(query) => setSearchQuery(query)}
        searchedQuery={searchQuery}
        onQueryCleared={() => setSearchQuery(null)}
        showBackButton={!!queryParams.showBackButton}
      />
      {searchQuery ? (
        <ResellerSearchResults query={searchQuery} />
      ) : (
        <>
          <Tabs selectedStatus={selectedStatus} onSelectionChanged={(newStatus) => setSelectedStatus(newStatus)} />
          <ViewingChips statusType={selectedStatus} />
        </>
      )}
    </div>
  );
}
