import React from 'react';
import { Link } from 'react-router-dom';
import { Links } from '../../../lib/constants';

import styles from './RequestUpdateLink.module.scss';

interface RequestUpdateLinkProps {
  serialNumber: string;
  contactToken: string;
}

export default function RequestUpdateLink(props: RequestUpdateLinkProps) {
  return (
    <div className={styles.main}>
      <Link to={Links.register({ id: props.serialNumber })}>Request Update</Link>
    </div>
  );
}
