import React, { useState } from 'react';
import RecaptchaControl from './RecaptchaControl';
import { useHistory } from 'react-router-dom';
import { SearchState } from '../../lib/SearchState';
import { search } from '../../lib/chipsApi';

export interface SearchVerfiicationProps {
  query: string;
}

export default function SearchVerification({ query }: SearchVerfiicationProps) {
  const [error, setError] = useState(false);
  const history = useHistory();

  const gotToken = async (token: string) => {
    // Issue search using token
    try {
      const response = await search(query, token);
      const state: SearchState = {
        isSearchState: true,
        query,
        response,
      };

      history.push({ pathname: '/search', state });
    } catch (err) {
      console.error(err);
      setError(true);
    }
  };

  let body: JSX.Element;
  if (error) {
    body = (
      <>
        <h4>Sorry, we were unable to perform your search.</h4>
      </>
    );
  } else {
    body = (
      <>
        <h4>Please check the box below to proceed.</h4>
        <RecaptchaControl onToken={gotToken} />
      </>
    );
  }

  return <div className="single-column single-column--centered">{body}</div>;
}
