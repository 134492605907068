import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router-dom';
import client from '../../lib/apollo/client';
import { AppState } from '../../lib/store';
import { userChipResellerQuery } from '../../lib/utils';
import { gqlTypes } from '../../types/gqlTypes';
import ContactInfoStep from './ContactInfoStepV2/ContactInfoStepV2';
import EmailStep from './EmailStepV2/EmailStepV2';
import PetInfoStep from './PetInfoStepV2/PetInfoStepV2';
import { chipRegistrationAddEmailMutation } from './RegisterChip.graphql';
import RegisterChipContext, { RegisterChipStep, stepsInOrderV2 } from './RegisterChipContextV2';
import styles from './RegisterChipV2.module.scss';
import { useRegisterStateV2 } from './RegisterStateV2';
import SerialNumberStep from './SerialNumberStepV2/SerialNumberStepV2';
import SuccessStep from './SuccessStepV2/SuccessStepV2';
import { FeaturesReady } from '@growthbook/growthbook-react';
import UpgradeStep from './UpgradeStepV2/UpgradeStepV2';

/**
 * A helper to determine if all the required information has been filled out for the previous steps
 */
function useMaybeRedirect() {
  const { registerState, setPrimaryEmail, currentUserIsReseller } = useContext(RegisterChipContext);
  const isLoggedIn = useSelector((state: AppState) => !!state.session);
  const email = useSelector((state: AppState) => state.session?.email);

  const { chipId } = registerState;

  return useCallback(
    (step: RegisterChipStep) => {
      if (!chipId && stepsInOrderV2.indexOf(step) > stepsInOrderV2.indexOf('chip')) {
        return <Redirect to={`/register/chip`} />;
      }

      if (!isLoggedIn && stepsInOrderV2.indexOf(step) > stepsInOrderV2.indexOf('email')) {
        return <Redirect to={`/register/email`} />;
      }

      if (isLoggedIn && email && step === 'email') {
        setPrimaryEmail(email);
        return <Redirect to={`/register/pet`} />;
      }

      if (currentUserIsReseller && step === 'upgrade') {
        return <Redirect to={`/register/success`} />;
      }

      return undefined;
    },
    [chipId, isLoggedIn, email, setPrimaryEmail, currentUserIsReseller],
  );
}

function RegisterChipRoute({ children, step }: { children: React.ReactNode; step: RegisterChipStep }) {
  const maybeRedirect = useMaybeRedirect();

  return (
    <Route
      exact
      path={`/register/${step}`}
      render={() => {
        return maybeRedirect(step) || children;
      }}
    />
  );
}

export default function RegisterChipV2() {
  const { registerState, ...reducers } = useRegisterStateV2();
  const { chipId } = registerState;

  const history = useHistory();
  const isLoggedIn = useSelector((state: AppState) => !!state.session);

  const onDone = useCallback(() => {
    history.replace('/manage');
  }, [history]);

  const onStepFinished = useCallback(
    (step: RegisterChipStep) => {
      const nextStep = stepsInOrderV2[stepsInOrderV2.indexOf(step) + 1];
      if (nextStep) {
        history.push(`/register/${nextStep}`);
      } else {
        onDone();
      }
    },
    [history, onDone],
  );

  const { data: currentUserResellerData, loading: currentUserResellerLoading } = useQuery<gqlTypes.userChipReseller>(
    userChipResellerQuery,
    {
      skip: !isLoggedIn,
    },
  );

  const isReseller = currentUserResellerData?.currentUser.chipReseller?.status === gqlTypes.ResellerStatus.ACCEPTED;

  // Mutation to register the chip. It can be called from step 1, if the user is already logged in, or from step 2
  // if the user is not logged in.
  const registerChipAddEmail = useCallback(
    async (chipId) => {
      // If the current user is a reseller, they won't be using this flow to register chips to themselves. They
      // will be assigning them to other people. So we won't call this mutation, which is for registering chips to
      // the current user. Instead, they will enter new owner contact info in the last step.
      if (isReseller) {
        return;
      }

      const response = await client.mutate<
        gqlTypes.NANO_chipRegistrationAddEmail,
        gqlTypes.NANO_chipRegistrationAddEmailVariables
      >({
        mutation: chipRegistrationAddEmailMutation,
        variables: {
          input: {
            chipId,
          },
        },
      });

      if (response.data) {
        return;
      }

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      throw new Error('Failed to register chip');
    },
    [isReseller],
  );

  // Wait until we know if the current user, if any, is a reseller
  if (isLoggedIn && currentUserResellerLoading) {
    return null;
  }

  // We want vets to select from a list of stock chips first
  if (isLoggedIn && isReseller && !chipId) {
    return <Redirect to="/vet/chips" />;
  }

  return (
    <RegisterChipContext.Provider
      value={{
        currentUserIsReseller: isReseller,
        onStepFinished,
        registerState,
        registerChipAddEmail,
        ...reducers,
      }}
    >
      <div className={classNames('register-chip-next', styles.container)}>
        <RegisterChipRoute step="chip">
          <SerialNumberStep />
        </RegisterChipRoute>
        <RegisterChipRoute step="email">
          <EmailStep />
        </RegisterChipRoute>
        <RegisterChipRoute step="pet">
          <PetInfoStep />
        </RegisterChipRoute>
        <RegisterChipRoute step="contact">
          <ContactInfoStep />
        </RegisterChipRoute>
        <RegisterChipRoute step="upgrade">
          <UpgradeStep />
        </RegisterChipRoute>
        <RegisterChipRoute step="success">
          <FeaturesReady>
            <SuccessStep />
          </FeaturesReady>
        </RegisterChipRoute>
      </div>
    </RegisterChipContext.Provider>
  );
}
