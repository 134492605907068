import { gql } from '@apollo/client';
import { miniChipFragment, renderFragments } from '../../lib/fragments';

export const viewingChipsQuery = gql`
  query viewingChips($statusType: ChipStatusType!, $limit: Int, $offset: Int) {
    currentUser {
      id
      chipReseller {
        id
        chips(statusType: $statusType, limit: $limit, offset: $offset) {
          chips {
            ...miniChip
          }
        }
      }
    }
  }

  ${renderFragments(miniChipFragment)}
`;
