import { gql } from '@apollo/client';
import validator from 'validator';

// https://stackoverflow.com/a/53210158/1480571
// What could go wrong?
export const phoneRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const KG_TO_LBS = 2.20462;

export const kgToLbs = (input: number) => input * KG_TO_LBS;

export const lbsToKg = (input: number) => input / KG_TO_LBS;

export const saneEmail = (email: string | null | undefined) => !email || !email.endsWith('.con');

export const isOptionalUrl = (url: string | null | undefined) => (url ? validator.isURL(url) : true);

export const userChipResellerQuery = gql`
  query userChipReseller {
    currentUser {
      chipReseller {
        id
        status
      }
    }
  }
`;
