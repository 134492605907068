import React from 'react';
import { Link } from 'react-router-dom';

export default function InternalOrExternalLink({
  to,
  children,
  ...otherProps
}: { to: string } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>) {
  const isExternal = to.startsWith('http');
  if (isExternal) {
    return (
      <a href={to} {...otherProps}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...otherProps}>
        {children}
      </Link>
    );
  }
}
