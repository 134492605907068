import React from 'react';
import styles from './RadioField.module.scss';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';

interface RadioFieldOption {
  label: string;
  value: string;
}

interface RadioFieldProps {
  label: string;
  name: string;
  options: Array<RadioFieldOption>;
  selectedValue?: string;
}

export default function RadioField({ label, name, options, selectedValue }: RadioFieldProps) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  return (
    <div className={styles.container}>
      <label>{label}</label>
      <div className={styles.inputContainer}>
        {options.map(({ label, value: optionValue }) => (
          <div
            key={optionValue}
            className={classNames(styles.radioOption, {
              [styles.selected]: optionValue === (selectedValue ?? field.value),
            })}
            onClick={() => {
              setFieldValue(name, optionValue);
            }}
          >
            <span className={styles.radioText}>{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
