import { useApolloClient } from '@apollo/client';
import React, { useEffect } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import { CustomBackgroundColor } from '../../lib/backgroundColor';
import { Colors } from '../../lib/constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../lib/store';
import { Link, useHistory } from 'react-router-dom';
import SignupOption from './SignupOption';
import { gqlTypes } from '../../types/gqlTypes';
import { useQueryParams } from '../../lib/useQueryParams';
import { userChipResellerQuery } from '../../lib/utils';

import styles from './Login.module.scss';

interface LoginQueryParams {
  redirectUri?: string;
}

export default function Login() {
  const { redirectUri } = useQueryParams<LoginQueryParams>();
  const history = useHistory();
  const client = useApolloClient();
  const areLoggedIn = useSelector((state: AppState) => !!state.session);

  useEffect(() => {
    async function doRedirect() {
      if (redirectUri) {
        history.push(redirectUri);
        return;
      }

      const { data } = await client.query<gqlTypes.userChipReseller>({
        query: userChipResellerQuery,
      });
      const isAcceptedReseller = data?.currentUser.chipReseller?.status === gqlTypes.ResellerStatus.ACCEPTED;
      history.push(isAcceptedReseller ? `/vet/chips` : `/manage`);
    }

    if (areLoggedIn) {
      doRedirect();
    }
  }, [areLoggedIn, client, history, redirectUri]);

  return (
    <div className="single-column single-column--narrow">
      <CustomBackgroundColor color={Colors.lightGrayBackground} />
      <LoginForm />
      <div className={styles.actions}>
        <Link to="/passwordReset" className="link link__underlined">
          Forgot your password?
        </Link>
      </div>
      <div className={styles.signupOptions}>
        <div className={styles.signupPrompt}>Don’t have an account?</div>
        <div>
          <SignupOption
            title="Vet / Vet Pro"
            subtitle="Apply to the Nano program"
            linkText="Apply here"
            linkHref="/apply"
            secondaryLink
          />
          <SignupOption title="Fi Nano owner" subtitle="Setup your Nano" linkText="Sign up here" linkHref="/signup" />
        </div>
      </div>
    </div>
  );
}
