import React, { useMemo } from 'react';

// https://stackoverflow.com/a/8358141/1480571
function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return null;
}

export interface PhoneNumberLinkProps {
  number: string;
}

export default function PhoneNumberLink({ number }: PhoneNumberLinkProps) {
  // Try to get a nicely formatted number, if we can't we fall back to the given number
  const formattedNumber = useMemo(() => formatPhoneNumber(number), [number]);

  return <a href={`tel:${number}`}>{formattedNumber ?? number}</a>;
}
