import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { gqlTypes } from '../../types/gqlTypes';
import AppBar from '../components/AppBar';
import OutlineButton from '../components/OutlineButton';
import PetList from '../components/PetList/PetList';
import { manageHomeQuery } from './ManageHome.graphql';
import ChipsWithoutPetList from '../components/ChipsWithoutPetList';

export default function ManageHome() {
  const history = useHistory();
  const { data, loading } = useQuery<gqlTypes.manageHome>(manageHomeQuery);

  if (loading || !data) {
    return null;
  }

  // These pets may or may not have chips associated with them. In the pet list, we'll show pets even without
  // chips (e.g. Fi collar user that does not have a chip registered), pets with chips assigned, or pets with a
  // pending chip transfer.
  const pets = data.currentUser.userHouseholds.flatMap((userHousehold) => userHousehold.household.pets);

  // Since the above list will show all chips asssociated with pets (whether assigned, or just pending)
  // we also have 2 more catgories of things to show in this view:
  // 1. Pending transfers that do not have a pet associated with them.
  // 2. Chips that are assigned to the user but do not have a pet assigned to them.
  const chipsWithoutPets = data.currentUser.chips.filter((chip) => {
    // Unassigned chips (pending transfer)
    if (chip.__typename === 'StrangerChip') {
      // Exclude chips without a transfer request
      if (!chip.transfer) {
        return false;
      }

      // Exclude chips with a transfer request but pet is already assigned. That will be taken care of in the pet list.
      if (chip.transfer.pet) {
        return false;
      }

      return true;
    }

    // Assigned chips
    if (chip.__typename === 'AssignedChip') {
      // Exclude chips that already have a pet assigned. That will be taken care of in the pet list.
      if (chip.pet) {
        return false;
      }

      return true;
    }

    return false;
  });

  // Put chip-active pets first.
  pets.sort((a, b) => {
    const x = !!a.chip;
    const y = !!b.chip;
    return x === y ? 0 : x ? -1 : 1;
  });

  // Nothing to show them in this view. Take them to register a new chip.
  if (pets.length === 0 && chipsWithoutPets.length === 0) {
    return <Redirect to={{ pathname: '/register', state: { hideBackButton: true } }} />;
  }

  return (
    <div>
      <AppBar title="Your Pets" hideBackButton />
      <ChipsWithoutPetList chips={chipsWithoutPets} />
      <PetList pets={pets} />
      <OutlineButton secondary onClick={() => history.push(`/register`)}>
        {'Register a Chip'}
      </OutlineButton>
    </div>
  );
}
