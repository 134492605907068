import React from 'react';

import styles from './Comparison.module.scss';

function ComparisonHeader() {
  return (
    <div className={styles.header}>
      <div className={styles.preHeader}>Fi NANO vs. OTHER MICROCHIPS</div>
      <h3>Safe, modern, and cost-effective.</h3>
    </div>
  );
}

interface ComparisonCellProps {
  main: string;
  bullets?: string[];
}

function ComparisonCell({ main, bullets }: ComparisonCellProps) {
  return (
    <td>
      <div>{main}</div>
      {bullets && (
        <ul>
          {bullets.map((bullet, i) => (
            <li key={i}>{bullet}</li>
          ))}
        </ul>
      )}
    </td>
  );
}

interface ComparisonRowProps {
  title: string;
  nano: ComparisonCellProps;
  other: ComparisonCellProps;
}

function ComparisonRow({ title, nano, other }: ComparisonRowProps) {
  return (
    <>
      <tr>
        <td colSpan={2} className={styles.mobileTitle}>
          {title}
        </td>
      </tr>
      <tr>
        <td className={styles.desktopTitle}>{title}</td>
        <ComparisonCell {...nano} />
        <ComparisonCell {...other} />
      </tr>
    </>
  );
}

const rows: ComparisonRowProps[] = [
  {
    title: `Chips`,
    nano: {
      main: `1.4mm ISO`,
      bullets: [
        `ISO Certified & ICAR registered`,
        `Internationally scannable on all existing scanners`,
        `Small gauge needle, sterile individually wrapped with syringe`,
      ],
    },
    other: {
      main: `2.2mm ISO - 5.5mm ISO`,
    },
  },
  {
    title: `Registry`,
    nano: {
      main: `Fully searchable by AAHA 24/7`,
    },
    other: {
      main: `Fully searchable by AAHA 24/7`,
    },
  },
  {
    title: `Cost to Pet Owners`,
    nano: {
      main: `Free registry for life`,
    },
    other: {
      main: `$20/year subscription`,
    },
  },
  {
    title: `Cost to Vets`,
    nano: {
      main: `$3/chip`,
    },
    other: {
      main: `$22 – $25/chip`,
    },
  },
  {
    title: `Registration Method`,
    nano: {
      main: `Automatic upon shipment`,
      bullets: [
        `100% registration`,
        `Microchips are linked to the vet at shipment, then easily updated once implanted.`,
      ],
    },
    other: {
      main: `Manual by customer`,
      bullets: [
        `52% registration`,
        `Microchips manufacturers try to charge a subscription to pet parents, who end up not registering their contact information.`,
      ],
    },
  },
  {
    title: `Contact Update`,
    nano: {
      main: `iPhone/Android or web browser`,
    },
    other: {
      main: `Manufacturer web portal`,
    },
  },
];

export default function Comparison() {
  return (
    <div className={styles.main}>
      <ComparisonHeader />
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div>Product</div>
            </th>
            <th className="uppercase">
              <div>
                F<span className="no-uppercase">i</span> Nano
              </div>
            </th>
            <th>
              <div>Homeagain &amp; Others</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <ComparisonRow {...row} key={i} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
