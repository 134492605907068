import { configureStore, combineReducers, PayloadAction } from '@reduxjs/toolkit';
import { Session, sessionReducer } from './reducers/session';

export interface AppState {
  session: Session | null;
}

const initialAppState: AppState = {
  session: null,
};

const storedState = window.localStorage.getItem('reduxState');
const persistedState: AppState = storedState
  ? {
      ...initialAppState,
      ...JSON.parse(storedState),
    }
  : initialAppState;

export const store = configureStore({
  reducer: combineReducers<AppState, PayloadAction<any>>({
    session: sessionReducer,
  }),
  preloadedState: persistedState,
});

store.subscribe(() => {
  const stateToPersist = store.getState();
  localStorage.setItem('reduxState', JSON.stringify(stateToPersist));
});
