import { gqlTypes } from '../../types/gqlTypes';

export default abstract class StatusTypeMap {
  abstract get(chipId: string): gqlTypes.ChipStatusType;

  static fromResults(results: Array<{ id: string; status: gqlTypes.ChipStatusType }>): StatusTypeMap {
    return new ConcreteStatusTypeMap(new Map(results.map((result) => [result.id, result.status])));
  }

  static uniform(statusType: gqlTypes.ChipStatusType): StatusTypeMap {
    return new UniformStatusTypeMap(statusType);
  }
}

class UniformStatusTypeMap extends StatusTypeMap {
  constructor(private readonly statusType: gqlTypes.ChipStatusType) {
    super();
  }

  get(_chipId: string) {
    return this.statusType;
  }
}

class ConcreteStatusTypeMap extends StatusTypeMap {
  constructor(private readonly map: Map<string, gqlTypes.ChipStatusType>) {
    super();
  }

  get(chipId: string) {
    return this.map.get(chipId) ?? gqlTypes.ChipStatusType.ACTIVE;
  }
}
