import { useQuery } from '@apollo/client';
import React, { useState, useEffect, useMemo } from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import { allBreedsQuery } from './BreedSelect.graphql';
import { ErrorMessage, useFormikContext } from 'formik';
import classNames from 'classnames';
import RadioField from '../../../components/form/RadioField';
import BreedSelectModal from './BreedSelectModal';

export const UNKNOWN_BREED_NAME = 'Other';

export interface BreedFields {
  breedId: string;
  isMixedBreed: boolean;
  species: string;
}

interface BreedSelectProps {
  name: string;
  isMixedBreed: boolean;
}

const SPECIES_OPTIONS = [
  {
    label: `Dog`,
    value: gqlTypes.Species.DOG,
  },
  {
    label: `Cat`,
    value: gqlTypes.Species.CAT,
  },
  {
    label: `Other`,
    value: gqlTypes.Species.OTHER,
  },
];

export default function BreedSelect({ name }: BreedSelectProps) {
  const { data } = useQuery<gqlTypes.allBreeds>(allBreedsQuery);

  const { setFieldValue, values } = useFormikContext<BreedFields>();

  const options = useMemo(() => {
    const breeds = data?.allBreeds ?? [];

    return breeds.map((breed) => ({
      label: breed.name,
      value: breed.id,
    }));
  }, [data?.allBreeds]);

  const [value, setValue] = useState(() => values.breedId || '');
  const species = useMemo(() => values.species || gqlTypes.Species.DOG, [values.species]);

  useEffect(() => {
    setFieldValue(name, value);
  }, [value, name, setFieldValue]);

  return (
    <>
      <div className="form-group">
        <div className="form-field">
          <RadioField
            options={SPECIES_OPTIONS}
            name="species"
            label="Type"
            selectedValue={values.species || gqlTypes.Species.DOG}
          />
        </div>
      </div>
      {species === gqlTypes.Species.DOG && (
        <div className={classNames('form-group')}>
          <div className="form-field">
            <BreedSelectModal options={options} setValue={setValue} value={values.breedId} />
          </div>
        </div>
      )}

      <ErrorMessage name={name} component="div" className="form-field__error" />
    </>
  );
}
