import { useQuery } from '@apollo/client';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Links } from '../../lib/constants';
import { AppState } from '../../lib/store';
import { userChipResellerQuery } from '../../lib/utils';
import { gqlTypes } from '../../types/gqlTypes';

type UserType = 'reseller' | 'owner';

export default function EnsureLoggedIn({ children, userType }: { children: ReactNode; userType?: UserType }) {
  const location = useLocation();
  const isLoggedIn = useSelector((state: AppState) => !!state.session);
  const { data: resellerData, loading: resellerLoading } = useQuery<gqlTypes.userChipReseller>(userChipResellerQuery, {
    skip: !isLoggedIn,
  });

  if (!isLoggedIn) {
    return <Redirect to={Links.login(`${location.pathname}${location.search}`)} />;
  }

  const currentUserIsReseller = !!resellerData?.currentUser.chipReseller;
  if (userType && resellerLoading) {
    return null;
  } else if (userType === 'reseller' && !currentUserIsReseller) {
    return <Redirect to={Links.manageHome} />;
  } else if (userType === 'owner' && currentUserIsReseller) {
    return <Redirect to={Links.vetChipList()} />;
  }

  return <>{children}</>;
}

export const withEnsureLoggedIn =
  <P extends {}>(Component: React.FC<P>, userType?: UserType): React.FC<P> =>
  (props: P) => {
    return (
      <EnsureLoggedIn userType={userType}>
        <Component {...props} />
      </EnsureLoggedIn>
    );
  };
