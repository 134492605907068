import { useMutation } from '@apollo/client';
import React, { useEffect, useCallback } from 'react';
import { isValidThirdPartySerialNumber } from '@barkinglabs/nano-shared';

import { withBackBehavior, useBackAction } from '../lib/withBackBehavior';
import { resellerChipDetailsQuery, registerThirdPartyChip } from './ResellerChipDetails.graphql';
import NotFound from '../components/NotFound';
import { gqlTypes } from '../../types/gqlTypes';
import PetDetails from '../components/ChipDetails/PetDetails/PetDetails';
import ViewContactInfo from '../components/ChipDetails/ViewContactInfo/ViewContactInfo';
import { useQueryParams } from '../../lib/useQueryParams';
import QueryBasedPage, { QueryBasedPageChildProps } from '../components/QueryBasedPage';
import AcceptTransfer from '../components/AcceptTransfer/AcceptTransfer';
import ModalConfirm from '../components/modals/ModalConfirm';
import ModalAlert from '../components/modals/ModalAlert';
import EditPetAndContactInfo from '../components/EditPetAndContactInfo/EditPetAndContactInfo';
import { Links } from '../../lib/constants';
import { useAppViewContext } from '../lib/AppViewContext';
import { Redirect, useHistory } from 'react-router-dom';
import FinalizeTransferView from '../components/FinalizeTransferView/FinalizeTransferView';

interface ResellerChipDetailsQueryParams {
  id?: string;
}

function RegisterChipModal({ serialNumber }: { serialNumber: string }) {
  const backAction = useBackAction();

  const [registerChip, { error, loading, called }] = useMutation<
    gqlTypes.resellerRegisterThirdPartyChip,
    gqlTypes.resellerRegisterThirdPartyChipVariables
  >(registerThirdPartyChip, {
    refetchQueries: [{ query: resellerChipDetailsQuery, variables: { id: serialNumber } }],
    awaitRefetchQueries: true,
  });

  if (error) {
    return (
      <ModalAlert
        message="There was an error registering this chip, please try again."
        open={true}
        onClose={() => backAction()}
      />
    );
  }

  return (
    <ModalConfirm
      message="This was not detected as a Fi Nano. Would you like to register this microchip?"
      open={true}
      onAccept={() => {
        // Ensure we're not calling multiple times
        if (!loading && !called) {
          registerChip({ variables: { input: { serialNumber } } });
        }
      }}
      onReject={() => {
        backAction();
      }}
    />
  );
}

interface ResellerChipDetailsData {
  chip: gqlTypes.resellerChip;
  ownedByReseller: boolean;
}

interface ResellerChipDetailsMainProps extends QueryBasedPageChildProps<ResellerChipDetailsData> {}

function ResellerChipDetailsMain({
  data: { chip, ownedByReseller },
  reload,
  editing,
  setCanEdit,
  setEditing,
}: ResellerChipDetailsMainProps) {
  const history = useHistory();
  const { id: chipId, transfer, pet, latestContactHistoryEntry } = chip;
  const { isDesktop } = useAppViewContext();

  const canEdit = ownedByReseller;
  const showTransferResolution = !!transfer?.canResolve && !ownedByReseller;
  useEffect(() => {
    setCanEdit(canEdit);
  }, [canEdit, setCanEdit]);

  if (transfer && showTransferResolution) {
    return (
      <AcceptTransfer chip={chip} transfer={transfer} pet={transfer.pet} reload={() => reload()} canEdit={canEdit} />
    );
  }

  if (!latestContactHistoryEntry) {
    return <Redirect to={Links.register({ id: chipId })} />;
  }

  if (editing) {
    if (!pet && transfer) {
      // This case woule be the reseller has a personal pet whose chip was owned by a different shelter
      // They can still see them in the pet list, but they can't resolve the transfer
      return (
        <FinalizeTransferView
          chipShortId={chip.shortId}
          contactInfo={transfer.contactInfo}
          onContactInfoUpdated={reload}
          onPetInfoUpdated={() => {
            reload();
            setEditing(false);
          }}
        />
      );
    }

    return (
      <EditPetAndContactInfo
        chipId={chipId}
        pet={pet ?? undefined}
        initialContactInfo={latestContactHistoryEntry?.contactInfo}
        onDone={() => {
          history.push(isDesktop ? Links.vetChipList() : Links.listChips());
        }}
      />
    );
  }

  return (
    <>
      {pet && (
        <PetDetails
          chip={chip}
          pet={pet}
          status={latestContactHistoryEntry ? 'active' : 'inactive'}
          contactInfoSource={latestContactHistoryEntry && latestContactHistoryEntry.source}
          onPetEdited={reload}
        />
      )}
      {latestContactHistoryEntry && <ViewContactInfo contactInfo={latestContactHistoryEntry.contactInfo} />}
    </>
  );
}

const ResellerChipDetails = withBackBehavior('close', () => {
  const { id } = useQueryParams<ResellerChipDetailsQueryParams>();

  const transformData = useCallback((data: gqlTypes.resellerChipDetails): ResellerChipDetailsData | undefined => {
    const chip = data.resellerChip;
    const currentUserPetIds = data.currentUser.userHouseholds
      .flatMap((uh) => (uh.householdRole === gqlTypes.UserHouseholdRole.OWNER ? uh.household.pets : []))
      .map((pet) => pet.id);

    const chipAssignedToPetInDifferentHousehold = !!chip?.pet && !currentUserPetIds.includes(chip.pet.id);

    const latestContactHistoryMatchesCurrentUserEmail =
      chip?.latestContactHistoryEntry?.contactInfo.primaryEmail === data.currentUser.email;

    return chip
      ? {
          chip,
          ownedByReseller: latestContactHistoryMatchesCurrentUserEmail && !chipAssignedToPetInDifferentHousehold,
        }
      : undefined;
  }, []);

  if (!id) {
    return <NotFound />;
  }

  const notFound = isValidThirdPartySerialNumber(id) ? <RegisterChipModal serialNumber={id} /> : undefined;

  return (
    <QueryBasedPage<gqlTypes.resellerChipDetails, gqlTypes.resellerChipDetailsVariables, ResellerChipDetailsData>
      query={resellerChipDetailsQuery}
      title={id}
      transformData={transformData}
      options={{ variables: { id } }}
      notFound={notFound}
    >
      {(childProps) => <ResellerChipDetailsMain {...childProps} />}
    </QueryBasedPage>
  );
});

export default ResellerChipDetails;
