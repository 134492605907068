import analytics, { IdentifyTraits } from '.';
import { getFiGrowthBook } from '../../appview/lib/growthbook';

export function identifyUser(userId: string, traits: IdentifyTraits) {
  analytics.identify(userId, traits);

  getFiGrowthBook().setAttributes();
}

export function resetAnalytics() {
  analytics.reset();
}

export function trackNewsletterSignup(email: string, source: string) {
  analytics.track('email_captured', {
    name: email.toLowerCase(),
    source,
  });
}

export function trackExperimentViewed(name: string, variant: string) {
  analytics.track('User Viewed Experiment', {
    name,
    variant,
  });
}

export function trackClickShopCollarCTA(component: string) {
  analytics.track('Nano Registration > Success > Get the Collar Button', {
    component,
  });
}
