import { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Links } from '../../lib/constants';
import { useQueryParams } from '../../lib/useQueryParams';
import { gqlTypes } from '../../types/gqlTypes';
import FinalizeTransferView from '../components/FinalizeTransferView/FinalizeTransferView';
import NotFound from '../components/NotFound';
import QueryBasedPage, { QueryBasedPageChildProps } from '../components/QueryBasedPage';
import { isStrangerChipWithTransfer } from '../lib/types';
import { withBackBehavior } from '../lib/withBackBehavior';
import AssignedChipView from './AssignedChipView';
import { chipDetailsQuery } from './ChipDetails.graphql';
import EditAssignedChipView from './EditAssignedChipView';
import PendingTransferView from './PendingTransferView';
import OutlineButton from '../components/OutlineButton';
import AcceptTransfer from '../components/AcceptTransfer/AcceptTransfer';

interface ChipDetailsQueryParams {
  id?: string;
}

interface ChipDetailsMainProps extends QueryBasedPageChildProps<gqlTypes.baseChip> {}

function ChipDetailsMain({ data: chip, editing, setCanEdit, setEditing, reload }: ChipDetailsMainProps) {
  const [viewTransfer, setViewTransfer] = useState<boolean>(false);

  useEffect(
    () => setCanEdit(chip.__typename === 'AssignedChip' && !!chip.pet && !viewTransfer),
    [chip, setCanEdit, viewTransfer],
  );

  if (chip.__typename === 'AssignedChip' && chip.latestContactHistoryEntry) {
    if (chip.transfer && viewTransfer) {
      return <AcceptTransfer chip={chip} transfer={chip.transfer} pet={null} reload={reload} canEdit={false} />;
    }

    // Button to view transfer request
    const transferRequestButton = chip.transfer ? (
      <OutlineButton onClick={() => setViewTransfer(true)}>View transfer request</OutlineButton>
    ) : null;

    // The chip could be assigned to the user but does not have a pet yet, in that case, we should show the
    // pet information view
    if (!chip.pet) {
      return (
        <>
          <FinalizeTransferView
            chipShortId={chip.shortId}
            contactInfo={chip.latestContactHistoryEntry.contactInfo}
            onContactInfoUpdated={reload}
            onPetInfoUpdated={reload}
          />
          {transferRequestButton}
        </>
      );
    }

    return editing ? (
      <EditAssignedChipView
        chip={chip}
        onDone={(didSubmit) => {
          setEditing(false);
          if (didSubmit) {
            reload();
          }
        }}
      />
    ) : (
      <>
        <AssignedChipView chip={chip} reload={reload} />
        {transferRequestButton}
      </>
    );
  } else if (chip.__typename === 'StrangerChip' && isStrangerChipWithTransfer(chip)) {
    const { transfer } = chip;
    const resolvedPetId = transfer.pet?.id;

    if (!resolvedPetId) {
      return (
        <FinalizeTransferView
          chipShortId={chip.shortId}
          contactInfo={transfer.contactInfo}
          onContactInfoUpdated={reload}
          onPetInfoUpdated={reload}
        />
      );
    } else {
      return <PendingTransferView chip={chip} petId={resolvedPetId} reload={reload} />;
    }
  }

  return <Redirect to={Links.manageHome} />;
}

const ChipDetails = withBackBehavior('close', ({ forManage }: { forManage?: boolean }) => {
  const { id } = useQueryParams<ChipDetailsQueryParams>();

  const transformData = useMemo(() => (data: gqlTypes.chipDetails) => data.chip, []);

  if (!id) {
    return <NotFound />;
  }

  return (
    <QueryBasedPage<gqlTypes.chipDetails, gqlTypes.chipDetailsVariables, gqlTypes.baseChip>
      query={chipDetailsQuery}
      title={id}
      transformData={transformData}
      options={{ variables: { id } }}
      backLink={forManage ? '/manage' : undefined}
    >
      {(childProps) => <ChipDetailsMain {...childProps} />}
    </QueryBasedPage>
  );
});

export default ChipDetails;
