import { useLocation } from 'react-router-dom';
import * as qs from 'querystring';
import { useMemo } from 'react';

export function useQueryParams<T = any>() {
  const location = useLocation();

  const record: Record<string, any> = useMemo(() => {
    const str = location.search ? location.search.substr(1) : '';
    if (!str) {
      return {};
    }
    const obj = qs.parse(str);
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        return [key, Array.isArray(value) ? value[0] : value];
      }),
    );
  }, [location]);

  return record as T;
}
