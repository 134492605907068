import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { lbsToKg } from '../../../lib/utils';
import { gqlTypes } from '../../../types/gqlTypes';
import { useUpdatePet } from '../../lib/mutations';
import { manageHomeQuery } from '../../ManageHome/ManageHome.graphql';
import ContactInfoForm from '../../RegisterChip/ContactInfoStep/ContactInfoForm';
import PetInfoForm, { petFormValuesFromPet, PetInfo } from '../../RegisterChip/PetInfoStep/PetInfoForm';
import { formValuesFromGql } from '../ChipDetails/ContactInfoForm/ContactInfoForm';
import styles from './EditPetAndContactInfo.module.scss';

export interface EditPetAndContactInfoProps {
  chipId: string;
  pet: gqlTypes.basePet | undefined;
  initialContactInfo?: gqlTypes.contactInfo;
  onDone(petOwnershipWasTransferred: boolean): void;
}

export default function EditPetAndContactInfo({ chipId, initialContactInfo, pet, onDone }: EditPetAndContactInfoProps) {
  const [updatePet] = useUpdatePet();

  const [updatePetInput, setUpdatePetInput] = useState<gqlTypes.UpdatePetInput | null>(null);

  const [petFormValues, setPetFormValues] = useState<PetInfo | null>(null);
  const [petFormIsValid, setPetFormIsValid] = useState(true);
  const petFormInitialValues = useMemo(() => pet && petFormValuesFromPet(pet), [pet]);

  useEffect(() => {
    if (!pet || !petFormValues) {
      return;
    }

    const newInput: gqlTypes.UpdatePetInput = {
      id: pet.id,
    };
    const values = petFormValues;

    if (values.name !== pet.name) {
      newInput.name = values.name;
    }

    let birthDate: DateTime | undefined;
    try {
      if (petFormValues.birthDate) {
        birthDate = DateTime.fromISO(petFormValues.birthDate);
      }
    } catch (err) {}

    if (!birthDate) {
      return;
    }

    const newDayOfBirth = birthDate.day;
    if (newDayOfBirth !== pet.dayOfBirth) {
      newInput.dayOfBirth = newDayOfBirth;
    }

    const newMonthOfBirth = birthDate.month;
    if (newMonthOfBirth !== pet.monthOfBirth) {
      newInput.monthOfBirth = newMonthOfBirth;
    }

    const newYearOfBirth = birthDate.year;
    if (newYearOfBirth !== pet.yearOfBirth) {
      newInput.yearOfBirth = newYearOfBirth;
    }

    if (values.weight !== petFormInitialValues?.weight) {
      const newWeight = lbsToKg(Number(values.weight));
      newInput.weight = newWeight;
    }

    if (values.breedId !== pet.breed?.id) {
      newInput.breedId = values.breedId;
    }

    if (values.gender !== pet.gender) {
      newInput.gender = values.gender;
    }

    const inputIfChanged = Object.keys(newInput).filter((key) => key !== 'id').length > 0 ? newInput : null;

    setUpdatePetInput(inputIfChanged);
  }, [pet, petFormValues, petFormInitialValues]);

  const initialContactInfoFormValues = useMemo(
    () => (initialContactInfo ? formValuesFromGql(initialContactInfo) : undefined),
    [initialContactInfo],
  );

  return (
    <div className={styles.container}>
      {pet && (
        <div>
          <h4>Pet Information</h4>
          <PetInfoForm
            initialValues={petFormInitialValues}
            onValuesChanged={setPetFormValues}
            onIsValidChanged={setPetFormIsValid}
          />
        </div>
      )}
      <div>
        {pet && <h4>Contact Information</h4>}
        <ContactInfoForm
          initialValues={initialContactInfoFormValues}
          chipShortId={chipId}
          disabled={!petFormIsValid}
          submitLabel="Save"
          mutationOptions={{ refetchQueries: [{ query: manageHomeQuery }] }}
          allowPrimaryEmailEdit={true}
          preSubmit={
            updatePetInput
              ? () => {
                  // Updating the pet needs to happen before submitting contact info in case they are submitting the
                  // contact info with a change in primary email, which transfers the pet away from themselves. After
                  // the transfer happens, they would no longer have permission to edit the pet.
                  return updatePet({
                    variables: {
                      input: updatePetInput,
                    },
                    refetchQueries: [{ query: manageHomeQuery }],
                  });
                }
              : undefined
          }
          onSuccess={async (contactInfoInput) => {
            const petOwnershipWasTransferred = !!contactInfoInput?.updateChipContactInfo.petOwnershipWasTransferred;

            onDone(petOwnershipWasTransferred);
          }}
        />
      </div>
    </div>
  );
}
