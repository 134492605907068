import React from 'react';
import { gqlTypes } from '../../../types/gqlTypes';
import RadioField from '../../../components/form/RadioField';
import { ErrorMessage } from 'formik';

export interface GenderSelectProps {
  name: string;
}

export default function GenderSelect({ name }: GenderSelectProps) {
  return (
    <>
      <div className="form-group">
        <div className="form-field">
          <RadioField
            label="Gender"
            name={name}
            options={[
              {
                label: `Male`,
                value: gqlTypes.Gender.MALE,
              },
              {
                label: `Female`,
                value: gqlTypes.Gender.FEMALE,
              },
            ]}
          />
          <ErrorMessage name={name} component="div" className="form-field__error" />
        </div>
      </div>
    </>
  );
}
