import React from 'react';

import styles from './ChipListHeader.module.scss';

export default function ChipListHeader() {
  return (
    <div className={styles.main}>
      <div>Chip</div>
      <div>Owner</div>
    </div>
  );
}
