import React, { useState, useRef } from 'react';
import { Links } from '../../lib/constants';
import { useAppViewContext } from '../lib/AppViewContext';

import qrcodeScanner from '../../assets/images/qrcode-scanner.svg';
import magnifyingGlass from '../../assets/images/magnifying-glass.svg';
import closeX from '../../assets/images/close-x.svg';
import backImage from '../../assets/images/back.svg';

import styles from './SearchBar.module.scss';

export interface SearchBarProps {
  onQuerySubmitted(query: string): void;
  onQueryCleared(): void;
  searchedQuery: string | null;
  showBackButton?: boolean;
}

export default function SearchBar({ onQuerySubmitted, onQueryCleared, searchedQuery, showBackButton }: SearchBarProps) {
  const [query, setQuery] = useState('');

  const isDesktop = useAppViewContext().isDesktop;

  const inputRef = useRef<HTMLInputElement>(null);

  let action: 'search' | 'scan' | 'cancel';
  if (query.length > 0 || isDesktop) {
    action = query && query === searchedQuery ? 'cancel' : 'search';
  } else {
    action = 'scan';
  }

  const actionImageMap: { [K in typeof action]: string } = {
    scan: qrcodeScanner,
    cancel: closeX,
    search: magnifyingGlass,
  };
  const actionImage = actionImageMap[action];

  const doSearch = () => {
    if (!query) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      onQuerySubmitted(query);
    }
  };

  return (
    <form
      className={styles.main}
      onSubmit={(e) => {
        e.preventDefault();
        doSearch();
      }}
    >
      {showBackButton && (
        <img
          className={styles.backButton}
          alt="Go back"
          src={backImage}
          onClick={() => window.open(Links.closeAppView())}
        />
      )}
      <input
        type="text"
        placeholder="Search"
        value={query}
        ref={inputRef}
        onChange={(e) => setQuery(e.currentTarget.value.trim())}
      />
      <div
        className={styles.action}
        onClick={() => {
          if (action === 'search') {
            doSearch();
          } else if (action === 'scan') {
            window.open(Links.scanChip);
          } else if (action === 'cancel') {
            setQuery('');
            onQueryCleared();
          }
        }}
      >
        <img src={actionImage} alt="The action" />
      </div>
    </form>
  );
}
