import React from 'react';

import ChipSearchBox from '../components/ChipSearchBox';

import styles from './SearchHero.module.scss';

export default function SearchHero() {
  return (
    <div className={styles.main}>
      <div className={styles.contents}>
        <h1>
          F<span className="lowercase">i</span> Nano - A new <br />
          generation of microchip
        </h1>
        <ChipSearchBox />
      </div>
    </div>
  );
}
