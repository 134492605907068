import React from 'react';
import { AssignedChip } from '../lib/types';
import { Links } from '../../lib/constants';
import EditPetAndContactInfo from '../components/EditPetAndContactInfo/EditPetAndContactInfo';
import { useAppViewContext } from '../lib/AppViewContext';
import { useHistory } from 'react-router-dom';

export interface EditAssignedChipViewProps {
  chip: AssignedChip;
  onDone(didSubmit: boolean): void;
}

export default function EditAssignedChipView({ chip, onDone }: EditAssignedChipViewProps) {
  const history = useHistory();
  const { isDesktop } = useAppViewContext();

  return (
    <div>
      <EditPetAndContactInfo
        chipId={chip.id}
        initialContactInfo={chip.latestContactHistoryEntry?.contactInfo}
        pet={chip.pet ?? undefined}
        onDone={(petOwnershipWasTransferred) => {
          if (petOwnershipWasTransferred) {
            if (isDesktop) {
              history.push('/manage');
            } else {
              window.open(Links.closeAppView());
            }
          } else {
            onDone(true);
          }
        }}
      />
    </div>
  );
}
