import React, { useEffect } from 'react';

/**
 * Sets a custom background color for the whole site on mount, clear it on unmount.
 */
export function CustomBackgroundColor({ color }: { color: string }) {
  useEffect(() => {
    document.body.style.backgroundColor = color;
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, [color]);
  return <React.Fragment />;
}
