import React from 'react';
import JoinEmailForm from './JoinEmailForm';
import { NavLinks, Links } from '../../../lib/constants';
import InternalOrExternalLink from '../../utils/InternalOrExternalLink';
import classNames from 'classnames';

import appStoreImage from '../../../assets/images/footer/app_store.png';
import facebookImage from '../../../assets/images/footer/facebook.svg';
import instagramImage from '../../../assets/images/footer/instagram.svg';
import pinterestImage from '../../../assets/images/footer/pinterest.svg';
import playStoreImage from '../../../assets/images/footer/play_store.png';
import twitterImage from '../../../assets/images/footer/twitter.svg';

import styles from './Footer.module.scss';

const Images = {
  appStore: appStoreImage,
  facebook: facebookImage,
  instagram: instagramImage,
  pinterest: pinterestImage,
  playStore: playStoreImage,
  twitter: twitterImage,
};

const Footer = () => {
  return (
    <footer className={styles.main}>
      <div className={styles.contents}>
        <div className={styles.left}>
          <div>
            <JoinEmailForm />
            <nav className={styles.nav}>
              {NavLinks.map(({ path, text }, i) => {
                return (
                  <InternalOrExternalLink to={path} key={i}>
                    {text}
                  </InternalOrExternalLink>
                );
              })}
            </nav>
            <h6 className={classNames(styles.copyright, styles.desktopCopyright)}>&copy; &nbsp; Barking Labs</h6>
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.hours}>
              <h5>Hours of Operation</h5>
              <p>
                Monday–Friday
                <br />
                9am–5pm EST
              </p>
            </div>
            <div className={styles.contact}>
              <a href="https://support.tryfi.com/hc/en-us" className={styles.button}>
                Get in Touch
              </a>
            </div>
            <div className={styles.social}>
              <a href={Links.facebook}>
                <img alt="Facebook" src={Images.facebook} />
              </a>
              <a href={Links.instagram}>
                <img alt="Instagram" src={Images.instagram} />
              </a>
              <a href={Links.instagram}>
                <img alt="Twitter" src={Images.twitter} />
              </a>
              <a href={Links.pinterest}>
                <img alt="Pinterest" src={Images.pinterest} />
              </a>
            </div>
            <span className={styles.appstores}>
              <a href={Links.iosAppStore}>
                <img alt="Fi on the app store" src={Images.appStore} />
              </a>
              <a href={Links.androidAppStore}>
                <img alt="Fi on the play store" src={Images.playStore} />
              </a>
            </span>
            <h6 className={classNames(styles.copyright, styles.mobileCopyright)}>&copy; &nbsp; Barking Labs</h6>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
