import React from 'react';
import { gqlTypes } from '../../../types/gqlTypes';

import styles from './ContactInfoSourceBadge.module.scss';

const SHELTERLUV_SOURCES = [
  gqlTypes.ChipContactHistoryEntrySource.SHELTERLUV_BACKFILL,
  gqlTypes.ChipContactHistoryEntrySource.SHELTERLUV_INCREMENTAL,
];

interface ContactInfoSourceBadgeProps {
  contactInfoSource: gqlTypes.ChipContactHistoryEntrySource;
}

export default function ContactInfoSourceBadge({ contactInfoSource }: ContactInfoSourceBadgeProps) {
  if (!SHELTERLUV_SOURCES.includes(contactInfoSource)) {
    return null;
  }

  return <div className={styles.contactInfoSource}>Registered via Shelterluv</div>;
}
