import { gqlTypes } from '../../types/gqlTypes';

export interface CommonChip {
  shortId: string;
  serialNumber: string;
}

export type AssignedChip = Extract<gqlTypes.baseChip, { __typename: 'AssignedChip' }>;

export type StrangerChip = Extract<gqlTypes.baseChip, { __typename: 'StrangerChip' }>;

// Defines a StrangerChip type with a transfer field that is defined, non-null, and non-optional.
// https://stackoverflow.com/a/53050575
export type StrangerChipWithTransfer = {
  [K in keyof StrangerChip]-?: K extends 'transfer' ? Exclude<StrangerChip[K], null> : StrangerChip[K];
};

export function isStrangerChipWithTransfer(chip: StrangerChip): chip is StrangerChipWithTransfer {
  return !!chip.transfer;
}

export interface AppViewLinkState {
  forceBackBehavior?: 'close' | 'back';
}
