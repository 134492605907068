import { gql, useQuery } from '@apollo/client';
import { renderFragments, basePetFragment } from '../../lib/fragments';
import { gqlTypes } from '../../types/gqlTypes';

const strangerPetDetailsQuery = gql`
  query strangerPetDetails($id: ID!) {
    strangerPet(id: $id) {
      ...basePet
    }
  }

  ${renderFragments(basePetFragment)}
`;

export function usePet(petId: string) {
  const { data } = useQuery<gqlTypes.strangerPetDetails, gqlTypes.strangerPetDetailsVariables>(
    strangerPetDetailsQuery,
    {
      variables: {
        id: petId,
      },
    },
  );

  return { pet: data?.strangerPet };
}
