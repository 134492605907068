import { useMutation, MutationTuple, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AppState, store } from '../../lib/store';
import { logout, signup, UserAlreadyExistsError } from '../../lib/authentication';
import { registerChipResellerQuery } from './ApplicationFormV2.graphql';
import { gqlTypes } from '../../types/gqlTypes';
import { phoneRegex, saneEmail, isOptionalUrl, userChipResellerQuery } from '../../lib/utils';
import FormField from '../../components/form/FormField';
import SingleTextFieldFormGroup from '../../components/form/SingleTextFieldFormGroup';
import StateSelectField from '../../components/form/StateSelectField';
import checkmark from '../../assets/images/checkmark.svg';

import styles from './ApplicationFormV2.module.scss';
import ModalConfirm from '../../appview/components/modals/ModalConfirm';
import { checkEstablishmentRegistration, EstablishmentAlreadyExistsError, validateAddress } from '../../lib/chipsApi';

interface FormValues {
  resellerTypes: gqlTypes.ResellerType[] | null;
  petInterestTypes: gqlTypes.ResellerPetInterest[] | null;
  name: string;
  website: string | undefined;
  estimatedMonthlyChipRegistrations: string | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  line1: string;
  line2: string | undefined;
  city: string;
  zipcode: string;
  state: string | null;
  shippingLine1: string | undefined;
  shippingLine2: string | undefined;
  shippingCity: string | undefined;
  shippingZipcode: string | undefined;
  shippingState: string | null | undefined;
  password: string | undefined;
}

const initialFormValues: FormValues = {
  resellerTypes: null,
  petInterestTypes: null,
  name: '',
  website: '',
  estimatedMonthlyChipRegistrations: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  line1: '',
  line2: undefined,
  city: '',
  zipcode: '',
  state: null,
  shippingLine1: undefined,
  shippingLine2: undefined,
  shippingCity: undefined,
  shippingZipcode: undefined,
  shippingState: undefined,
  password: '',
};

// The address entered during the application process is also used as the shipping address for Nano boxes.
// Our 3PL has a max length of 40 characters for address fields.
const ADDRESS_LINE_MAX_LENGTH = 40;

function getShippingAddressForApplication(values: FormValues, useSameAddressForShipping: boolean) {
  return {
    line1: useSameAddressForShipping ? values.line1 : values.shippingLine1!,
    line2: useSameAddressForShipping ? values.line2 : values.shippingLine2!,
    city: useSameAddressForShipping ? values.city : values.shippingCity!,
    state: useSameAddressForShipping ? values.state! : values.shippingState!,
    zip: useSameAddressForShipping ? values.zipcode : values.shippingZipcode!,
    country: 'US',
  };
}

function formattedAddress(address: gqlTypes.Address) {
  return (
    <>
      {address.line1 && <div>{address.line1}</div>}
      {address.line2 && <div>{address.line2}</div>}
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
    </>
  );
}

async function submitApplication(
  values: FormValues,
  mutation: MutationTuple<gqlTypes.registerChipResellerV2, gqlTypes.registerChipResellerV2Variables>[0],
  useSameAddressForShipping: boolean,
) {
  const loggedIn = !!store.getState().session;
  if (!loggedIn) {
    await signup(values.email, values.password!, values.name, '');
  }

  await mutation({
    variables: {
      input: {
        name: values.name,
        types: values.resellerTypes || [],
        petInterestTypes: values.petInterestTypes || [],
        contactInfo: {
          firstName: values.firstName,
          lastName: values.lastName,
          primaryEmail: values.email,
          primaryPhone: values.phoneNumber,
          website: values.website,
          estimatedMonthlyChipRegistrations: values.estimatedMonthlyChipRegistrations,
          line1: values.line1,
          line2: values.line2,
          city: values.city,
          zipcode: values.zipcode,
          state: values.state!,
          country: `US`,
        },
        shippingAddress: getShippingAddressForApplication(values, useSameAddressForShipping),
      },
    },
  });
}

interface MultiSelectOption {
  label: string;
  value: string;
}

interface MultiSelectComponentProps {
  name: string;
  options: MultiSelectOption[];
  setFieldValue: (field: string, value: string[], shouldValidate: boolean) => void;
  allowMultiple?: boolean;
}

const MultiSelectComponent: React.FC<MultiSelectComponentProps> = ({
  name,
  options,
  setFieldValue,
  allowMultiple = true,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectionChange = (value: string) => {
    let updatedSelectedOptions: string[];

    if (allowMultiple) {
      updatedSelectedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((item) => item !== value)
        : [...selectedOptions, value];
    } else {
      updatedSelectedOptions = selectedOptions.includes(value) ? [] : [value];
    }

    setSelectedOptions(updatedSelectedOptions);
    setFieldValue(name, updatedSelectedOptions, false);
  };

  return (
    <div className={styles.multiSelectContainer}>
      {options.map((option) => (
        <div
          key={option.value}
          className={classNames(styles.multiSelectOption, {
            [styles.multiSelectOptionSelected]: selectedOptions.includes(option.value),
          })}
          onClick={() => handleSelectionChange(option.value)}
        >
          <img
            className={classNames(styles.multiSelectImage, {
              [styles.multiSelectImageSelected]: selectedOptions.includes(option.value),
            })}
            src={checkmark}
            alt="checkmark"
          />
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default function ApplicationFormV2() {
  const session = useSelector((state: AppState) => state.session);
  const isLoggedIn = !!session;

  const [mutation, { error, data }] = useMutation<
    gqlTypes.registerChipResellerV2,
    gqlTypes.registerChipResellerV2Variables
  >(registerChipResellerQuery);
  const { data: currentUserResellerData } = useQuery<gqlTypes.userChipReseller>(userChipResellerQuery, {
    skip: !isLoggedIn,
  });
  const currentUserIsReseller = !!currentUserResellerData?.currentUser.chipReseller;
  const [useSameAddressForShipping, setUseSameAddressForShipping] = useState(true);
  const [showSignupErrorModal, setShowSignupErrorModal] = useState(false);
  const [showEstablishmentErrorModal, setShowEstablishmentErrorModal] = useState(false);
  const [showAddressValidationModal, setShowAddressValidationModal] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<gqlTypes.Address | null>(null);
  const [skipAddressValidation, setSkipAddressValidation] = useState(false);

  if (data?.registerChipResellerV2.id && !error) {
    if (!isLoggedIn) {
      return <Redirect to={'/login'} />;
    }

    return (
      <div className={classNames(styles.successContainer, 'success-container')}>
        <div className={styles.successTitle}>WELCOME TO Fi NANO</div>
        <div className={styles.successSubtitle}>Success! You just leveled up your microchip operation.</div>
        <div className={styles.successSubtitle}>We’ll email you confirmation when your sample box ships.</div>
        <button
          type="submit"
          className={classNames(styles.submitButton, styles.successSubmitButton)}
          onClick={() => {
            window.location.href = 'https://shop.tryfi.com/products/nano-20';
          }}
        >
          Get more microchips
        </button>
      </div>
    );
  } else if (currentUserIsReseller) {
    return <Redirect to="/manage" />;
  }

  let initialValues = initialFormValues;
  if (session) {
    initialValues = { ...initialValues, email: session.email };
  }

  const validationSchema = yup.object({
    resellerTypes: yup
      .mixed<gqlTypes.ResellerType[]>()
      .required(`Please select whether you are a vet, rescue, or breeder organization`),
    name: yup.string().required(`Please enter a name`),
    phoneNumber: yup
      .string()
      .matches(phoneRegex, `Please enter a valid phone number`)
      .required(`Please enter a phone number`),
    website: yup
      .string()
      .test('is_url', 'Please enter a valid website', isOptionalUrl)
      .required('Please enter a website'),
    line1: yup.string().required(`Please enter an address`).max(ADDRESS_LINE_MAX_LENGTH),
    line2: yup.string().max(ADDRESS_LINE_MAX_LENGTH),
    city: yup.string().required(`Please enter your city`).max(ADDRESS_LINE_MAX_LENGTH),
    zipcode: yup.string().required(`Please enter your zip code`),
    state: yup
      .string()
      .required(`Please select a state`)
      .nullable()
      .test('required', `Please enter a state`, (x) => x !== null),
    shippingLine1: yup
      .string()
      .max(ADDRESS_LINE_MAX_LENGTH)
      .when([], {
        is: () => !useSameAddressForShipping,
        then: yup.string().required('Please enter an address'),
      }),
    shippingLine2: yup.string().max(ADDRESS_LINE_MAX_LENGTH),
    shippingCity: yup
      .string()
      .max(ADDRESS_LINE_MAX_LENGTH)
      .when([], {
        is: () => !useSameAddressForShipping,
        then: yup.string().required('Please enter your city'),
      }),
    shippingZipcode: yup.string().when([], {
      is: () => !useSameAddressForShipping,
      then: yup.string().required('Please enter your zip code'),
    }),
    shippingState: yup
      .string()
      .nullable()
      .test('required', 'Please enter a state', (x) => x !== null)
      .when([], {
        is: () => !useSameAddressForShipping,
        then: yup.string().required('Please select a state'),
      }),
    petInterestTypes: yup
      .mixed<gqlTypes.ResellerPetInterest[]>()
      .required(`Plese select which pets you are planning to microchip`),
    firstName: yup.string().when([], {
      is: () => !isLoggedIn,
      then: yup.string().required('Please enter your first name'),
    }),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .test('sane_email', 'Please enter a valid email address', saneEmail)
      .when([], {
        is: () => !isLoggedIn,
        then: yup.string().required('Please enter an email address'),
      }),
    password: yup.string().when([], {
      is: () => !isLoggedIn,
      then: yup.string().required('Please enter a password'),
    }),
  });

  return (
    <div className={styles.main}>
      <div className={styles.header}>Join the Fi Nano Network</div>
      <Formik<FormValues>
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          setSubmitting(true);
          setStatus(null);
          setSuggestedAddress(null);
          try {
            await checkEstablishmentRegistration(values.name, values.city);

            if (!skipAddressValidation) {
              const addressValidationData = await validateAddress(
                getShippingAddressForApplication(values, useSameAddressForShipping),
              );

              if (addressValidationData.status !== 'VERIFIED') {
                if (addressValidationData.matchedAddress?.line1) {
                  setSuggestedAddress(addressValidationData.matchedAddress);
                }
                setShowAddressValidationModal(true);
                setSubmitting(false);
                return;
              }
            }

            await submitApplication(values, mutation, useSameAddressForShipping);
          } catch (err) {
            if (err instanceof UserAlreadyExistsError) {
              setShowSignupErrorModal(true);
            } else if (err instanceof EstablishmentAlreadyExistsError) {
              setShowEstablishmentErrorModal(true);
            } else {
              setStatus({ errorMessage: (err as any).message });
            }
          } finally {
            setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ handleSubmit, status, isSubmitting, setFieldValue, values, setValues }) => (
          <form onSubmit={handleSubmit} className="form" autoComplete="on">
            <div className="form-group">
              <div className={classNames('form-field form-field--labelled', styles.vetRescueField)}>
                <div className={styles.multiSelectApplicationContainer}>
                  <div className={styles.groupTitle}>Type of establishment</div>
                  <MultiSelectComponent
                    name="resellerTypes"
                    options={[
                      {
                        label: 'Vet',
                        value: gqlTypes.ResellerType.VET,
                      },
                      {
                        label: 'Rescue',
                        value: gqlTypes.ResellerType.RESCUE,
                      },
                      {
                        label: 'Breeder',
                        value: gqlTypes.ResellerType.BREEDER,
                      },
                    ]}
                    setFieldValue={setFieldValue}
                    allowMultiple={false}
                  />
                </div>
                <ErrorMessage name="resellerTypes" component="div" className="form-field__error" />
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.groupTitle}>Establishment</div>
              <SingleTextFieldFormGroup name="name" placeholder="Name of establishment" />
              <SingleTextFieldFormGroup name="phoneNumber" type="tel" placeholder="Phone number" autoComplete="tel" />
              <SingleTextFieldFormGroup name="website" placeholder="Website or social media" />
            </div>

            <div className={styles.formGroup}>
              <div className={styles.groupTitle}>Address of establishment</div>
              <SingleTextFieldFormGroup name="line1" placeholder="Address" autoComplete="street-address" />
              <SingleTextFieldFormGroup name="line2" placeholder="Apt/suite/other" autoComplete="address-line-2" />
              <SingleTextFieldFormGroup name="city" placeholder="City" autoComplete="address-level2" />
              <div className={classNames('form-group', styles.stateAndZipFields)}>
                <div className={styles.stateField}>
                  <StateSelectField name="state" />
                  <ErrorMessage name="state" component="div" className="form-field__error" />
                </div>
                <div className="form-field">
                  <Field type="text" name="zipcode" placeholder="Zip" autoComplete="postal-code" />
                  <ErrorMessage name="zipcode" component="div" className="form-field__error" />
                </div>
              </div>
              <div className={styles.shippingCheckbox}>
                <input
                  type="checkbox"
                  checked={useSameAddressForShipping}
                  onChange={() => {
                    setUseSameAddressForShipping(!useSameAddressForShipping);
                  }}
                />
                <span onClick={() => setUseSameAddressForShipping(!useSameAddressForShipping)}>
                  Use the same address for shipping
                </span>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className="form-group">
                <div className={classNames('form-field form-field--labelled', styles.petInterestField)}>
                  <div className={styles.multiSelectApplicationContainer}>
                    <div className={styles.groupTitle}>What type of pets will you microchip? Select all that apply</div>
                    <MultiSelectComponent
                      name="petInterestTypes"
                      options={[
                        {
                          label: 'Dogs',
                          value: gqlTypes.ResellerPetInterest.DOGS,
                        },
                        {
                          label: 'Cats',
                          value: gqlTypes.ResellerPetInterest.CATS,
                        },
                        {
                          label: 'Other',
                          value: gqlTypes.ResellerPetInterest.OTHER,
                        },
                      ]}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <ErrorMessage name="petInterestTypes" component="div" className="form-field__error" />
                </div>
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.groupTitle}>How many microchips a month do you register?</div>
              <SingleTextFieldFormGroup name="estimatedMonthlyChipRegistrations" placeholder="Estimated amount" />
            </div>

            {!useSameAddressForShipping && (
              <div className={styles.formGroup}>
                <div className={styles.groupTitle}>Shipping address</div>
                <SingleTextFieldFormGroup name="shippingLine1" placeholder="Address" autoComplete="street-address" />
                <SingleTextFieldFormGroup
                  name="shippingLine2"
                  placeholder="Apt/suite/other"
                  autoComplete="address-line-2"
                />
                <SingleTextFieldFormGroup name="shippingCity" placeholder="City" autoComplete="address-level2" />
                <div className={classNames('form-group', styles.stateAndZipFields)}>
                  <div className={styles.stateField}>
                    <StateSelectField name="shippingState" />
                    <ErrorMessage name="shippingState" component="div" className="form-field__error" />
                  </div>
                  <div className="form-field">
                    <Field type="text" name="shippingZipcode" placeholder="Zip" autoComplete="postal-code" />
                    <ErrorMessage name="shippingZipcode" component="div" className="form-field__error" />
                  </div>
                </div>
              </div>
            )}

            {!isLoggedIn && (
              <div className={styles.formGroup}>
                <div className={styles.groupTitle}>Create an account</div>
                <SingleTextFieldFormGroup name="firstName" placeholder="Your name" autoComplete="given-name" />
                <div className="form-group">
                  <FormField type="email" name="email" placeholder={'Email address'} autoComplete="email" />
                </div>
                <SingleTextFieldFormGroup
                  name="password"
                  placeholder="Password"
                  type="password"
                  autoComplete="new-password"
                />
              </div>
            )}

            {isLoggedIn && <div className={styles.groupTitle}>You are logged in as {session?.email}.</div>}

            <div className="form-group form-group--action">
              <div className="form-field">
                <button type="submit" disabled={isSubmitting} className={styles.submitButton}>
                  Submit
                </button>
                {status && status.errorMessage && <div className="form-field__error">{status.errorMessage}</div>}
              </div>
            </div>

            <ModalConfirm
              className={styles.addressValidationModal}
              open={showAddressValidationModal}
              closeOnDocumentClick={true}
              closeOnEscape={true}
              message={
                <>
                  <div className={styles.modalTitle}>Verify your shipping address</div>
                  {!suggestedAddress && (
                    <div className={styles.modalSubtitle}>
                      The shipping address you entered is not recognized by the United States Postal Service. Please
                      verify your address to ensure accurate delivery of your order.
                    </div>
                  )}
                  {suggestedAddress && (
                    <div className={styles.modalSubtitle}>
                      To ensure accurate delivery, consider using the suggested address below.
                    </div>
                  )}
                  {suggestedAddress && (
                    <div className={styles.shippingAddress}>
                      <div>
                        <div className={styles.shippingAddressTitle}>We suggest:</div>
                        {formattedAddress(suggestedAddress)}
                      </div>
                      {suggestedAddress && (
                        <button
                          className={styles.useThisAddressButton}
                          onClick={() => {
                            const valuesToSubmit: any = {};
                            if (useSameAddressForShipping) {
                              valuesToSubmit.line1 = suggestedAddress.line1;
                              valuesToSubmit.line2 = suggestedAddress.line2 || undefined;
                              valuesToSubmit.city = suggestedAddress.city;
                              valuesToSubmit.state = suggestedAddress.state;
                              valuesToSubmit.zipcode = suggestedAddress.zip;
                            } else {
                              valuesToSubmit.shippingLine1 = suggestedAddress.line1;
                              valuesToSubmit.shippingLine2 = suggestedAddress.line2 || undefined;
                              valuesToSubmit.shippingCity = suggestedAddress.city;
                              valuesToSubmit.shippingState = suggestedAddress.state;
                              valuesToSubmit.shippingZipcode = suggestedAddress.zip;
                            }
                            setValues((prevValues) => ({
                              ...prevValues,
                              ...valuesToSubmit,
                            }));
                            setSkipAddressValidation(true);
                            handleSubmit();
                            setShowAddressValidationModal(false);
                          }}
                        >
                          Use suggested address
                        </button>
                      )}
                    </div>
                  )}
                  <div className={styles.shippingAddress}>
                    <div>
                      <div className={styles.shippingAddressTitle}>You entered:</div>
                      {formattedAddress(getShippingAddressForApplication(values, useSameAddressForShipping))}
                    </div>
                    {suggestedAddress && (
                      <button
                        className={styles.editAddressButton}
                        onClick={() => {
                          setSkipAddressValidation(true);
                          handleSubmit();
                          setShowAddressValidationModal(false);
                        }}
                      >
                        Use my address
                      </button>
                    )}
                  </div>
                  {!suggestedAddress && (
                    <div className={styles.addressValidationButtons}>
                      <button
                        className={styles.useThisAddressButton}
                        onClick={() => {
                          setSkipAddressValidation(true);
                          handleSubmit();
                          setShowAddressValidationModal(false);
                        }}
                      >
                        Use this address
                      </button>
                      <button
                        className={styles.editAddressButton}
                        onClick={() => {
                          setShowAddressValidationModal(false);
                        }}
                      >
                        Edit address
                      </button>
                    </div>
                  )}
                </>
              }
              hideActions={true}
              onClose={() => setShowAddressValidationModal(false)}
            />

            <ModalConfirm
              className={styles.errorModal}
              open={showEstablishmentErrorModal}
              closeOnDocumentClick={true}
              closeOnEscape={true}
              message={
                <>
                  <div className={styles.modalTitle}>Your establishment is already registered.</div>
                  <div className={styles.modalSubtitle}>
                    Check to see if someone at your organization has already signed up for Fi Nano.
                  </div>
                </>
              }
              acceptText="Log in to a Fi account"
              rejectText="Contact Support"
              onClose={() => setShowEstablishmentErrorModal(false)}
              onAccept={() => {
                setShowEstablishmentErrorModal(false);
                logout();
                window.location.href = '/login';
              }}
              onReject={() => {
                setShowEstablishmentErrorModal(false);
                window.open('https://support.tryfi.com/hc/en-us', '_blank');
              }}
            />

            <ModalConfirm
              className={styles.errorModal}
              open={showSignupErrorModal}
              closeOnDocumentClick={true}
              closeOnEscape={true}
              message={
                <>
                  <div className={styles.modalTitle}>This email address is already registered.</div>
                  <div className={styles.modalSubtitle}>
                    Check to see if someone at your organization has already signed up for Fi Nano.
                  </div>
                </>
              }
              acceptText="Log in to a Fi account"
              rejectText="Contact Support"
              onClose={() => setShowSignupErrorModal(false)}
              onAccept={() => {
                setShowSignupErrorModal(false);
                window.location.href = '/login';
              }}
              onReject={() => {
                setShowSignupErrorModal(false);
                window.open('https://support.tryfi.com/hc/en-us', '_blank');
              }}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}
