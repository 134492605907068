import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './UpgradeStepV2.module.scss';
import RegisterChipContextV2 from '../RegisterChipContextV2';
import { useQuery } from '@apollo/client';
import { gqlTypes } from '../../../types/gqlTypes';
import Loading from '../../../components/Loading';
import { Redirect } from 'react-router-dom';
import { chipDetailsQuery } from '../../ChipDetails/ChipDetails.graphql';
import fiCollarImage from '../../../assets/images/fi-collar-small.png';
import locationTrackingImage from '../../../assets/images/location-tracking.png';
import locationTrackingGPSExperimentImage from '../../../assets/images/location-tracking-gps-experiment.png';
import gpsAvatarPinImage from '../../../assets/images/gps-avatar-pin.png';
import { ReactComponent as Star } from '../../../assets/images/star.svg';
import { ReactComponent as Checkmark } from '../../../assets/images/checkmark.svg';
import { ReactComponent as Dog } from '../../../assets/images/dog-head.svg';
import { ReactComponent as LocationPin } from '../../../assets/images/location.svg';
import { ReactComponent as Warranty } from '../../../assets/images/warranty.svg';
import getCustomerIO from '../../../lib/customerIO';
import { getShopURL } from '../../../lib/shopURL';
import { getFiGrowthBook } from '../../lib/growthbook';

const testimonials = [
  { text: 'The peace of mind I get is worth every penny', byline: '@myheartbeatsatmyfeet' },
  { text: 'I don’t worry about losing any of my six dogs now', byline: '@jeremygranthorton' },
  { text: 'Helped me get my escape artist back!', byline: 'Rachel D.' },
  { text: 'The best decision ever buying this GPS collar!!', byline: '@shirley2792' },
];

const TestimonialCarousel: React.FC = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % testimonials.length);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.testimonialCarousel}>
      <div className={styles.testimonialCard}>
        <div className={styles.testimonialStars}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={classNames({
              [styles.testimonial]: true,
              [styles.active]: index === current,
              [styles.prev]: index === (current - 1 + testimonials.length) % testimonials.length,
              [styles.next]: index === (current + 1) % testimonials.length,
              [styles.hidden]: ![
                current,
                (current - 1 + testimonials.length) % testimonials.length,
                (current + 1) % testimonials.length,
              ].includes(index),
            })}
          >
            <div>
              <div className={styles.testimonialText}>{testimonial.text}</div>
              <div className={styles.testimonialByline}>{testimonial.byline}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.dots}>
        {testimonials.map((_, index) => (
          <div key={index} className={classNames({ [styles.dot]: true, [styles.active]: index === current })} />
        ))}
      </div>
    </div>
  );
};

export default function UpgradeStep() {
  const { registerState, onStepFinished } = useContext(RegisterChipContextV2);

  // See if the current user already owns the chip or has an existing transfer request with a page
  const { data: chipDetails, loading: chipDetailsLoading } = useQuery<gqlTypes.chipDetails>(chipDetailsQuery, {
    variables: {
      id: registerState.chipId,
    },
    skip: !registerState.chipId,
    fetchPolicy: 'network-only',
  });

  const petInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.pet) {
      return chipDetails.chip.pet;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.pet) {
      return chipDetails.chip.transfer.pet;
    }

    return undefined;
  }, [chipDetails]);

  const contactInfo = useMemo(() => {
    if (chipDetails?.chip?.__typename === 'AssignedChip' && chipDetails.chip.latestContactHistoryEntry?.contactInfo) {
      return chipDetails.chip.latestContactHistoryEntry.contactInfo;
    }

    if (chipDetails?.chip?.__typename === 'StrangerChip' && chipDetails.chip.transfer?.contactInfo) {
      return chipDetails.chip.transfer.contactInfo;
    }

    return undefined;
  }, [chipDetails]);

  if (chipDetailsLoading) {
    return <Loading />;
  }

  if (!petInfo || !registerState.chipId) {
    return <Redirect to="/manage" />;
  }

  const logStepCompleted = () => {
    getCustomerIO()?.identify(registerState.primaryEmail, { nano: true });
    getCustomerIO()?.track('Nano Registration: Upgrade Step Completed', {
      email: registerState.primaryEmail,
      first_name: contactInfo?.firstName,
      last_name: contactInfo?.lastName,
      secondary_first_name: contactInfo?.secondaryFirstName,
      secondary_last_name: contactInfo?.secondaryLastName,
      address_line_1: contactInfo?.line1,
      address_line_2: contactInfo?.line2,
      address_city: contactInfo?.city,
      address_state: contactInfo?.state,
      address_zipcode: contactInfo?.zipcode,
      primary_phone: contactInfo?.primaryPhone,
      secondary_phone: contactInfo?.secondaryPhone,
      primary_email: contactInfo?.primaryEmail,
      chip_serial_number: registerState.chipDetails?.serialNumber,
      pet_name: petInfo?.name,
      pet_breed: petInfo.breed,
      pet_birthday: petInfo.dayOfBirth,
    });
  };
  const upsellV4ExperimentEnabled = getFiGrowthBook().getFeatureValue('nano-registration-upsell-v4-experiment', false);
  const gpsPulsingExperimentEnabled = getFiGrowthBook().getFeatureValue('nano-registration-upsell-gps-pulsing', false);

  const bulletPointContainer = (
    <div className={styles.bulletPointContainer}>
      <div className={styles.bulletPoint}>
        {upsellV4ExperimentEnabled ? <Dog className={styles.dogSVG} /> : <Checkmark />}
        {upsellV4ExperimentEnabled
          ? 'New dogs and puppies are much more likely to run away in their first 3 months.'
          : `See ${petInfo.name}’s location in real time`}
      </div>
      <div className={styles.bulletPoint}>
        {upsellV4ExperimentEnabled ? <LocationPin className={styles.locationPinSVG} /> : <Checkmark />}
        {upsellV4ExperimentEnabled
          ? "Microchips are a good first step for safety, but can't give you live location."
          : `Know instantly when they escape`}
      </div>
      <div className={styles.bulletPoint}>
        {upsellV4ExperimentEnabled ? <Warranty className={styles.warrantySVG} /> : <Checkmark />}
        {upsellV4ExperimentEnabled
          ? 'Only a GPS tracking collar can give you  complete confidence, knowing where your new best friend is 24/7.'
          : 'Monitor daily steps and sleep activity'}
      </div>
    </div>
  );
  const ctaContainer = (
    <div className={styles.ctaContainer}>
      <button
        className={styles.tryItButton}
        onClick={() => {
          analytics.track('Nano Registration > Upsell > Tapped Try It Button');
          logStepCompleted();
          onStepFinished('upgrade');
          window.open(getShopURL('NanoUpgrade', petInfo), '_blank');
        }}
      >
        {`Start tracking ${petInfo.name.length <= 10 ? petInfo.name : 'with Fi'}`}
      </button>
      <button
        onClick={() => {
          analytics.track('Nano Registration > Upsell > Tapped Skip Deal Button');
          logStepCompleted();
          onStepFinished('upgrade');
        }}
        className={styles.skipThisDealButton}
      >
        {upsellV4ExperimentEnabled ? 'Skip' : 'Skip this deal'}
      </button>
    </div>
  );

  return (
    <div className={classNames('upgrade-step', styles.container)}>
      <div className={styles.mainContent}>
        <div className={styles.progress}>
          <div className={styles.progressText}>
            {upsellV4ExperimentEnabled ? 'Step 5: GPS Tracking' : 'Step 5: Upgrade'}
          </div>
          <div className={styles.progressFillBoxes}>
            <div className={styles.progressFillBox1} />
            <div className={styles.progressFillBox2} />
            <div className={styles.progressFillBox3} />
            <div className={styles.progressFillBox4} />
            <div className={styles.progressFillBox5} />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {upsellV4ExperimentEnabled
              ? 'Reach total peace of mind with Fi Nano + Fi GPS Collar'
              : 'The ultimate peace of mind: 24/7 location tracking'}
          </div>
          {bulletPointContainer}
          {!gpsPulsingExperimentEnabled && (
            <img className={styles.locationTrackingImage} alt="Location tracking" src={locationTrackingImage} />
          )}
          {gpsPulsingExperimentEnabled && (
            <div className={styles.gpsPulsingContainer}>
              <img
                className={styles.locationTrackingImage}
                alt="Location tracking"
                src={locationTrackingGPSExperimentImage}
              />
              <div className={styles.gpsPinContainer}>
                <img className={styles.gpsAvatarPinImage} alt="GPS Avatar Pin" src={gpsAvatarPinImage} />
                <div className={styles.gpsRings} />
              </div>
            </div>
          )}
          <img className={styles.fiCollarImage} alt="Fi collar" src={fiCollarImage} />
          {ctaContainer}
        </div>
      </div>
      <div className={styles.desktopSidebarContainer}>
        <div className={styles.desktopSidebarContent}>
          {!gpsPulsingExperimentEnabled && (
            <img className={styles.locationTrackingImage} alt="Location tracking" src={locationTrackingImage} />
          )}
          {gpsPulsingExperimentEnabled && (
            <div className={styles.gpsPulsingContainer}>
              <img
                className={styles.locationTrackingImage}
                alt="Location tracking"
                src={locationTrackingGPSExperimentImage}
              />
              <div className={styles.gpsPinContainer}>
                <img className={styles.gpsAvatarPinImage} alt="GPS Avatar Pin" src={gpsAvatarPinImage} />
                <div className={styles.gpsRings} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.desktopSidebarContent}>
          <TestimonialCarousel />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.mobileContent}>
          <TestimonialCarousel />
        </div>
      </div>
    </div>
  );
}
