import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CommonChip } from '../../lib/types';
import PetDetails from '../ChipDetails/PetDetails/PetDetails';
import OutlineButton from '../OutlineButton';
import ActionsContainer from '../ActionsContainer/ActionsContainer';
import ModalAlert from '../modals/ModalAlert';
import { useResolveChipTransfer } from '../../lib/mutations';
import { ResolveChipTransferInput, ChipTransferStatus } from '../../../types/gql-op-types';
import ViewContactInfo from '../ChipDetails/ViewContactInfo/ViewContactInfo';
import { gqlTypes } from '../../../types/gqlTypes';

export interface AcceptTransferProps {
  chip: CommonChip;
  pet: gqlTypes.basePet | undefined | null;
  transfer: gqlTypes.transfer;
  reload(): void;
  canEdit: boolean;
}

export default function AcceptTransfer({ chip, pet, transfer, reload, canEdit }: AcceptTransferProps) {
  const history = useHistory();
  const [{ complete, wasSuccess }, setResolutionState] = useState({
    complete: false,
    wasSuccess: false,
  });

  const [resolveChipTransfer] = useResolveChipTransfer();

  const doResolve = async (input: ResolveChipTransferInput) => {
    try {
      await resolveChipTransfer({
        variables: { input },
      });
      setResolutionState({ complete: true, wasSuccess: true });
      history.push('/manage');
    } catch (err) {
      console.error(err);
      setResolutionState({ complete: true, wasSuccess: false });
    }
  };

  const doAccept = () =>
    doResolve({
      chipTransferId: transfer.id,
      status: ChipTransferStatus.ACCEPTED,
    });

  const doReject = () =>
    doResolve({
      chipTransferId: transfer.id,
      status: ChipTransferStatus.REJECTED,
    });

  return (
    <div>
      {pet && (
        <PetDetails
          chip={chip}
          pet={pet}
          status="pending"
          onPetEdited={
            canEdit
              ? () => {
                  reload();
                }
              : undefined
          }
        />
      )}
      {transfer.contactInfo && <ViewContactInfo contactInfo={transfer.contactInfo} />}
      <ActionsContainer>
        <OutlineButton onClick={doAccept}>Accept Transfer</OutlineButton>
        <div className="link link__underlined" onClick={doReject}>
          Reject transfer
        </div>
      </ActionsContainer>
      <ModalAlert
        message={wasSuccess ? `Transfer complete!` : `An unknown error occurred`}
        open={complete}
        onClose={() => reload()}
      />
    </div>
  );
}
