import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './DataElements.module.scss';

export interface DataElement {
  title: string;
  content: ReactNode;
}

export interface DataElementsProps {
  elements: DataElement[];
  large?: boolean;
  action?: ReactNode;
}

export default function DataElements({ elements, large, action }: DataElementsProps) {
  return (
    <div
      className={classNames(styles.main, 'data-elements', {
        [styles.large]: large,
      })}
    >
      {elements.map((element, i) => (
        <div key={i} className={styles.element}>
          <div className={styles.title}>{element.title}</div>
          <div className={styles.content}>{element.content}</div>
        </div>
      ))}
      {action && <div>{action}</div>}
    </div>
  );
}
