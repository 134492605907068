import React from 'react';
import { useQueryParams } from '../../lib/useQueryParams';
import SearchVerification from './SearchVerification';
import { Redirect } from 'react-router-dom';

export default function VerifySearch() {
  const queryParams = useQueryParams<{ q?: string }>();

  return queryParams.q ? <SearchVerification query={queryParams.q} /> : <Redirect to="/" />;
}
