import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from '../../lib/authentication';

import styles from './PasswordReset.module.scss';

export default function PasswordReset() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sent, setSent] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email: string = (event.target as any).email.value.trim();

    try {
      await sendPasswordResetEmail(email);
      setSent(true);
    } catch (error) {
      debugger;
      setErrorMessage((error as any).message);
    }
  };

  let body: JSX.Element;
  if (sent) {
    body = (
      <div className="form">
        <div className="form-group form-group--action">
          <div className="form-field">
            <div>
              <Link to="/" className="btn-yellow">
                Return to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    body = (
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <div className="form-field">
            <input type="text" name="email" placeholder="Email" />
          </div>
        </div>
        <div className="form-group form-group--action">
          <div className="form-field">
            <button type="submit" className="btn-yellow">
              Send Email
            </button>
            {errorMessage && <div className="form-field__error">{errorMessage}</div>}
          </div>
        </div>
        <div className="form-group">
          <div className="form-field">
            <div>
              <Link to="/login" className={styles.link}>
                Nevermind, take me back
              </Link>
            </div>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className="single-column">
      <h3 className={styles.header}>{sent ? `Email Sent!` : `Forgot Password`}</h3>
      {body}
    </div>
  );
}
